import React from 'react'
import { ROUTES_CONST } from '../../../constants/routeConstant'
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom'
import { useGames } from '../../../hooks/useGame'
import { useAuth } from '../../../hooks/useAuth'
import { useAuthModal } from '../../../hooks/useAuthModal'
import { useState } from 'react'
import { getAPI } from '../../../service/apiInstance'
import { useEffect } from 'react'
import { AUTH_MODAL_TABS, GAME_OPTION_TABS } from '../../../constants'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const OptionPills = () => {
  
  const {setGameObject} = useGames()
  const { token } = useAuth();
  const { setAuthModalObject } = useAuthModal();
  const [categories, setCategories] = useState([])
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const casinoLabels = t("casinoLabels", { returnObjects: true })
  const categoryPillOptions = useSelector(state=> state.categoryPillOptions)
  const [query, setQuery] = useSearchParams()


  const getCategory = async ()=> {
    setLoading(true)
    try {
        // const res = await getAPI(`get-all-category`)
        // if (res?.data?.success) {
        //   setCategories(res?.data?.data)
        // }
        setCategories(categoryPillOptions)
    } catch (error) {
      
    }finally {
      setLoading(false)
    }
  }
  useEffect(()=> {
    getCategory()
  },[categoryPillOptions])
  return (
    <>   
    <div className="row optionPillsOuter gx-2 flex-nowrap overflow-x-auto mt-4  ">
       <div className="col-auto optionPill">
         <div 
           onClick={()=> {
            setGameObject((prev)=> ({
             selectedTab : GAME_OPTION_TABS?.LOBBY
           }))
           setQuery({ q: "lobby" })
           navigate(`${ROUTES_CONST.GAME_PAGE}?q=${encodeURIComponent('lobby')}`)
           }
          }
           className={`optionPillInner text-decoration-none rounded-pill d-flex align-items-center justify-content-center`}>
           <img src='assets/img/options/lobby.png' alt="" />
           {casinoLabels.Lobby}
         </div>
       </div>
       <div className="col-auto optionPill">
         <div 
           onClick={()=>{
               navigate(ROUTES_CONST.SPORTS)
           }}
           className={`optionPillInner text-decoration-none rounded-pill d-flex align-items-center justify-content-center`}>
           <img src='assets/img/options/sports.png' alt="" />
           {casinoLabels.Sports}
         </div>
       </div>
       <div className="col-auto optionPill">
         <div 
           onClick={()=>{
               navigate(ROUTES_CONST.POKER)
           }}
           className={`optionPillInner text-decoration-none rounded-pill d-flex align-items-center justify-content-center`}>
           <img src='assets/img/gameCasino.png' alt="" />
           {'Poker'}
         </div>
       </div>
       <div className="col-auto optionPill">
         <div 
           onClick={()=>{
             if(token) {
               setGameObject((prev)=> ({
                 selectedTab : GAME_OPTION_TABS?.FAVOURITE
               }))
               
           setQuery({ q: "favourite" })
           navigate(`${ROUTES_CONST.GAME_PAGE}?q=${encodeURIComponent('favourite')}`)
             } else {
               setAuthModalObject(pre => ({
                 isAuthOpen: true,
                 selectedTab: AUTH_MODAL_TABS.LOG_IN
               }))
             }
           }}
           className={`optionPillInner text-decoration-none rounded-pill d-flex align-items-center justify-content-center`}>
           <img src='assets/img/options/favourite.png' alt="" />
           {casinoLabels.Favorites}
         </div>
       </div>
       <div className="col-auto optionPill">
         <div 
           onClick={()=>{
             if(token) {
               setGameObject((prev)=> ({
                 selectedTab : GAME_OPTION_TABS?.RECENT
               }))
               
           setQuery({ q: "recent" })
           navigate(`${ROUTES_CONST.GAME_PAGE}?q=${encodeURIComponent('recent')}`)
             } else {
               setAuthModalObject(pre => ({
                 isAuthOpen: true,
                 selectedTab: AUTH_MODAL_TABS.LOG_IN
               }))
             }
           }}
           className={`optionPillInner text-decoration-none rounded-pill d-flex align-items-center justify-content-center`}>
           <img loading='lazy' src='assets/img/options/recent.png' alt="" />
           {casinoLabels.Recent}
         </div>
       </div>

       <div className="col-auto optionPill">
         <div 
           onClick={()=>{
             if(token) {
               navigate(ROUTES_CONST.VIP_CLUB)
             } else {
               setAuthModalObject(pre => ({
                 isAuthOpen: true,
                 selectedTab: AUTH_MODAL_TABS.LOG_IN
               }))
             }
           }}
           className={`optionPillInner text-decoration-none rounded-pill d-flex align-items-center justify-content-center `}>
           <img src='assets/img/options/bonus.png' alt="" />
           {casinoLabels.Bonus}
         </div>
       </div>
       
       <div className="col-auto optionPill">
              <div 
                onClick={()=>{
                  if(token) {
                    setGameObject((prev)=> ({
                      selectedTab : GAME_OPTION_TABS?.FREE_SPIN
                    }))
                    
           setQuery({ q: "freeSpin" })
           navigate(`${ROUTES_CONST.GAME_PAGE}?q=${encodeURIComponent('freeSpin')}`)
                  } else {
                    setAuthModalObject(pre => ({
                      isAuthOpen: true,
                      selectedTab: AUTH_MODAL_TABS.LOG_IN
                    }))
                  }
                }}
                className={`optionPillInner text-decoration-none rounded-pill d-flex align-items-center justify-content-center`}>
                <img src='assets/img/options/freeSpin.svg' alt="" />
                Free Spin
              </div>
            </div>
       {!loading ?
         categories?.length > 0 ?
           categories?.map((item)=> (
             <div className="col-auto optionPill">
               <div 
                 onClick={()=>{
                   setGameObject((prev)=> ({
                     selectedTab : item?.category_name?.split(" ").join(""),
                     selectedId : item?._id
                   }))
                  
           setQuery({ q: item?.category_name?.split(" ").join("")})
           navigate(`${ROUTES_CONST.GAME_PAGE}?q=${encodeURIComponent(item?.category_name?.split(" ").join(""))}&categoryId=${encodeURIComponent(item?._id)}`)
                 }}
                 className={`optionPillInner text-decoration-none rounded-pill d-flex align-items-center justify-content-center
                   `}>
                 <img src={item?.image} alt="" />
                 {item?.category_name?.toLowerCase()}
               </div>
             </div>
           ))
         : ''
       : ''}
     </div>
    </>
 
  )
}

export default OptionPills