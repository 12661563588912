import React, { useEffect, useRef, useState } from 'react'
import { useAuth } from '../../../hooks/useAuth';
import { getAPIAuth } from '../../../service/apiInstance';
import { useWalletModal } from '../../../hooks/useWallet';
import QRCode from "react-qr-code";
import { errorToaster, succesToaster } from '../../../utils/toaster';
import { useTranslation } from 'react-i18next';
import { getCryptoListAction, walletBuyAction, walletSellAction } from '../../../store/action';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import gift from '../../../assets/img/gift.png'
import greenCheck from '../../../assets/img/greenMark.png'
import usdt from '../../../assets/img/usdtIcon.png'
import TimerComponent from './TimerComponent';

const Crypto = () => {
  const { walletModalObject, setWalletModalObject } = useWalletModal()
  const userBonusDetail = useSelector(state => state.userBonusDetail)
  const { token } = useAuth();
  const [cryptoData, setCryptoData] = useState([]);
  const [depositAddData, setDepositAddData] = useState({});
  const [selCryptoNetwork, setSelCryptoNetwork] = useState({ crypto: "", network: "" });
  const [search, setSearch] = useState({ crypto: "", network: "", });
  const [networkData, setNetworkData] = useState([]);
  const [cryptoOpen, setCryptoOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const [networkOpen, setNetworkOpen] = useState(false);
  const [cryptoFiltered, setCryptoFiltered] = useState([]);
  const [networkFiltered, setNetworkFiltered] = useState([]);
  const [availCrypto, setAvailCrypto] = useState([])
  const networkRef = useRef();
  const cryptoRef = useRef();
  const { t } = useTranslation()
  const walletLabels = t("wallet", { returnObjects: true })
  const isCryptoOpen = walletModalObject.isWalletOpen
  const dispatch = useDispatch()
  const userDetails = useSelector(state => state.userDetails)
  const [isAddressLoading, setIsAddressLoading] = useState(true)
  const [isDepositCheck, setIsDepositCheck] = useState(true)
  const [isSpinCheck, setIsSpinCheck] = useState(true)
  const crypto = useSelector(state => state?.selectedCurrency?.crypto)
  // console.log("crypto", { crypto, selCryptoNetwork })

  useEffect(() => {
    if (crypto?._id) {
      setSelCryptoNetwork({
        crypto: crypto,
        network: crypto?.allNetworks?.[0]
      })
    }
  }, [crypto?._id])
  // function for getting crypto currencies
  console.log('cccccccccccccccccccccccccccc', networkFiltered)
  console.log('cccccccccccccccccccccccccccc network', networkData)

  

  const gettingCryptoData = async () => {
    if (isCryptoOpen) {
      try {
        const resp = await getAPIAuth(
          "crypto/get-crypto",
          token
        );



        // let selectedCrypto = {}
        if (!crypto?._id) {
          const selectedCrypto = resp?.data?.data.filter(item => item.code === "usdt")[0]
          console.log("crypto-->>",selectedCrypto )
          setSelCryptoNetwork((prev) => ({
            network: resp?.data?.data.filter(item => item.code === "usdt")[0]?.allNetworks.filter(el => el.name === "BEP20")?.[0],
            ["crypto"]: selectedCrypto,
          }));
          await updateWalletAddress(selectedCrypto)
        }

        resp?.data?.data.sort((a, b) => {
          if (a.code < b.code) {
            return -1;
          }
          if (a.code > b.code) {
            return 1;
          }
          return 0;
        });
        setCryptoFiltered(resp?.data?.data);
        setCryptoData(resp?.data.data);
        // setNetworkData(resp?.data.data[0].allNetworks)
        // setNetworkFiltered(resp?.data.data[0].allNetworks)
        // name: "BEP20"
        // setNetworkData(resp?.data?.data.filter(item => item.code === "usdt")[0]?.allNetworks)
        setNetworkData(resp?.data?.data.filter(item => item.code === "usdt")[0]?.allNetworks)
        setNetworkFiltered(resp?.data?.data.filter(item => item.code === "usdt")[0]?.allNetworks)
        dispatch(getCryptoListAction(resp?.data.data))
      } catch (error) {

      }
    }
  };
  // function for getting network
  const gettingNetwork = async () => {
    if (isCryptoOpen) {
      try {
        const resp = await getAPIAuth(
          "crypto/get-network",
          token
        );
        setSelCryptoNetwork((prev) => ({
          ...prev,
          ["network"]: resp?.data.data[0],
        }));
        setNetworkFiltered(resp?.data.data);
        setNetworkData(resp?.data.data);
        setLoader(false)
      } catch (error) {
        setLoader(false)
      }
    }
  };

  //   function for filter crypto data
  const filterCrypto = () => {
    if (search.crypto) {
      let filterData = cryptoData.filter((el) =>
        el.code
          .toUpperCase()
          .includes(search.crypto.toUpperCase())
      );
      filterData.sort((a, b) => {
        if (a.code < b.code) {
          return -1;
        }
        if (a.code > b.code) {
          return 1;
        }
        return 0;
      });
      setCryptoFiltered(filterData);
    } else {
      const sortedCrypto = cryptoData.sort((a, b) => {
        if (a.code < b.code) {
          return -1;
        }
        if (a.code > b.code) {
          return 1;
        }
        return 0;
      });
      setCryptoFiltered(sortedCrypto);
    }
  };


  console.log('cryptoData', cryptoData)


  //   function for filter network data
  const filterNetwork = () => {

    if (search.network) {
      let filterData = networkData.filter((el) =>
        el.name
          .toUpperCase()
          .includes(search.network?.toUpperCase())
      );
      setNetworkFiltered(filterData);
    } else {
      setNetworkFiltered(networkData);
    }
  };


  const handleCopy = () => {
    if (depositAddData?.address?.length) {
      navigator.clipboard.writeText(depositAddData?.address)
      succesToaster("Address copied")
      window.dataLayer.push({
        'event': 'GA4_event',
        'event_name': 'game_dep_copy_address'
      });
    }
  }

  useEffect(() => {
    if (token) {
      gettingCryptoData();
      // will be of use in future
      // gettingNetwork();
    }
  }, [isCryptoOpen]);

  useEffect(() => {
    filterCrypto();
  }, [search.crypto]);

  useEffect(() => {
    filterNetwork();
  }, [search.network, networkData?.length, networkData]);

  useEffect(() => {
    if (cryptoOpen) cryptoRef.current.focus();
  }, [cryptoOpen]);

  useEffect(() => {
    if (networkOpen) networkRef.current.focus();
  }, [networkOpen]);

  useEffect(() => {
    if (userDetails.id && token && walletModalObject.isWalletOpen) {
      getAvailableCrypto()
    }
  }, [userDetails?.id, token, walletModalObject.isWalletOpen])
useEffect(()=>{
  getWalletAddress();
},[selCryptoNetwork])
  //wallet address 
  const getWalletAddress = async () => {
    setIsAddressLoading(true)
    try {
      const res = await getAPIAuth(`getAddress?coinId=${selCryptoNetwork?.crypto?._id}&networkId=${selCryptoNetwork?.network?._id}`)
      if (res.data?.success) {
        setDepositAddData(res.data.data)
        dispatch(walletBuyAction(res.data.data.address))
      } else {
        setDepositAddData({})
      }
    } catch (error) {
      // err
      setDepositAddData({})
    } finally {
      setIsAddressLoading(false)
    }
  }

  //wallet address 
  const updateWalletAddress = async (item) => {
    setIsAddressLoading(true)
    try {
      const network = item?.allNetworks?.filter(el => el.name === "BEP20")?.[0]
      const res = await getAPIAuth(`getAddress?coinId=${item?._id}&networkId=${network ? network?._id : item?.allNetworks[0]?._id}`)
      if (res.data?.success) {
        setDepositAddData(res.data.data)

        if (item?.code == "usdt" && network?.name === 'BEP20') {
          console.log('walletAccBuy res', res.data.data.address)
          dispatch(walletBuyAction(res.data.data.address))
        }
      } else {
        setDepositAddData({})
      }
    } catch (error) {
      // err
      setDepositAddData({})
    } finally {
      setIsAddressLoading(false)
    }
  }

  const getAvailableCrypto = async () => {
    try {
      const res = await getAPIAuth("crypto/get-crypt-favorite-by-admin", token)
      if (res.data.success) {
        setAvailCrypto(res.data.data)
        // setSelCryptoNetwork(res.data.data)
        // updateWalletAddress(res.data.data[0])
      }
    } catch (error) {
    }
  }
  //wallet address 
  const updateNetwork = async (item) => {

    setIsAddressLoading(true)
    try {
      const res = await getAPIAuth(`getAddress?coinId=${selCryptoNetwork?.crypto?._id}&networkId=${item?._id}`)
      if (res.data?.success) {
        setDepositAddData(res.data.data)
      } else {
        setDepositAddData({})
      }
    } catch (error) {
      // err
      setDepositAddData({})
    } finally {
      setIsAddressLoading(false)
    }
  }
  useEffect(() => {
    // if (token && selCryptoNetwork?.crypto?._id && selCryptoNetwork.network?._id) {
    //   getWalletAddress()
    // }
    // }, [selCryptoNetwork.crypto, selCryptoNetwork.network?._id])

  }, [])

  // useEffect(() => {
  //   if (selCryptoNetwork?.crypto?.allNetworks?.length) {
  //     setSelCryptoNetwork((pre) => ({
  //       ...pre,
  //       network: selCryptoNetwork?.crypto?.allNetworks[0]
  //     }))
  //     setNetworkData(selCryptoNetwork?.crypto?.allNetworks)
  //   }
  // }, [selCryptoNetwork.crypto?._id, selCryptoNetwork?.crypto?.allNetworks?.length])


  const handleSpinCheck = () => {
    // if(userBonusDetail?.bonusData?.freeSpinAmount < 40) {
    //   // errorToaster('')
    // } 
    // setWalletModalObject(prev=>({
    //   ...prev,
    //   freeSpinCheck : !walletModalObject?.freeSpinCheck
    // }))
  }

  const returnTimeForBonus = (time) => {
    const currentDate = new Date(time)
    const hours = currentDate.setHours(currentDate.getHours() + 12);
    console.log('hours', hours)
    return hours
  }

  function isWithin12Hours(givenTimeStr) {
    let givenTime = new Date(givenTimeStr);
    
    // Check if the given time is valid
    if (isNaN(givenTime)) {
      throw new Error("Invalid date format");
    }
  
    let currentTime = new Date();
  
    givenTime.setHours(givenTime.getHours() + 12);
  
    // Compare the current time with the new given time
    return currentTime <= givenTime;
  }

  // console.log('isWithin12Hours',isWithin12Hours(userDetails?.joinedTime))


  console.log('userBonusDetail?.bonusData?.spinTimmer', userBonusDetail?.bonusData?.spinTimmer)
  
    // Function to check if the given time is in the past or future
    const checkTime = (givenTime) => {
        // Parse the given time string into a Date object
      const given = new Date(givenTime);
      // Get the current time
      const current = new Date();
      if (given < current) {
        return 
      } else if (given > current) {
        return given
      } else {
        return
      }
    };


  return (
    <div className="tab-pane fade show active">
      <div className="row">
        <div className="col-12 cryptoHeading d-flex align-items-center">
          {walletLabels?.Deposit_currency}
          {/* <div class="dropdown d-flex depositDetailDropdown ms-2">
            <img className='exclamation' src="assets/img/sidebar/info.png" data-bs-toggle="dropdown" aria-expanded="false" alt="" />
            <div className="dropdown-menu depositDetailDropdownInner">
              Please be advised that all deposits made in any currency will be displayed as converted to USDT (Tether) on the front end of the platform. However, users retain the flexibility to withdraw their balances in any currency of their choice. This system ensures uniformity in tracking balances while allowing users the freedom to manage their funds according to their preferences
            </div>
          </div> */}
        </div>
        {/* Available crypto */}
        <div className="col-12">
          <div className="d-flex align-items-center gap-2 pb-1" style={{overflowX: 'auto'}}>
            {
              availCrypto?.map((item) => (
                <span
                  key={item?._id}
                  className="usdtBtn d-flex align-items-center justify-content-center text-decoration-none cursor-pointer"
                  onClick={() => {
                    setSelCryptoNetwork((prev) => ({
                      ...prev,
                      ["crypto"]: item,
                    }))
                    if (item?.allNetworks?.length) {
                      setSelCryptoNetwork((pre) => ({
                        ...pre,
                        network: item?.allNetworks[0]
                      }))
                      setNetworkData(item?.allNetworks)
                    }
                    updateWalletAddress(item)
                  }}

                >
                  <span className="usdtIcon d-flex align-items-center justify-content-center">
                    <img src={item?.icon} alt="usdt" className="h-100 w-100 img-fluid" />
                  </span>
                  {item?.code}
                </span>
              ))
            }
          </div>
        </div>
        <div className="col-12 pt-2">
          <div className="dropdown btcdropDown pt-1 w-100">
            {/* selected crypto */}
            <button
              onClick={() =>
                setCryptoOpen(!cryptoOpen)
              }
              className="btn dropdown-toggle dropDownBtn position-relative d-flex align-items-center w-100 pe-4">
              <span className="bitcoinIcon me-3 d-flex align-items-center justify-content-center rounded-circle overflow-hidden">
                <img src={selCryptoNetwork?.crypto?.icon} alt="Bitcoin" className="h-100 w-100 img-fluid" />
              </span>
              <div className="d-flex justify-content-between w-100 pe-2">
                <span>{selCryptoNetwork?.crypto?.code}</span>

                <span>
                  {/* {selCryptoNetwork?.crypto?.chain} */}
                </span>
              </div>
            </button>
            <ul className={`dropdown-menu btcdropmenu w-100 ${cryptoOpen ? 'show' : ''}`}>
              {/* crypto  search box */}
              <li className="">
                <div className="position-relative dropSearch">
                  <img className="searchIcon" src="assets/img/aboutJonathan/search.png" alt="search Icon" />
                  <input type="text" className="form-control bg-transparent shadow-none searchInput"
                    value={search.crypto}
                    ref={cryptoRef}
                    onChange={(e) =>
                      setSearch((prev) => ({
                        ...prev,
                        ["crypto"]: e.target.value,
                      }))
                    } />
                </div>
              </li>
              {/* crypto list */}
              {cryptoFiltered?.map(
                (elem) => (
                  <>
                    <li
                      // onClick={() => {
                      //   setSelCryptoNetwork((prev) => ({
                      //     ...prev,
                      //     ["crypto"]: elem,
                      //   })
                      //   );
                      //   setCryptoOpen(!cryptoOpen);
                      // }}
                      key={elem?._id}
                      onClick={() => {
                        setSelCryptoNetwork((prev) => ({
                          ...prev,
                          ["crypto"]: elem,
                        }))
                        if (elem?.allNetworks?.length) {
                          setSelCryptoNetwork((pre) => ({
                            ...pre,
                            network: elem?.allNetworks[0]
                          }))
                          setNetworkData(elem?.allNetworks)
                        }
                        setCryptoOpen(!cryptoOpen);
                        updateWalletAddress(elem)
                      }}
                      className='my-1'
                    >
                      <div className={`dropdown-item btcInner d-flex align-items-center ${selCryptoNetwork.crypto?._id == elem?._id ? "active" : ""
                        }`}>
                        <span className="selectIcon me-3 d-flex align-items-center justify-content-center rounded-circle overflow-hidden">
                          <img src={elem?.icon} alt={elem?.code} className="h-100 w-100 img-fluid" />
                        </span>
                        <div className="d-flex justify-content-between w-100">
                          <span>{elem?.code}</span>
                          {/* <span>{elem?.chain}</span> */}
                        </div>
                      </div>
                    </li>
                  </>
                )
              )}
            </ul>
          </div>
        </div>
        {/* selected network */}
        <div className="col-12 pt-4 cryptoHeading">
          {walletLabels.Choose_Network}
        </div>
        {/* network search box and network list */}
        <div className="col-12 ">
          <div className="dropdown btcdropDown w-100">
            <button
              onClick={() => setNetworkOpen(!networkOpen)}
              className="btn fw-bold network dropdown-toggle dropDownBtn position-relative d-flex align-items-center w-100"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {selCryptoNetwork.network?.name}
            </button>
            <ul className={`dropdown-menu btcdropmenu w-100 ${networkOpen ? 'show' : ''}`}>
              <li className="">
                <div className="position-relative dropSearch">
                  <img className="searchIcon" src="assets/img/aboutJonathan/search.png" alt="search Icon" />
                  <input type="text" className="form-control bg-transparent shadow-none searchInput"
                    ref={networkRef}
                    value={search.network}
                    onChange={(e) =>
                      setSearch((prev) => ({
                        ...prev,
                        ["network"]:
                          e.target.value,
                      }))
                    }
                  />
                </div>
              </li>
              {/* {selCryptoNetwork.crypto.allNetworks?.map( */}
              {
                networkFiltered?.map(
                  (item) => (
                    <li
                      key={item?._id}
                      // onClick={() => {
                      //   setSelCryptoNetwork(
                      //     (prev) => ({
                      //       ...prev,
                      //       ["network"]: item,
                      //     })
                      //   );
                      //   setNetworkOpen(
                      //     !networkOpen
                      //   );
                      // }}
                      onClick={() => {
                        setSelCryptoNetwork(
                          (prev) => ({
                            ...prev,
                            ["network"]: item,
                          }));
                        updateNetwork(item)
                        setNetworkOpen(!networkOpen);
                      }}
                    >
                      <div className={`dropdown-item btcInner d-flex align-items-center 
                              ${selCryptoNetwork.network._id == item._id ? "active" : ""}`}>
                        {item.name}                   </div>
                    </li>
                  )
                )}
            </ul>
          </div>
        </div>
        {/* <div className="col-12 d-sm-block d-none">
          <div className="bonusTitle d-flex align-items-center">
            <div className="">
              {walletLabels.Get_extra}  <span className="fw-bold"> {walletLabels.Bonus180} </span> {walletLabels.minimumText}
            </div>
            <span className="d-flex align-items-center justify-content-center ms-3 rounded-circle infoImg cursor-pointer">
              <img src="assets/img/sidebar/info.png" alt="info" className="h-100 w-100 img-fluid" />
            </span>
          </div>
        </div> */}
        <div className="col-12 px-0">
          <div className="row mx-0">
            <div className="col-12  d-flex justify-content-end py-3">
              <div class="dropdown d-flex depositDetailDropdown">
                <img className='exclamation' src="assets/img/sidebar/info.png" data-bs-toggle="dropdown" aria-expanded="false" alt="" />
                <div className="dropdown-menu depositDetailDropdownInner">
                  Once your deposit is credited, it will activate a slected bonus. if you have already made a deposit, please don’t change the selected bonus until the deposit is credited to the game balance.
                </div>
              </div>

            </div>
            <div className="col-sm-6 col-12">
              <div className="greenBox active h-100" onClick={() => setIsDepositCheck(!isDepositCheck)}>
                {
                  isDepositCheck &&
                  <div className="check">
                    <img src={greenCheck} alt="" />
                  </div>
                }
                <div className="row justify-content-center mx-0">
                  <div className="col-auto px-0 mb-2"><div className="giftBox"><img src={gift} alt="" />200% on the deposit</div></div>
                  <div className="col-12 d-flex justify-content-center">
                    <div className="depositeTxt"><span>Min. Deposit</span>: $50</div>
                  </div>
                  <div className="col-12">
                  <div className="depositeTxt text-center"><span>You will get $100.00 BONUS!</span></div>
                  </div>
                  <div className="col-12 d-flex justify-content-center py-2">
                    <div className="depositeTxt"><img src={usdt} alt="" /><span> 100.00  </span>USDT</div>
                  </div>
                  {
                    userDetails?.joinedTime && isWithin12Hours(userDetails?.joinedTime) && (
                      <div className="col-12 d-flex justify-content-center">
                          <div className="timer">
                            <TimerComponent targetTime={returnTimeForBonus(userDetails?.joinedTime)}/>
                          </div>
                        </div>
                    )
                  }
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-12 mt-3 mt-sm-0">
              <div className={`greenBox blueBox h-100 ${walletModalObject?.freeSpinCheck ? 'active' : 'active'}`} onClick={() => setIsSpinCheck(!isSpinCheck)}>
                {
                  isSpinCheck && <div className="check">
                    <img src={greenCheck} alt="" />
                  </div>
                }
                <div className="row justify-content-center mx-0">
                  <div className="col-auto px-0 mb-2"><div className="giftBox"><img src={gift} alt="" />100 free Spins</div></div>
                  <div className="col-12 d-flex justify-content-center">
                    <div className="depositeTxt"><span>Min. Deposit</span>: $40</div>
                  </div>
                  <div className="col-12">
                  <div className="depositeTxt text-center"><span>To unlock your $ {userBonusDetail?.bonusData?.freeSpinAmount ? Number(userBonusDetail?.bonusData?.freeSpinAmount)?.toFixed(2) : 0.00} BONUS!</span></div>
                  </div>
                  <div className="col-12 d-flex justify-content-center py-2">
                    <div className="depositeTxt"><img src={usdt} alt="" /><span> {userBonusDetail?.bonusData?.freeSpinAmount ? Number(userBonusDetail?.bonusData?.freeSpinAmount)?.toFixed(2) : 0.00} </span>USDT</div>
                  </div>
                  <div className="col-12 d-flex justify-content-center">
                    {
                      checkTime(userBonusDetail?.bonusData?.spinTimmer) ?
                        <div className="timer">
                          <TimerComponent targetTime={userBonusDetail?.bonusData?.spinTimmer} />
                        </div>
                        : ''
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 pt-sm-5 pt-4">
          <div className="row depositCard mx-sm-0">
            <div className="col-auto order-sm-1 order-2">
              <div className="overflow-hidden rounded-2 qrImg">
                {/* wallet QR code */}
                {/* <img src={depositAddData?.qr_image} alt="qrcode" className="h-100 w-100 img-fluid" /> */}
                {
                  isAddressLoading ? (
                    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                      <Spinner animation="border" variant="dark" size="sm" />
                    </div>
                  ) : (

                    depositAddData?.address ? (
                      <QRCode
                        size={256}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={depositAddData?.address}
                        viewBox={`0 0 256 256`}
                      />
                    ) : (
                      <div className='d-flex justify-content-center align-items-center w-100 h-100'>
                        <b>"Error"</b>
                      </div>
                    )
                  )
                }
              </div>
            </div>
            <div className="col order-sm-2 order-1">
              <div className="d-flex flex-column justify-content-sm-start justify-content-between h-100">
                {/* <div className="bonusTitle d-sm-none d-flex alignitems-center pt-0">
                  <span className="d-flex align-items-center justify-content-center me-2 rounded-circle infoImg">
                    <img src="assets/img/sidebar/info.png" alt="info" className="h-100 w-100 img-fluid" />
                  </span>
                  <div className="">
                    {walletLabels.Get_extra}  <span className="fw-bold"> {walletLabels.Bonus180} </span> {walletLabels.minimumText}
                  </div>
                </div> */}
                <div>
                  <div className="depositAddress">
                    {walletLabels.Your_Personal}  <span className='text-uppercase'>{selCryptoNetwork?.crypto?.code ? selCryptoNetwork?.crypto?.code : ""}</span> {walletLabels.Deposit_Address}
                  </div>
                  <div className="depositcardTitle pt-md-3 pt-sm-2 pt-1">
                    <span> {walletLabels.NOTICE}:</span> {walletLabels.Send_only} <span className='text-uppercase'>{selCryptoNetwork?.crypto?.code ? selCryptoNetwork?.crypto?.code : ""}</span> {walletLabels.address_Text}
                  </div>

                  <div className="pt-2 d-sm-block d-none">
                    <div className="input-group copyAddressmain">
                      {/* wallet Address */}
                      <input
                        type="text"
                        value={depositAddData?.addressValue ? depositAddData?.addressValue : depositAddData?.address ? depositAddData?.address : ""}
                        className="form-control rounded-0 bg-transparent border-0 shadow-none addressInput"
                        readOnly
                      />
                      {/* wallet Address copy */}
                      <button
                        className="playBtn playbtntwo border-0 rounded-2 position-relative d-flex align-items-center justify-content-center"
                        onClick={handleCopy}
                      >
                        <div className="playBtnInner d-flex px-3 align-items-center justify-content-center w-100">
                          {isAddressLoading ? (<div className="w-100 h-100 d-flex justify-content-center align-items-center">
                            <Spinner animation="border" variant="dark" size="sm" />
                          </div>) : (walletLabels.Copy_ADDRESS)}
                        </div>
                      </button>
                    </div>
                  </div>
                  {

                    // true ? (
                    depositAddData?.addressValue ? (
                      <div className="pt-2 d-sm-block d-none">
                        <div className="depositcardTitle d-flex align-items-center gap-2">
                          {depositAddData?.address}
                          {/* 112455071 */}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )
                  }
                  <div className="pt-2 d-sm-block d-none">
                    <div className="depositcardTitle d-flex align-items-center gap-2">
                      <span className="infoIcon rounded-circle overflow-hidden d-flex align-items-center justify-content-center">
                        <img src="assets/img/sidebar/info.png" alt="info" className="h-100 w-100 img-fluid" />
                      </span>
                      {walletLabels.Do_not_deposit} {selCryptoNetwork?.crypto?.code ? selCryptoNetwork?.crypto?.code : ""}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 order-3 py-3 d-sm-none d-block">
              <div className="input-group copyAddressmain">
                <input
                  type="text"
                  className="form-control rounded-0 bg-transparent border-0 shadow-none addressInput"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  value={depositAddData?.addressValue ? depositAddData?.addressValue : depositAddData?.address ? depositAddData?.address : ""}
                  readOnly
                />
                <button
                  onClick={handleCopy}
                  className="copyAddressBtn border-0 rounded-1 position-relative d-flex align-items-center justify-content-center">
                  <div className="playBtnInner d-flex align-items-center justify-content-center w-100">
                    {
                      isAddressLoading ? (
                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                          <Spinner animation="border" variant="dark" size="sm" />
                        </div>
                      ) : (
                        walletLabels.Copy_ADDRESS
                      )
                    }
                  </div>
                </button>
              </div>
            </div>
            <div className="depositcardTitle d-sm-none pt3 order-last d-flex align-items-center gap-2">
              <span className="infoIcon rounded-circle overflow-hidden d-flex align-items-center justify-content-center">
                <img src="assets/img/sidebar/info.png" alt="info" className="h-100 w-100 img-fluid" />
              </span>
              {walletLabels.Do_not_deposit}  {selCryptoNetwork?.crypto?.code ? selCryptoNetwork?.crypto?.code : ""}
            </div>
          </div>
        </div>
        {/* will be of future use */}
        {/* <div className="col-12 pt-sm-5 pt-4 mt-sm-0 mt-2">
            <div className="d-flex align-items-center gap-1">
                <div className="input-group btcValue ">
                    <div className="input-group-text btcLabel bg-transparent border-0" id="basic-addon1">
                        <span className="overflow-hidden me-2 rounded-circle btcvalueImg d-flex align-items-center justify-content-center">
                            <img src="assets/img/sidebar/coin.png" alt="coin" className="h-100 w-100 img-fluid"/>
                        </span>
                        $
                    </div>
                    <input type="text" className="form-control btcInput shadow-none border-0 bg-transparent" aria-label="Username" aria-describedby="basic-addon1"/>
                </div>
                <span className="Equal">=</span>
                <div className="input-group btcValue ">
                    <div className="input-group-text pe-0 btcLabel bg-transparent border-0" id="basic-addon1">
                        <span className="overflow-hidden me-2 rounded-circle btcvalueImg d-flex align-items-center justify-content-center">
                            <img src="assets/img/sidebar/Bitcoin.png" alt="bitcoin" className="h-100 w-100 img-fluid"/>
                        </span>
                    </div>
                    <input type="text" className="form-control btcInput shadow-none border-0 bg-transparent" aria-label="Username" aria-describedby="basic-addon1"/>
                </div>
            </div>
        </div>
        <div className="col-12 text-center btnvaluetitle">
            The value of BTC may change between now and the time we receive your payment
        </div> */}
      </div>
    </div >
  )
}

export default Crypto