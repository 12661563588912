import React from "react";
import { calculateDays } from "../../../utils/calculateDays";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTES_CONST } from "../../../constants/routeConstant";
import { Spinner } from "react-bootstrap";

const HighBetsTable = ({ data, loading }) => {
    const { t } = useTranslation()
    const casinoLabels = t("casinoLabels", { returnObjects: true })
    const navigate = useNavigate()

    return (
        <div className="tab-content">
            <div className="tab-pane fade show active shadowTable">
                <div className="table-responsive scrollTable">
                    <table className="table align-middle text-white text-nowrap">
                        <thead className="animatedThead">
                            <tr>
                                <th scope="col">{casinoLabels.NAME}</th>
                                <th scope="col">{casinoLabels.PLAYER}</th>
                                {/* <th scope="col" className="text-end">{casinoLabels.TIME}</th> */}
                                <th scope="col" className="text-en">{casinoLabels.BET_AMOUNT}</th>
                                <th scope="col" className="text-en">{casinoLabels.MULTIPLIER}</th>
                                {/* <th scope="col" className="text-end">{casinoLabels.PAYOUT}</th> */}

                            </tr>
                        </thead>
                        <tbody className="animatedTBody">
                            {
                            !loading ?
                            data?.length > 0 ?
                                data?.map((item) => (
                                    <tr onClick={()=>navigate(`${ROUTES_CONST.SLOT_PAGE}/${item?.game_id}`)} className="animatedTr cursor-pointer" key={item?._id}>
                                        <td>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div className="gameImg overflow-hidden me-2 " style={{ height: '25px',minWidth: '25px', width: '25px', borderRadius: '3px' }}>
                                                    <img src={item?.image} className="h-100 w-100 object-fit-cover" alt="game" />
                                                </div>
                                                {item?.name}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div className="gameImg overflow-hidden me-2 " style={{ height: '25px',minWidth: '25px', width: '25px', borderRadius: '3px' }}>
                                                    <img src={item?.rankImage} className="h-100 w-100 object-fit-cover" alt="game" />
                                                </div>
                                                {item?.fullname}
                                            </div></td>
                                        {/* <td className="text-end">
                                            {calculateDays(item?.createdAt)}
                                        </td> */}
                                        <td className="text-en">
                                            {/* ${item?.amount < 1 ?  Number(item?.amount).toFixed(2) : Math.round(item?.amount)} */}
                                                {Math.floor(item?.amount)}
                                            
                                        </td>
                                        <td ><span className="badg Multiplier justify-content-en">{item?.multiplier?.toFixed(2)}</span></td>
                                        {/* <td>
                                            <a href="#" className="d-flex align-items-center justify-content-end text-decoration-none" >
                                                <span className="d-flex align-items-center amount me-1"></span>$0
                                            </a>
                                        </td> */}
                                    </tr>
                                ))
                                : <tr>
                                    <td colSpan={5} className="text-light"> No data available</td>
                                </tr>
                                  :  <tr>
                                  <td></td>
                                  <td colSpan={3} className="text-light">
                                      <div className="m-auto d-flex justify-content-center py-2">
                                          <Spinner className="m-auto" animation="border" variant="light" size="sm"/>
                                      </div>
                                  </td>
                                  <td></td>
                              </tr>
                                }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default HighBetsTable;
