import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Mousewheel, Keyboard } from 'swiper/modules';
import { getAPI, postAPIAuth } from '../../../service/apiInstance';
import { Spinner } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES_CONST } from '../../../constants/routeConstant';
import { useAuth } from '../../../hooks/useAuth';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthModal } from '../../../hooks/useAuthModal';
import { AUTH_MODAL_TABS, MOBILE_SCREEN_WIDTH } from '../../../constants';
import { succesToaster } from '../../../utils/toaster';
import GamesCard from '../../../components/common/GamesCard/GamesCard';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { topRatedGamesAction } from '../../../store/action';
import SkeletonLoader from '../../../components/skeletonLoader/SkeletonLoader';

const CasionGames = () => {
    const [category, setCategory] = useState('top-rated')
    const [games, setGames] = useState('')
    const [gamesLoader, setGamesLoader] = useState(true)
    const [searchValue, setSearchValue] = useState('')
    const navigate = useNavigate()
    const { token } = useAuth()
    const userDetails = useSelector(state => state.userDetails)
    const { setAuthModalObject } = useAuthModal()
    const isMobile = useMediaQuery({ maxWidth: MOBILE_SCREEN_WIDTH })
    const { t } = useTranslation()
    const casinoLabels = t("casinoLabels", { returnObjects: true })
    const dispatch = useDispatch()


    const getGames = async () => {
        setGamesLoader(true)
        try {

            if (category) {
                const res = await getAPI(`games/${category}?is_mobile=${isMobile ? "true" : "false"}`)
                setGames(res?.data?.data)
                if(category === 'top-rated') {
                    dispatch(topRatedGamesAction(res?.data?.data))
                }
            }
        } catch (error) {
        } finally {
            setGamesLoader(false)
        }
    }

    useEffect(() => {
        getGames()
    }, [category])


    const searchQueryHandler = (event) => {
        event.preventDefault()
        if (event?.key === "Enter" && searchValue?.length > 0) {
            navigate(`${ROUTES_CONST.SLOT_PAGE}?${searchValue}`)
        }
    }

    const favouriteGame = async (id) => {
        if (token) {
            let params = {
                "gameId": id,
                "userId": userDetails?.id
            }
            const res = await postAPIAuth('crypto/create-favourite-games', params)
            if (res.data.status) succesToaster(res.data.message)
        } else {
            setAuthModalObject(pre => ({ isAuthOpen: true, selectedTab: AUTH_MODAL_TABS.LOG_IN }))
        }
    }
    return (
        <div className="container-fluid px-0 position-relative casinoGamesSlide">
            <div className="topratedgames-tab-main position-relative">
                <div className="topratedgames-tab-content">
                    <div className="row align-items-center justify-content-between">
                        <div className="col">
                            <div className="diceBox">
                                <img src="assets/img/dice.png" alt="" />
                                {casinoLabels.CASINO_GAMES}
                            </div>
                        </div>
                        {/* <div className="col-auto seeAllBtn">
                            <span className='cursor-pointer'
                                onClick={() => navigate(ROUTES_CONST.SLOT_PAGE)}>
                                <span className='seeAllTitle'>See all</span>
                                <span className='seeAllIcon'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="9" viewBox="0 0 12 9" fill="none">
                                    <path d="M11.0351 4.01642L7.94995 0.588419C7.88413 0.515745 7.79226 0.474609 7.6949 0.474609H5.63811C5.50304 0.474609 5.38032 0.554139 5.32479 0.677547C5.26994 0.80164 5.29256 0.946302 5.38306 1.0464L8.2619 4.24541L5.38306 7.44373C5.29256 7.54451 5.26925 7.68917 5.32479 7.81258C5.38032 7.93667 5.50304 8.0162 5.63811 8.0162H7.6949C7.79226 8.0162 7.88413 7.97438 7.94995 7.90308L11.0351 4.47508C11.1524 4.34482 11.1524 4.14599 11.0351 4.01642Z" fill="#EFB90B"/>
                                    <path d="M6.5786 4.01642L3.4934 0.588419C3.42759 0.515745 3.33572 0.474609 3.23836 0.474609H1.18156C1.0465 0.474609 0.923779 0.554139 0.868245 0.677547C0.813397 0.80164 0.836022 0.946302 0.926521 1.0464L3.80535 4.24541L0.926521 7.44373C0.836022 7.54451 0.812712 7.68917 0.868245 7.81258C0.923779 7.93667 1.0465 8.0162 1.18156 8.0162H3.23836C3.33572 8.0162 3.42759 7.97438 3.4934 7.90308L6.5786 4.47508C6.69584 4.34482 6.69584 4.14599 6.5786 4.01642Z" fill="#EFB90B"/>
                                </svg>
                                </span>
                            </span>
                        </div> */}
                        <div className="col-auto d-flex justify-content-end casinoTabCol d-none d-sm-block">
                            <div className="nav nav-tabs border-0">
                                <button
                                    className={`nav-link text-nowrap toprated-link ${category === 'recent-games' ? 'active' : ''}`}
                                    onClick={() => setCategory('recent-games')}>
                                    {casinoLabels.recent_big_wins}
                                </button>
                                <button
                                    className={`nav-link text-nowrap toprated-link ps-0 ${category === 'top-rated' ? 'active' : ''}`}
                                    onClick={() => setCategory('top-rated')}>
                                    {casinoLabels.top_rated_games}
                                </button>
                            </div>
                        </div>
                        {/* <div className="col-auto ps-1 me-5 mRight d-none d-lg-block">
                            <div>
                                <div className="input-group  search-bar-group border-1">
                                    <input
                                        onChange={(e) => setSearchValue(e.target.value)}
                                        value={searchValue}
                                        onKeyUp={searchQueryHandler}
                                        type="text" className="form-control search-input fw-semibold shadow-none bg-transparent border-0"
                                        placeholder="Search for games"
                                        aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                    />
                                    <button
                                        type="button"
                                        className="input-group-text bg-transparent border-0"
                                        id="basic-addon2"
                                        onClick={() => navigate(`${ROUTES_CONST.SLOT_PAGE}?${searchValue}`)}
                                    >
                                        <img src="assets/img/index/search-icon.png" alt="search-icon" />
                                    </button>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="tab-content">
                <div className="tab-pane fade show active">
                    <div className="container-fluid toprated-games-main">
                        <div className="toprated-games">
                            <div className="row">
                                <div className="col-12 games-card-main px-0">
                                    <div className="mySwiper cardSwiper casinoCard1">
                                        <Swiper className="swiper"
                                            navigation={{
                                                nextEl: ".gamesNextBtn",
                                                prevEl: ".gamesPrevBtn",
                                            }}
                                            // mousewheel={true}
                                            // keyboard={true}
                                            breakpoints={{
                                                0: {
                                                    slidesPerView: 3,
                                                    spaceBetween: 8,
                                                },
                                                420: {
                                                    slidesPerView: 3,
                                                    spaceBetween: 8,
                                                },
                                                500: {
                                                    slidesPerView: 4,
                                                    spaceBetween: 8,
                                                },
                                                768: {
                                                    slidesPerView: 5,
                                                    spaceBetween: 8,
                                                },
                                                992: {
                                                    slidesPerView: 6,
                                                    spaceBetween: 8,
                                                },
                                                1100: {
                                                    slidesPerView: 7,
                                                    spaceBetween: 8,
                                                },
                                                1200: {
                                                    slidesPerView: 6,
                                                    spaceBetween: 10,
                                                },
                                                1400: {
                                                    slidesPerView: 7,
                                                    spaceBetween: 10,
                                                },
                                                1600: {
                                                    slidesPerView: 8,
                                                    spaceBetween: 10,
                                                }
                                            }}
                                            modules={[Navigation]}
                                            >
                                            {!gamesLoader ?
                                                games?.length > 0 ?
                                                    games?.map((item) => (
                                                        <SwiperSlide key={item?._id} className="swiper-slide " onClick={(e) => {
                                                            e.stopPropagation()
                                                        }}>
                                                            <GamesCard
                                                                // RTP={false}
                                                                RTP={item?.rtp}
                                                                onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    favouriteGame(item?._id)
                                                                }}
                                                                image={item?.image}
                                                                name={item?.name}
                                                                // isFav={false}
                                                                isFav={item?.isFav}
                                                                favButton={false}
                                                                uuid={item?.uuid}
                                                            />
                                                        </SwiperSlide>
                                                    ))
                                                    : <div className="w-100 h-100">
                                                        <h6 className="text-light"> No data available</h6>
                                                    </div>
                                                :
                                                <SkeletonLoader type={'gameCategory'}/>
                                            }
                                        </Swiper>
                                        <div className="swiper-button-prev-unique gamesPrevBtn livePrev p-0 d-inline-flex align-items-center justify-content-center cursor-pointer casinoPrev">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                                <path d="M12.1505 0.370117C18.6778 0.370117 23.9692 5.6871 23.9692 12.246C23.9692 18.8047 18.6778 24.1218 12.1505 24.1218C5.62313 24.1218 0.331718 18.8047 0.331718 12.246C0.331718 5.6871 5.62313 0.370117 12.1505 0.370117ZM8.54907 13.0503C8.60666 13.1082 8.66985 13.1571 8.73565 13.1996L13.1712 17.6566C13.3866 17.8732 13.669 17.9815 13.9514 17.9815C14.2338 17.9815 14.516 17.8732 14.7316 17.6566C15.1626 17.2235 15.1626 16.5213 14.7316 16.0884L10.9095 12.2472L14.7124 8.42598C15.1434 7.99267 15.1434 7.29065 14.7124 6.85774C14.2814 6.42467 13.5827 6.4249 13.1517 6.8575L8.73572 11.2946C8.66993 11.3371 8.60674 11.3859 8.54938 11.4439C8.32908 11.6652 8.22279 11.9568 8.22775 12.2472C8.22255 12.5373 8.32861 12.8288 8.54907 13.0503Z" fill="#00D4D6"/>
                                            </svg>
                                        </div>
                                        <div className="swiper-button-next-unique gamesNextBtn liveNext p-0 d-inline-flex align-items-center justify-content-center cursor-pointer casinoNext">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                                <path d="M12.1061 0.370117C5.57877 0.370117 0.287354 5.6871 0.287354 12.246C0.287354 18.8047 5.57877 24.1218 12.1061 24.1218C18.6335 24.1218 23.9249 18.8047 23.9249 12.246C23.9249 5.6871 18.6335 0.370117 12.1061 0.370117ZM15.7075 13.0503C15.6499 13.1082 15.5867 13.1571 15.5209 13.1996L11.0854 17.6566C10.8699 17.8732 10.5876 17.9815 10.3052 17.9815C10.0228 17.9815 9.74055 17.8732 9.52497 17.6566C9.09398 17.2235 9.09398 16.5213 9.52497 16.0884L13.3471 12.2472L9.5442 8.42598C9.11321 7.99267 9.11321 7.29065 9.5442 6.85774C9.97519 6.42467 10.6739 6.4249 11.1049 6.8575L15.5209 11.2946C15.5867 11.3371 15.6498 11.3859 15.7072 11.4439C15.9275 11.6652 16.0338 11.9568 16.0288 12.2472C16.034 12.5373 15.928 12.8288 15.7075 13.0503Z" fill="#00D4D6"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CasionGames