import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAPIAuth, postAPIAuthFormData } from "../../../service/apiInstance";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import BonusDetailModal from "./BonusDetailModal";
import { fetchUserDetails } from "../../../service/fetchUserDetails";
import { reducerConst } from "../../../constants/storeConstants";
import { useAuth } from "../../../hooks/useAuth";

const UserVipDetails = () => {
  const [data, setData] = useState([]);
  const [allrankdata, setALLRankData] = useState([]);
  const userDetails = useSelector((state) => state.userDetails);
  const [showBonusModal, setShowBonusModal] = useState(false)
  const [progressValue, setProgressValue] = useState(50);
  const { t } = useTranslation()
  const VIPClubLabels = t("VIPClubLabels", { returnObjects: true })
  const dispatch = useDispatch();
  const { token } = useAuth();



  const calculateProgress = (min, max, index) => {
    // Calculate the progress value as a percentage
    let finalpercent;
    const progress = (userDetails?.wagerAmount / max) * 100;
    if (index == 0) {
      finalpercent = progress;
    }
    if (index == 1 && userDetails?.wagerAmount >= min) {
      finalpercent = progress;
    }
    if (index == 2 && userDetails?.wagerAmount >= min) {
      finalpercent = progress;
    }
    return finalpercent;
  };

  const Rankfunction = async () => {
    try {
      const res = await getAPIAuth("user/next-ranks");
      setData(res.data.data);

    } catch (error) {

    }
  };

  const AllRankFunction = async () => {
    try {
      const res = await getAPIAuth("user/get-ranking-list");
      setALLRankData(res.data.data);

    } catch (error) {

    }
  };

  const AllrankcalculateProgress = (min, max, index) => {
    let finalPercent = 0;
    const progress = (userDetails?.wagerAmount / max) * 100;
    const conditions = Array.from({ length: index + 1 }, (_, i) => {
      if (i === 0) {
        return true;
      }
      return true;
    });
    for (let i = 0; i < conditions.length; i++) {
      if (conditions[i] && userDetails?.wagerAmount >= min) {
        finalPercent = progress;
      }
    }
    return finalPercent;
  };

  useEffect(() => {
    Rankfunction();
    AllRankFunction();
  }, []);

  useEffect(() => {
    //   "hello",
    //   allrankdata,
    //   AllrankcalculateProgress(
    //     allrankdata[0]?.min_amount,
    //     allrankdata[0]?.max_amount,
    //     0
    //   )
    // );
  }, [allrankdata]);

  const calculateWager = (wager) => {

    // if (wager >= 999_999) {
    //   return (wager / 1_000_000).toFixed(0) + "M"
    // } else if (wager >= 1000) {
    //   return (wager / 1000).toFixed(0) + 'K'
    // } else {
    //   return wager
    // }
    if (wager >= 1_000_000_000) {
      return (wager / 1_000_000_000).toFixed(0) + "B";
    } else if (wager >= 1_000_000) {
      return (wager / 1_000_000).toFixed(0) + "M";
    } else if (wager >= 1000) {
      return (wager / 1000).toFixed(0) + 'K';
    } else {
      return wager;
    }
  }

  const handleProfilePic = async (e)=> {
    const fileObj = e.target.files && e.target.files[0]

    if(!fileObj) {
      return
    }

    const formData = new FormData()
    formData.append('typename', 'profilePic')
    formData.append('image', fileObj)

    console.log('formData', formData)

    const res = await postAPIAuthFormData('user/update-profile-pic', formData)
    if(res?.data?.success) {
      updateUserDetails();
    }
  }


  const updateUserDetails = async () => {
    const res = await fetchUserDetails(token);
    dispatch({
      type: reducerConst.USER_DETAILS,
      payload: res?.data?.data ? res.data.data : {},
    });
  };


  return (
    <>
      <div className="col-12 col-lg-4 d-flex align-items-center justify-content-between mb-5">
        <div className="dollor me-4 d-block d-lg-none">
          TOTAL BONUS received:
        </div>
        <div className="div divv d-flex align-items-center d-block d-lg-none">
          <span>${userDetails?.totalbonus}</span>
          <button className="detailBtn detailsBtn detailsBtnMb cursor-pointer pe-0"
            onClick={() => setShowBonusModal(true)}
          >
            {VIPClubLabels.DETAILS}
          </button>
        </div>
        {/* <button className="detailsBtn">Details</button> */}
      </div>
      <div className="row dashboardWrapper mx-0">
        <div className="col-12 px-0">
          <div className="row mx-0">
            <div className="col-auto fixwidth d-none d-lg-flex align-items-center pe-0">
              <div className="dollor d-lg-flex align-items-center d-none">
                <span>${userDetails?.wagerAmount} </span> / $ {calculateWager(userDetails?.nextlevel?.min_amount)} Wagered
              </div>
            </div>
            <div className="col px-0 d-flex justify-content-center flex-column">
              <div className="m-auto position-relative userProfilePicWrapper">
                <div className="user">
                  {
                  userDetails?.image ? 
                    <img src={userDetails?.image} alt="" />
                  : <img src='assets/img/vipPage/dashboardUser.png' alt="" />
                  }
                  
                </div>
                <input type="file" className="d-none" onChange={handleProfilePic} id="userProfileImage" />
                <label htmlFor="userProfileImage" className="position-absolute userProfileLabel">
                  <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="18px" width="18px" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75zM20.71 5.63l-2.34-2.34a.996.996 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83a.996.996 0 0 0 0-1.41z"></path></svg>
                </label>
              </div>
              <div className="userName d-lg-none">"{userDetails?.username}"<br /><span className="d-inline-block mt-1">“{userDetails?.currentlevel}”</span></div>
              <div className="dollor d-flex align-items-center justify-content-center d-lg-none text-center mt-3">
                <span>
                  ${userDetails?.wagerAmount}</span> / ${calculateWager(userDetails?.nextlevel?.min_amount)}
                Wagered
              </div>
            </div>
            <div className="col-auto fixwidth d-none d-lg-flex align-items-center justify-content-end px-0">
              <div className="dollor d-none d-lg-block me-3">
                TOTAL BONUS received:<span>${userDetails?.totalbonus}</span>
              </div>
              <button className="detailBtn  d-none d-lg-block detailsBtn cursor-pointer"
                onClick={() => setShowBonusModal(true)}
              >
                {VIPClubLabels.DETAILS}
              </button>
              {/* <button className="detailsBtn">Details</button> */}
            </div>
          </div>
        </div>
        <div className="col-12 mb-4">
          <div className="row justify-content-between align-items-end d-none d-lg-flex">
            <div className="nextRank fixwidthSmall col-auto d-flex align-items-center">
              <span />
              {VIPClubLabels.NEXT_RANKS}
            </div>
            <div className="col">
              <div className="userName">{userDetails?.username}<br /><span className="d-inline-block mt-1">{userDetails?.currentlevel}</span></div>
            </div>
            <div className="allRank fixwidthSmall d-flex justify-content-end col-auto">
              <a
                href="#offcanvasExample"
                className="anchor"
                data-bs-toggle="offcanvas"
                aria-controls="offcanvasExample"
              >
                {VIPClubLabels.All_Ranks}
              </a>
            </div>
          </div>
        </div>
        {/* <div className="col-lg-4 col-md-5 mb-md-0 mb-3">
          <div className="profile h-100 mt-3 mt-sm-0">
            <div className="row">
              <div className="col-12 d-sm-block d-none mb-3">
                <div className="user">“{userDetails?.username}”</div>
              </div>
              <div className="col-12 mb-3">
                <div className="userPic">
                  <img src={userDetails?.image} alt="" />
                </div>
              </div>
              <div className="col-12 d-sm-none d-block mb-2">
                <div className="user">“{userDetails?.username}”</div>
              </div>
              <div className="col-12 mb-2">
                <div className="rank">{VIPClubLabels.RANK}- {userDetails?.level}</div>
              </div>
              <div className="col-12">
                <div className="txt">
                  <span>${userDetails?.wagerAmount} </span>/ {calculateWager( userDetails?.nextlevel?.min_amount)} {VIPClubLabels.Wagered}
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="col-lg-12 col-md-7 mt-3 mt-sm-0">
          {/* <div className="row">
            <div className="col-12 pb-4 d-sm-none d-block">
              <div className="row justify-content-between ">
                <div className="nextRank col-auto d-flex align-items-center">
                  <span />
                  {VIPClubLabels.NEXT_RANKS}
                </div>
                <div className="allRank col-auto">
                  <a
                    href="#offcanvasExample"
                    className="anchor d-block"
                    data-bs-toggle="offcanvas"
                    aria-controls="offcanvasExample"
                  >
                    {VIPClubLabels.All_Ranks}
                  </a>
                </div>
              </div>
            </div>
          </div> */}
          <div className="nextRankBox h-100 d-none d-lg-block">
            <div className="row">
              <div className="col-12 pb-1 d-sm-block d-none">
                {/* <div className="row justify-content-between ">
                  <div className="nextRank col-auto d-flex align-items-center">
                    <span />
                    {VIPClubLabels.NEXT_RANKS}
                  </div>
                  <div className="allRank col-auto">
                    <a
                      href="#offcanvasExample"
                      className="anchor"
                      data-bs-toggle="offcanvas"
                      aria-controls="offcanvasExample"
                    >
                      {VIPClubLabels.All_Ranks}
                    </a>
                  </div>
                </div> */}
              </div>
              {data?.map((item, index) => (
                <>
                  <div className="col-12 py-3">
                    <div className="row maxwidth align-items-center">
                      <div className="col-auto widthh">
                        <div className="row align-items-center">
                          <div className="col-auto pe-0">
                            <div className="icon">
                              <img src={item?.image} alt="" />
                            </div>
                          </div>
                          <div className="col">
                            <div className="dAppRankerrr">{item?.name}</div>
                            <div className="stock">
                              {VIPClubLabels.Wager} ${calculateWager(item?.max_amount)}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="progressBar">
                          <div
                            className="progress progressFuill"
                            role="progressbar"
                            aria-label="Basic example"
                          >
                            <div
                              className="progress-bar progressInner"
                              style={{
                                width: `${calculateProgress(
                                  item.min_amount,
                                  item.max_amount,
                                  index
                                )}%`,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 my-4">
        <div className="row justify-content-between d-flex d-lg-none">
          <div className="nextRank col-auto d-flex align-items-center">
            <span />
            {VIPClubLabels.NEXT_RANKS}
          </div>
          <div className="allRank col-auto">
            <a
              href="#offcanvasExample"
              className="anchor"
              data-bs-toggle="offcanvas"
              aria-controls="offcanvasExample"
            >
              {VIPClubLabels.All_Ranks}
            </a>
          </div>
        </div>
      </div>
      <div className="nextRankBox nextRankBoxx h-100 d-block d-lg-none">
        <div className="row">
          <div className="col-12 pb-1 d-sm-block d-none">
            {/* <div className="row justify-content-between ">
                  <div className="nextRank col-auto d-flex align-items-center">
                    <span />
                    {VIPClubLabels.NEXT_RANKS}
                  </div>
                  <div className="allRank col-auto">
                    <a
                      href="#offcanvasExample"
                      className="anchor"
                      data-bs-toggle="offcanvas"
                      aria-controls="offcanvasExample"
                    >
                      {VIPClubLabels.All_Ranks}
                    </a>
                  </div>
                </div> */}
          </div>
          {data?.map((item, index) => (
            <>
              <div className="col-12 py-3">
                <div className="row maxwidth align-items-center">
                  <div className="col-auto widthh">
                    <div className="row align-items-center">
                      <div className="col-auto pe-0">
                        <div className="icon">
                          <img src={item?.image} alt="" />
                        </div>
                      </div>
                      <div className="col">
                        <div className="dAppRankerrr">{item?.name}</div>
                        <div className="stock">
                          {VIPClubLabels.Wager} ${calculateWager(item?.max_amount)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="progressBar">
                      <div
                        className="progress progressFuill"
                        role="progressbar"
                        aria-label="Basic example"
                      >
                        <div
                          className="progress-bar progressInner"
                          style={{
                            width: `${calculateProgress(
                              item.min_amount,
                              item.max_amount,
                              index
                            )}%`,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
      {/* all ranks offcanvas------------------------->>> */}
      <div
        className="offcanvas offcanvas-start allRanksOffcanvas"
        tabIndex={-1}
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header allRanksOffcanvasHeader d-flex align-items-center">
          <h5
            className="offcanvas-title allRanksOffcanvasTitle"
            id="offcanvasExampleLabel"
          >
            {VIPClubLabels.ALL_RANKS}
          </h5>
          <button
            type="button"
            className=" offcanvasCloseBtn p-0 mb-0 me-0"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <img src="assets/img/vipPage/close.png" alt="" />
          </button>
        </div>
        <div className="offcanvas-body allRanksOffcanvasBody">
          {allrankdata?.map((item, index) => (
            <div className="row align-items-center py-2 mx-0">
              <div className="col-auto ps-0">
                <div className="logo mt-1">
                  <img src={item.image} alt="" />
                </div>
              </div>
              <div className="col pe-0">
                <div className="row align-items-center">
                  <div className="col-12 ps-0">
                    <div className="logoTxt">{item?.name}</div>
                  </div>
                  <div className="col-12">
                    <div className="row align-items-center">
                      <div className="col-7 ps-0">
                        <div className="progressBar">
                          <div
                            className="progress progressWrapper"
                            role="progressbar"
                            aria-label="Basic example"
                          >
                            <div
                              className="progress-bar progressInner"
                              style={{
                                width: `${AllrankcalculateProgress(
                                  item.min_amount,
                                  item.max_amount,
                                  index
                                )}%`,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-5 text-end ps-0 pe-3">
                        <div className="progressTxt">
                          {VIPClubLabels.Wager} ${calculateWager(item.max_amount)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <BonusDetailModal showBonusModal={showBonusModal} setShowBonusModal={setShowBonusModal} />
    </>
  );
};

export default UserVipDetails;
