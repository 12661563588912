import React, { useEffect, useState } from "react";
import { getAPIAuth } from "../../../service/apiInstance";
import { useSelector } from "react-redux";
import { useAuth } from "../../../hooks/useAuth";
import moment from "moment";
import { useTranslation } from "react-i18next";
import CasinoTransaction from "./transactionComponent/CasinoTransaction";
import SportsTransaction from "./transactionComponent/SportsTransaction";
import AllTransaction from "./transactionComponent/AllTransactions";
import PokerTransaction from "./transactionComponent/PokerTransactions";

const Transaction = ({ openaccordian, tabopen }) => {
    const [transactionData, setTransactionData] = useState()
    const userDetails = useSelector(state => state?.userDetails)
    const { token } = useAuth()
    const [pageNo, setPageNo] = useState(1)
    const [totalPages, setTotalPage] = useState()
    const [pagination, setPagination] = useState({
        btnOne: 1,
        btnTwo: 2,
        btnThree: 3,
        btnFour: "...",
        btnFive: 0
    })
    const [isLoadMore, setIsLoadMore] = useState(false)
    const { t } = useTranslation()
    const accountPageLabels = t("accountPageLabels", { returnObjects: true })
    const [activeTab, setActiveTab] = useState('all')

    const paginationHandler = (count) => {
        if (pagination.btnOne != pageNo) {
            if (pageNo - 1 == pagination.btnThree) {
                setPagination(
                    prev => ({
                        ...prev,
                        btnOne: pagination.btnOne + 1,
                        btnTwo: pagination.btnTwo + 1,
                        btnThree: pagination.btnThree + 1
                    })
                )
            } else if (pageNo + 1 == pagination.btnOne) {
                setPagination(
                    prev => ({ ...prev, btnOne: pagination.btnOne - 1, btnTwo: pagination.btnTwo - 1, btnThree: pagination.btnThree - 1 })
                )
            } else if (pageNo == totalPages) {
                setPagination(
                    prev => ({ ...prev, btnOne: totalPages - 2, btnTwo: totalPages - 1, btnThree: totalPages })
                )
            }
        }
    }
    // const getData = async () => {
    //     try {
    //         const res = await getAPIAuth(`user/userTransactions/${userDetails?.id}?page=${pageNo}`)
    //         // const res = await getAPIAuth(`user/userTransactions/${"64be2d4fcb1b31b159cc6378"}`)
    //         if (res.data.success) {
    //             setTransactionData(res.data.data)
    //             setTotalPage(res.data.totalPages)
    //             setPagination({
    //                 btnOne: 1,
    //                 btnTwo: 2,
    //                 btnThree: 3,
    //                 btnFour: "...",
    //                 btnFive: res.data.totalPages
    //             })
    //         }
    //     } catch (error) {
    //         //error handling
    //     }

    // }

    // const loadMore = async () => {
    //     try {
    //         const res = await getAPIAuth(`user/userTransactions/${userDetails?.id}?page=${pageNo}`)
    //         // const res = await getAPIAuth(`user/userTransactions/${"64be2d4fcb1b31b159cc6378"}`)
    //         if (res.data.success) {
    //             setTransactionData(res.data.data)
    //             setTotalPage(res.data.totalPages)
    //         }
    //     } catch (error) {
    //         //error handling
    //     }

    // }


    useEffect(() => {
        if (userDetails?.id && (openaccordian === "Transactions" || tabopen === "Transactions")) {
            // getData()
        }
    }, [userDetails?.id, openaccordian, token])

    useEffect(() => {
        if(userDetails?.id && (openaccordian === "Transactions" || tabopen === "Transactions")) {
            if (pageNo >= 1 && isLoadMore) {
                paginationHandler()
                // loadMore()
                setIsLoadMore(false)
            } else if (pageNo >= 1) {
                paginationHandler()
                // loadMore()
            }
        }
    }, [pageNo, userDetails?.id, openaccordian])


    return (
        <div className="dambleTable my-0">
            <div className="sectionInner">
                <div className="row">
                    <div className="col-12 d-none d-md-block">
                        <div className="d-flex align-items-center text-uppercase accountCardHeading">
                            <div className="cardImg">
                            <img
                                src="assets/img/sidebar/withdraw.png"
                                alt="SETTINGS"
                                className="h-100 w-100 img-fluid "
                            />
                            </div>
                            {accountPageLabels.Transactions}
                        </div>
                    </div>
                    <div class="col-12 pt-md-4">
                        <div class="nav nav-tabs gap-2 reffralsTab border-0">
                        <div class={`nav-link refferalBtn d-flex align-items-center justify-content-center cursor-pointer ${activeTab === 'all' ? 'active' : ''}`}
                                onClick={()=> setActiveTab('all')}
                            >All</div>
                            <div class={`nav-link refferalBtn d-flex align-items-center justify-content-center cursor-pointer ${activeTab === 'casino' ? 'active' : ''}`}
                                onClick={()=> setActiveTab('casino')}
                            >Casino</div>
                            <div class={`nav-link refferalBtn d-flex align-items-center justify-content-center cursor-pointer ${activeTab === 'sports' ? 'active' : ''}`}
                                onClick={()=> setActiveTab('sports')}
                            >Sports</div>
                            <div class={`nav-link refferalBtn d-flex align-items-center justify-content-center cursor-pointer ${activeTab === 'poker' ? 'active' : ''}`}
                                onClick={()=> setActiveTab('poker')}
                            >Poker</div>
                        </div>
                    </div>
                </div>
                {
                    activeTab === "all" ? <AllTransaction openaccordian={openaccordian} tabopen={tabopen}/>:
                    activeTab === "casino" ? <CasinoTransaction openaccordian={openaccordian} tabopen={tabopen}/>
                    : activeTab === "sports" ? <SportsTransaction openaccordian={openaccordian} tabopen={tabopen}/>
                    : activeTab === "poker" ? <PokerTransaction openaccordian={openaccordian} tabopen={tabopen}/>
                    : ''
                }
            </div>
        </div>
    );
};

export default Transaction;
