import React, { useEffect, useState } from "react";
import { errorToaster, succesToaster } from "../../utils/toaster";
import { Formik } from "formik";
import * as Yup from "yup";
import ErrorMessage from "../common/ErrorMessage/ErrorMessage";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../config/firebase";
import { useAuth } from "../../hooks/useAuth";
import axios from "axios";
import { useAuthModal } from "../../hooks/useAuthModal";
import { useSelector } from "react-redux";
import { USER_LANG, baseURL } from "../../constants";
import { Spinner } from "react-bootstrap";
import { useReferralModal } from "../../hooks/useReferralModal";
import { useBtAuth } from "../../hooks/useBtAuth";
import { useSpinModal } from "../../hooks/useSpinModal";
import { useSearchParams } from "react-router-dom";

const validationSchema = Yup.object({
    email: Yup.string()
        .required()
        .matches(
            // it can be used later//
            // /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
            // /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$|^(\+\d{1,3}\s?)?[\d-]{6,}$/i,
            // /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$|^(\+\d{1,3}\s?)?[\d-]{10,}$/i,
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
            "Invalid email"
        ),
    password: Yup.string().required(),
});

const MetaMaskModal = ({
    isOpen,
    setIsOpen,
    parent,
    heroBanner,
    checks,
    click_id,
    new_click_id,
    bonus,
    isFromCrypto
}) => {
    const [wallet, setWallet] = useState("")
    const { setToken } = useAuth()
    const { setAuthModalObject } = useAuthModal()
    const { setBtToken } = useBtAuth()
    const { open, setOpen } = useState(false)
    const [isHidden, setIsHidden] = useState(false)
    const ipAddress = useSelector(state => state.ipAddress)
    const country = useSelector(state => state.country)
    const [isLoading, setIsLoading] = useState(false)
    const [query, setQuery] = useSearchParams()
    const { isReferral, setIsReferral, isReferralInput, setIsReferralInput } = useReferralModal()

    const { setSpinModalObject } = useSpinModal()


    // ----------------login -------------------//
    const handleLogin = async (values, wallet_address) => {
        try {
            // if (values.email) {
            // const userCredential = await signInWithEmailAndPassword(
            //     auth,
            //     values.email,
            //     values.password
            // );
            const lang = localStorage.getItem(USER_LANG)
            // const lang = JSON.parse(localStorage.getItem(USER_LANG))

            // Signed in
            const body = {
                // email: values.email,
                // password: values.password,
                wallet_id: wallet_address,
                loginMethod: "metamask",
                ipAddress: ipAddress ? ipAddress : "",
                country: country ? country : "",
                language: lang?.symbol
                // language: JSON.parse(lang)
            };
            const res = await axios.post(`${baseURL}/user/social-authentication`,
                JSON.stringify(body),
                {
                    headers: {
                        "Content-Type": "application/json; charset=utf-8",
                        "Accept": "application/json"
                    }
                }
            )
            if (res?.data?.success) {
                setToken(res?.data?.data?.token);
                // alert(res?.data?.data?.token)
                succesToaster(res.data.message);
                setBtToken(res?.data?.data?.betByToken)
                // setIsOpen(false)
                setAuthModalObject(pre => ({ ...pre, isAuthOpen: false }))
                setQuery({ action: '' })
                localStorage.removeItem('clickid')

            } else {
                errorToaster(res?.data?.message);
            }
            // }

        } catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message;
            // errorToaster(error.message)
            errorToaster("Something went wrong!")
        } finally {
            setIsLoading(false)
        }
    }

    // ----- sign up ----- //
    const handleSignUp = async (values, wallet_address) => {
        try {
            // const userCredential = await createUserWithEmailAndPassword(
            //     auth,
            //     // values.email,
            //     // values.password
            // );
            // const lang = JSON.parse(localStorage.getItem(USER_LANG))
            const lang = localStorage.getItem(USER_LANG)

            const body = {
                // email: values.email,
                // password: values.password,
                wallet_id: wallet_address,
                loginMethod: "metamask",
                ipAddress: ipAddress ? ipAddress : "",
                country: country ? country : "",
                // language: lang?.symbol
                language: lang,
                trackingToken: click_id ? click_id : null,
                clickid : !!new_click_id ? new_click_id : null,
                bonus: !!bonus ? bonus.bonus : false
            };

            const res = await axios.post(`${baseURL}/user/social-authentication`,
                // body,
                JSON.stringify(body),
                {
                    headers: {
                        "Content-Type": "application/json; charset=utf-8",
                        "Accept": "application/json"
                    }
                }
            )


            if (res.data.success) {
                succesToaster(res.data.message);
                setToken(res?.data?.data?.token);
                // alert(res?.data?.data?.token)
                setBtToken(res?.data?.data?.betByToken)
                // setBtToken(res?.data?.data?.betByToken)
                // setIsOpen(false)
                setAuthModalObject(pre => ({ ...pre, isAuthOpen: false }))
                setSpinModalObject(prev => ({
                    ...prev,
                    isModalOpen: true
                }))
                // ,isReferralInput, 
                // setIsReferralInput(true)
                // setIsReferral(true)
                localStorage.removeItem('clickid')

                window?.dataLayer?.push({
                    'event': 'GA4_event',
                    'event_name': 'game_sign_up'
                });
                setQuery({ action: "" })
            } else {
                errorToaster("Something went wrong")
            }
        } catch (error) {
            const errorMessage = error.message.substr(9, 30);
            errorToaster(`${errorMessage}`,);
        } finally {
            setIsLoading(false)
        }
    }

    const handler = (values, wallet_address) => {
        // metamaskHandler()
        if (parent === 'signin') {
            // signin
            handleLogin(values, wallet_address)
        } else if (parent === 'signup') {
            // signup
            if (checks.terms) {
                handleSignUp(values, wallet_address)
            } else {
                errorToaster("User agreement is required!")
            }
        }
    }
    const metamaskHandler = (values) => {
        if (window.ethereum) {
            window.ethereum.request({ method: 'eth_requestAccounts' })
                .then(res => {
                    setWallet(res[0])
                    handler(values, res[0])
                })
        } else {
            errorToaster("Wallet not available")
            setIsLoading(false)
        }

    }

    useEffect(() => {
        if (!isOpen) {
            setIsLoading(false)
        }
    }, [])

    return (
        <div className="col-auto">
            <div className="linkImg"                     onClick={() => {
                        // setIsMetamask(true)
                        if (!!!window.ethereum) {
                            errorToaster("Metamask browser extension not found")
                        } else {
                            // setIsMetamask(true)
                            metamaskHandler()
                        }
                    }}>
                <span>
                <img

                    src={isFromCrypto ? './assets/img/stacking/metamask.png' : 'assets/img/auth/metaMask.png'}
                    alt=''
                />
                </span> 
                <span className="d-md-flex d-none">
                    Metamask
                </span> 
            </div>
        </div>
    )

    // return (
    //     <>
    //         {
    //             heroBanner ? (
    //                 <button
    //                     type="button"
    //                     // style={{ background: 'transparent', border: 'none' }}
    //                     onClick={() => {
    //                         if (checks.terms) {
    //                             if (!!!window.ethereum) {
    //                                 errorToaster("Metamask browser extension not found")
    //                             } else {
    //                                 // setIsMetamask(true)
    //                                 metamaskHandler()
    //                             }
    //                         } else {
    //                             errorToaster("Please read and tick the bottom agreement")
    //                         }
    //                     }}
    //                     // className="p-0"
    //                     className="p-0 bg-transparent border-0"

    //                 >
    //                     <div className='joinAnchor'>
    //                         <img src="assets/img/newIcon/metamask-grayscale-logo.svg" alt="" />
    //                     </div>
    //                 </button>
    //             ) : (
    //                 <li className='authOption d-flex justify-content-center gap-3'>
    //                     <div className="authIcon d-flex align-items-center justify-content-center big">
    //                         <img src="assets/img/auth/metaMask.png" alt="" />
    //                     </div>
    //                     <div
    //                         onClick={() => {
    //                             // setIsMetamask(true)
    //                             if (!!!window.ethereum) {
    //                                 errorToaster("Metamask browser extension not found")
    //                             } else {
    //                                 // setIsMetamask(true)
    //                                 metamaskHandler()
    //                             }
    //                         }}
    //                         className="authTxt d-flex align-items-center justify-content-center rounded-pill">
    //                         Meta Mask
    //                     </div>
    //                 </li>
    //             )
    //         }

    //     </>
    // )
};

export default MetaMaskModal;
