import React from 'react'
import AppFooter from '../../components/AppFooter/AppFooter'
import { APP_LEGAL_MAIL } from '../../constants'

const SportsPolicy = () => {
return (
<>
    <main className="main vh-100" id="main">
        <div className="mainContaint">
            <div className="sportpolicypage">
                <div className="container-fluid sportpolicySection">
                    <div className="row">
                        <div className="col-12 sportheading">
                            SPORTS POLICY
                        </div>
                        <div className="col-12 policydate ">
                            Last updated: August, 08 2023.
                        </div>
                        <div className="col-12 sportsubheading introducation mt-0">
                            1. Introduction
                        </div>
                        <div className="col-12 sportdetail">
                            1.1. This set of terms and conditions govern the use of the Sportsbook platform. When placing a bet with the Sportsbook platform, the Account Holder is therefore agreeing that the Account Holder has read, understood and will be adhering to these Terms and Conditions including the general Terms and Conditions at any time applicable to Sportsbook platform, click .
                        </div>
                        <div className="col-12 sportdetail">
                            1.2. The use of this Sportsbook platform is subject to the regulations imposed by the (licence).
                        </div>
                        <div className="col-12 sportdetail">
                            1.3. Any dispute relating in any way to the use of this Sportsbook platform should be emailed to {APP_LEGAL_MAIL} Should the reply not be considered satisfactory, a request for confidential arbitration can be sent to the (licence). Their decision shall be binding and may be entered as a judgement in any court of competent jurisdiction.
                        </div>
                        <div className="col-12 sportdetail">1.4. Sportsbook platform reserves the right to make changes to the site, betting limits, payout limits and offerings.</div>
                        <div className="col-12 sportdetail">1.5. Sportsbook platform may update, amend, edit and supplement these Terms and Conditions at any time.</div>
                        <div className="col-12 sportdetail">1.6. Any reference in these Terms and Conditions to words/objects that appear in singular also applies to plural. References to gender are non-binding and to be treated for information purposes only.
                        </div>
                        <div className="col-12 sportsubheading">2. Definition</div>
                        <div className="col-12 sportdetail">2.1. Sportsbook platform – legal entity engaged in betting activities in accordance with the licensing and legislative requirements of the country.</div>
                        <div className="col-12 sportdetail">2.2. Client - an individual who has agreed to the Rules for accepting bets, registered on the site (and other subdomain versions of the site).</div>
                        <div className="col-12 sportdetail">2.3. Bet – risk-based agreement concluded between the Client and the Betting Company on the outcome of an event in which they do not participate, involving a win. Bets are made on terms previously proposed by the Sportsbook platform. </div>
                        <div className="col-12 sportdetail">2.4. Stake - the amount of money transferred by the client to the Sportsbook platform and which are the main conditions for participation in a bet in accordance with these Rules.</div>
                        <div className="col-12 sportdetail">2.5. The outcome is the result of the event on which the Sportsbook platform is invited to make a bet. </div>
                        <div className="col-12 sportdetail">2.6. Odd — the number by which the bet amount of the stakeholder is multiplied when determining the payout amount if bet wins.</div>
                        <div className="col-12 sportdetail">2.7. Winnings - cash to be paid to the Client upon the outcome, on which a bet was made.</div>
                        <div className="col-12 sportdetail">2.8. Bonuses:</div>
                        <div className="col-12 sportdetail">2.8.1. Freebet - free bet, client gets just won amount on his account. For example, freebet 5 on odd 3.5 client get 5*3.5 - 5 = 12.50</div>
                        <div className="col-12 sportdetail">2.8.2. Free money - client gets a bet amount and the winning on his account. For example, freebet 5 on odd 3.5 client get  5*3.5 = 18.50</div>
                        <div className="col-12 sportdetail">2.8.3. Bet without risk - player uses his money for bet, but if bet is lost he gets his money back. </div>
                        <div className="col-12 sportsubheading">3. Betting rules</div>
                        <div className="col-12 sportdetail">3.1. Sportsbook platform reserves the right to cancel any bet made on obviously “bad” odds, switched odds or a bet made after an event has started.</div>
                        <div className="col-12 sportdetail">3.2. All bets accepted by Sportsbook platform are subject to these rules, as well as to applicable licence conditions.</div>
                        <div className="col-12 sportdetail">3.3. Sportsbook platform reserves the right to refuse, restrict, cancel or limit any bet.</div>
                        <div className="col-12 sportdetail">3.4. Sportsbook platform reserves the right to settle after the contest is final or with official results</div>
                        <div className="col-12 sportdetail">3.5. The winner of an event will be determined on the date of the event’s conclusion. Sportsbook platform does not recognize protests or overturned decisions for betting purposes. The settlement of an event suspended after the start of the competition will be decided according to the betting rules specified for that sport by Sportsbook platform</div>
                        <div className="col-12 sportdetail">3.6. No one under the age of 18 is permitted to make a bet.</div>
                        <div className="col-12 sportdetail">3.7. All rules contained herein are subject to changes and revisions by Sportsbook platform without prior written notice. All changes and revisions to our rules will be posted on the Sportsbook platform website.</div>
                        <div className="col-12 sportdetail">3.8. Maximum bet amounts on all sporting events will be determined by Sportsbook platform and are subject to change without prior written notice. Sportsbook platform also reserves the right to adjust limits on individual accounts as well.</div>
                        <div className="col-12 sportdetail">3.9. For accounts with minus balances, Sportsbook platform reserves the right to cancel any pending plays, whether placed with funds resulting from the error or not.</div>
                        <div className="col-12 sportdetail">3.10. Members are solely responsible for their own account transactions. Please be sure to review and confirm your bets for any mistakes before sending them in. Once a transaction is complete, it cannot be changed. Sportsbook platform does not take responsibility for missing or duplicate bets made by the client and will not entertain requests for alterations because a play is missing or duplicated. Clients may review their transactions in the “My Bets” of the site after each session to ensure all requested bets were accepted.</div>
                        <div className="col-12 sportdetail">3.11. Disputes must be lodged within seven (7) days from the date the bet in question has been decided. No claims will be honoured after this period. The client is solely responsible for their account transactions.</div>
                        <div className="col-12 sportdetail">3.12. Winnings will always be calculated using Decimal Odds. Please note, that when converting odds into the British standard, round-off errors may occur, since some odds don’t have an exact translation into British-style fractions. Here, we’ll show the nearest fractional odds.</div>
                        <div className="col-12 sportdetail">3.13. Sportsbook platform reserves the right to suspend a client account without prior notice.</div>
                        <div className="col-12 sportdetail">3.14. In the event of there being a discrepancy between the English language version of these rules and any other language version, the English language version will be deemed to be correct.</div>
                        <div className="col-12 sportdetail">3.15. Combo (accumulators, parlays, multis). If certain outcomes are related, for example: place a bet on Barcelona to win the La Liga combined with a Barcelona win in the deciding game, these bets will be void.</div>
                        <div className="col-12 sportdetail">3.16. Any bets placed with a system bet will not count for the wagering requirement in Bonus.</div>
                        <div className="col-12 sportdetail">3.17. Live Score Update is for guidance only. Sportsbook platform is not responsible for any errors. Sportsbook platform reserves the right to cancel any bets if the outcome is already known or if the odds have not been updated correctly due to technical issues.</div>
                        <div className="col-12 sportdetail">3.18. Outright bets are considered all in run or not and so will be settled as a loss if the selection does not take part in the event, unless otherwise stated. Dead heat rules apply where there is more than one winner. Bettors' stakes are first divided by the number of selections who tied and then this portion of their stakes is settled as a winner and the rest settled as a loser.</div>
                        <div className="col-12 sportdetail">3.19. Sportsbook platform reserves the right to void or cancel any bets where the outcome has been altered by the imposition of penalty points, enforced relegations or any other measure enforced as a result of anything other than the normal results of the games/competitions in question.</div>
                        <div className="col-12 sportdetail">3.20. All bets are settled using the information provided by the official body running the competition at the time of the result except where stated otherwise. In the case of any events outside of official competitions then bets are settled using the information provided.</div>
                        <div className="col-12 sportdetail">3.21. If one of competitors didn't start, the Sportsbook platform cancels this head to head market.</div>
                        <div className="col-12 sportdetail">3.22. If both competitors didn't finish, the winner will be the competitor who has more laps. If both competitors out in the same lap, Sportsbook platform cancels this head to head market.</div>
                        <div className="col-12 sportdetail">3.23. If competitors in the same position, Sportsbook platform cancel bets on this head to heat market.</div>
                        <div className="col-12 sportdetail">3.24. Sportsbook platform don't responsible for the damage incurred by the client as a result of a system malfunction, defects, delays, manipulations or errors in data transfer.</div>
                        <div className="col-12 sportdetail">3.25. Clients' claims are considered by Sportsbook platform within thirty days from the moment the Client submits a written application to Sportsbook platform. After making a decision, Sportsbook platform notifies the client by means of an email linked to the game account.</div>
                        <div className="col-12 sportdetail">3.26 In case of suspicion of unfair activity, Sportsbook platform reserves the right to void any bet or any part of it, thus making the questionable bet invalid (in these cases, the payout is made with odds of “1”) or suspend any withdrawals for up to 31 calendar days.</div>
                        <div className="col-12 sportdetail">3.27. Clients are allowed to bet only as individuals, group bets are not allowed. Repeated bets on the same results / winners from the same or different customers may subsequently be declared invalid. Even after the official result of the competition / athletes is already known, Sportsbook platform may consider the indicated bets invalid if it considers that the Clients act in collusion or as a syndicate, or the bets considered were made by one or more Clients within a short period of time. The betting company also has the right to refuse to accept bets or to count already made bets as invalid if they are made from different game accounts from the same IP address.</div>
                        <div className="col-12 sportdetail">3.28. LIVE bets: If the match is interrupted or postponed and does not continue in 48 hours after the scheduled time, the bets will be cancelled (except for those outcomes that are clearly defined when the game was stopped).</div>
                        <div className="col-12 sportdetail">3.29. Statistics or editorial text published at the Sportsbook platform site are to be considered as added information but Sportsbook platform does not acknowledge or accept any liability whatsoever if the information is not correct. At all times it is the Account Holder’s responsibility to be aware about circumstances relating to an event.</div>
                        <div className="col-12 sportdetail">3.30. It is forbidden to use the automatic system (any kind of scanners or robots) on Sportsbook. Sportsbook platform reserves the right to cancel any bet which made using automatic systems</div>
                        <div className="col-12 sportdetail">
                        3.31. It is forbidden to use accounts owned by other people or registered accounts on other people. Sportsbook platform reserves the right to cancel any bet which is not made by the owner of an account.</div>
                        <div className="col-12 sportsubheading">4. Bets types</div>
                        <div className="col-12 sportdetail">4.1. Single (Ordinary) - bet on a separate outcome of the event. Single bet payout equal to the product of the bid amount set for the outcome odds.</div>
                        <div className="col-12 sportdetail">4.2. Combo - bet on several independent outcomes of events. To win on express it is necessary that none of the outcomes that are included in the express, there was no loss. Losing one of the results of the combo means losing all over the combo. Combo payment is equal to the product the amount of the bet on the odds of all outcomes included in the combo.</div>
                        <div className="col-12 sportdetail">4.3. System - a set of combos, which is a complete search for variants of combos of the same size from a fixed set of outcomes. It is characterised by the same stake for each express (option system) and the same number of outcomes in each express. Betting the system must specify the total number of outcomes and number of combos (system option). Payment on the system is equal to the amount of payments on combo included in the system. </div>
                        <div className="col-12 sportdetail">4.4. A ‘Trixie’ is a combination, which includes one treble and three doubles from a selection of three matches.</div>
                        <div className="col-12 sportdetail">4.5. A ‘Patent’ is a combination, which includes one treble, three doubles and three singles from a selection of three matches.</div>
                        <div className="col-12 sportdetail">4.6. A ‘Yankee’ is a combination, which includes one fourfold, four trebles and six doubles from a selection of four matches.</div>
                        <div className="col-12 sportdetail">4.7. A ‘Canadian’ (also known as ‘Super Yankee’) is a combination, which includes one fivefold, five fourfolds, ten trebles and ten doubles from a selection of five matches.</div>
                        <div className="col-12 sportdetail">4.8. A ‘Heinz’ is a combination, which includes one sixfold, six five folds, fifteen fourfolds, twenty trebles and fifteen doubles from a selection of six matches.</div>
                        <div className="col-12 sportdetail">4.9. A ‘Super Heinz’ is a combination, which includes one sevenfold, seven sixfolds, twenty-one fivefolds, thirty-five fourfolds, thirty-five trebles and twenty-one doubles from a selection of seven matches.</div>
                        <div className="col-12 sportdetail">4.10. A ‘Goliath’ is a combination, which includes one eightfold, eight sevenfolds, twenty-eight sixfolds, fifty-six fivefolds, seventy fourfolds, fifty-six trebles and twenty-eight doubles from a selection of eight matches</div>
                        <div className="col-12 sportdetail">4.11. If odds consider more than 2 digits after the decimal point, the payout will be rounded for the second digit after the decimal point. </div>
                        <div className="col-12 sportdetail">4.12. “Cash out” is an individual offer initiated by Sportsbook platform, addressed to a bet participant, aimed at changing one or several essential betting conditions (coefficient, event calculation time, etc.) in order to fix a new result and complete the bet at the current time ( further - Cash out). The offer to redeem a bet can be both accepted and rejected by the participant of the bet. By selecting “Cash out” the participant of the bet confirms his acceptance of the new essential conditions of the bet. Cash out rates can be offered for both pre match and for live betting. The bookmaker's office reserves the right to change the offer to repurchase the bid over time, or not to form a bid to repurchase the bet without giving a reason.</div>
                        <div className="col-12 sportsubheading">5. Markets</div>
                        <div className="col-12 sportdetail">5.1. "Match" (1X2) is where it is possible to bet on the (partial or definite) outcome of a match or event. The options are: "1" = Home team, or team listed to the left side of the offer; "X" = Draw, or the selection in the middle; "2" = Away team, or team listed to the right side of the offer.</div>
                        <div className="col-12 sportdetail">5.2. "Correct Score" ( is where it is possible to bet on the (partial or definite) exact score of a match or event.</div>
                        <div className="col-12 sportdetail">5.3. "Over/Under" (Totals) is where it is possible to bet on the (partial or definite) amount of a predefined occurrence (e.g. goals, points, corners, rebounds, penalty minutes, etc.). Should the total amount of the listed occurrences be exactly equal to the betting line, then all bets on this offer will be declared void. Example: an offer where the betting line is 128.0 points and the match ends with the result 64-64 will be declared void.</div>
                        <div className="col-12 sportdetail">5.4. "Odd/Even" is where it is possible to bet on the (partial or definite) amount of a predefined occurrence (e.g. goals, points, corners, rebounds, penalty minutes, etc.)."Odd" is 1,3,5 etc.; "Even" is 0,2,4 etc.</div>
                        <div className="col-12 sportdetail">5.5. A "Head-to-Head'' and/or "Triple-Head" is a competition between two or three participants/outcomes, originating from either an officially organised event, or else, as virtually defined by the Sportsbook platform.</div>
                        <div className="col-12 sportdetail">5.6. "Half time/Full time" is where it is possible to bet on the result in half time and the final outcome of the match. E.g. if at Half time the score is 1-0 and the match ends 1-1, the winning outcome is 1/X. The bet is void if the regular time of the match is played in a different time format than those listed in the bet (i.e. other than two halves).</div>
                        <div className="col-12 sportdetail">5.7. "Period betting" is where it is possible to bet on the outcome of each separate period within a match/event. </div>
                        <div className="col-12 sportdetail">5.8. "Draw No Bet" is where it is possible to bet on either "1" or "2" as defined in 5.1. It is also common practice to refer to "Draw No Bet" in cases where no draw odds are offered. Should the specific match contain no winner (E.g. match ends as a draw), or the particular occurrence does not happen (E.g.  Draw No Bet and match ends 0-0) the stakes will be refunded.</div>
                        <div className="col-12 sportdetail">5.9. "Handicap" is where it is possible to bet on whether the chosen outcome will be victorious once the listed handicap is added/subtracted (as applicable) to the match/period/total score to which the bet refers to. In those circumstances where the result after the adjustment of the handicap line is exactly equal to the betting line, then all bets on this offer will be declared void. Example: a bet on -3.0 goals will be declared void if the team chosen wins the match by exactly 3 goals difference (3-0,4-1, 5-2, etc).</div>
                        <div className="col-12 sportdetail">Asian Handicap: Home team  (-1.75) vs Away team (+1.75). This means that the stake is divided into 2 equal bets and placed on the outcomes -1.5 and -2.0. For the bet to be fully paid out at the listed odds, Team A must win the match with a bigger margin than both of their listed handicaps (ie. 3 goals or more margin). In the eventuality that Team A wins with only a 2 goal margin, the bet will be considered as partially won with a full payout on the -1.5 part of the bet and a refund on the -2.0 side since the outcome on that part of the bet would be considered a “tie”. Should the match produce any other outcome, including a Team A victory with only 1 goal margin, the whole stake would be lost. Away teams are given a +1.75 goal advantage in the match. This means that the stake is divided into 2 equal bets and placed on the outcomes +1.5 and +2.0. </div>
                        <div className="col-12 sportdetail">5.10. "Double Chance" is where it is possible to bet simultaneously on two (partial or definite) outcomes of a match or event. The options are: 1X, 12 and X2 with "1", "X" and "2" as defined in 5.1.</div>
                        <div className="col-12 sportdetail">5.11. "Outright" or "Place" betting is where it is possible to choose from a list of alternatives and bet on the eventuality that a participant wins or places within a specified position in the classification of the listed event/competition.  </div>
                        <div className="col-12 sportdetail">5.12.  Bets on "Quarter / Half / Period X" refer to the result/score achieved in the relevant timeframe and does not include any other points/goals/events tallied from other parts of the event/match. Bets will be voided if the match is played in any other format but the one stipulated in the offer.</div>
                        <div className="col-12 sportdetail">5.13. Bets on "Result at end of Quarter / Half / Period X '' refer to the result of the match/event after termination of the stipulated time frame and will take into account all other points/goals/events tallied from previous parts of the event/match.</div>
                        <div className="col-12 sportdetail">5.14. Bets on "Race to X Points / Race to X Goals..." and similar offers refer to the team/participant reaching the earliest the particular tally of points/goals/events. If the offer lists a timeframe (or any other period restriction) it will not include any other points/goals/events tallied from other parts of the event/match which are not related to the mentioned time frame. Should the listed score not be reached within the stipulated time frame (if any), all bets will be declared void, unless otherwise stated.</div>
                        <div className="col-12 sportdetail">5.15. Bets on "Winner of Point X / Scorer of Goal X'' and similar offers refer to the team/participant scoring/winning the listed occurrence. For the settlement of these offers, no reference to events happening prior to the listed occurrence will be taken into consideration. Should the listed event not be scored/won within the stipulated time frame (if any), all bets will be declared void, unless otherwise stated.</div>
                        <div className="col-12 sportdetail">5.16. Bets referring to the happening of a particular occurrence in a pre-defined time order, such as “First Card”, or “Next Team to receive penalty minutes” will be settled as void should it not be possible, without any reasonable doubt, to decide the winning outcome, for example in case of players from different teams which are shown a card in the same interruption of play.</div>
                        <div className="col-12 sportdetail">5.17. "Team to score first and win" refers to the listed team scoring the first goal in the match and going on to win the match. Should there be no goals in the match all bets will be settled as void.</div>
                        <div className="col-12 sportdetail">5.18. Any reference to "clean sheet" indicates that the listed team must not concede any goal during the match.</div>
                        <div className="col-12 sportdetail">5.19. "Team to win from behind" refers to the listed team winning the match after having been at least 1 goal down at any point in the match.</div>
                        <div className="col-12 sportdetail">5.20. Any reference for a team to win all halves/periods (e.g. Team to win both halves) means that the listed team must score more goals than its opponent during all the stipulated halves/periods of the match.</div>
                        <div className="col-12 sportdetail">5.21. Any reference to "Injury Time" refers to the amount displayed by the designated official and not to the actual amount played.</div>
                        <div className="col-12 sportdetail">5.22. Settlement of bets on offers such as "Man of the Match", "Most Valuable Player" etc. will be based on the competition's organisers’ decision, unless otherwise stated.</div>
                        <div className="col-12 sportsubheading">6. Special rules for sports</div>
                        <div className="col-12 sportdetail">6.1. <span className="text-white fw-semibold">Soccer</span></div>
                        <div className="col-12 sportdetail">
                        6.1.1. Bets on the outcome of a match will be decided based on two halves of the scheduled number of minutes each and any time the referee adds to compensate for injuries and other stoppages. It does not include periods of extra time nor penalty shootouts if not stated.</div>
                        <div className="col-12 sportdetail">6.1.2. Corners awarded but not taken are not considered.</div>
                        <div className="col-12 sportdetail">6.1.3. Own goals will not be considered for Anytime Goalscorer or Player to score X or Next Goalscorer or more settlement purposes and are ignored.</div>
                        <div className="col-12 sportdetail">6.1.4. All players who took part in the match since kick off are considered as runners.</div>
                        <div className="col-12 sportdetail">6.1.5. For “Player Props” markets, if a player is not in the starting XI all player markets will be voided.</div>
                        <div className="col-12 sportdetail">6.1.6. Anytime Goalscorer markets - bets will stand, if a player came into the game from substitute. Non fielders and players who were listed in the squad but remained on the substitute bench and did not play in the game will be voided. (Non fielders - player who were not listed in the match day squad)</div>
                        <div className="col-12 sportdetail">6.1.7. First Goalscorer markets - bets will stand, if a player came into the game from substitute before the first goal was scored. Non fielders and players who were listed in the squad but remained on the substitute bench and did not play in the game will be voided. (Non fielders - player who were not listed in the match day squad)</div>
                        <div className="col-12 sportdetail">6.1.8. Last Goalscorer markets - bets will stand, if a player came into the game from substitute. Non fielders and players who were listed in the squad but remained on the substitute bench and did not play in the game will be voided. If a player came out of the game before the last goal was scored, bets will be voided. (Non fielders - player who were not listed in the match day squad).</div>
                        <div className="col-12 sportdetail">6.1.9. If for any reason an unlisted player scores a goal all bets on listed players stand. </div>
                        <div className="col-12 sportdetail">6.1.10. Anytime Goalscorer or Player to score X or Next Goalscorer market will be settled based on TV insert and statistics provided by Press Association unless there is clear evidence that these statistics are not correct.</div>
                        <div className="col-12 sportdetail">6.1.11. Interval markets will be settled based on the goal time announced by TV. If this is not available, the time according to the match clock is considered.</div>
                        <div className="col-12 sportdetail">6.1.12. Interval goal markets are settled based on the time the ball crosses the line, and not the time the kick is made.</div>
                        <div className="col-12 sportdetail">6.1.13. Corner interval markets are settled based on the time the corner kick is taken and not the time the corner is conceded or awarded.</div>
                        <div className="col-12 sportdetail">6.1.14. Booking interval markets are settled based on the time the card is shown and not the time the infringement is made.</div>
                        <div className="col-12 sportdetail">6.1.15. Offsides will be settled based on the time when the referee gives the decision. This rule will be applied to any video assistant referee (VAR) situation.</div>
                        <div className="col-12 sportdetail">6.1.16. Penalty markets will be settled based on the time when the referee gives the decision. This rule will be applied to any video assistant referee (VAR) situation.</div>
                        <div className="col-12 sportdetail">6.1.17. Penalties awarded but not taken are not considered.</div>
                        <div className="col-12 sportdetail">6.1.18. Next scoring type.Freekick: The goal has to be scored directly from the freekick or corner to qualify as a goal by freekick. Deflected shots count as long as the freekick or corner taker is awarded the goal. Penalty: Goal must be scored directly from the penalty. Goals after a rebound of a missed penalty do not count. Own Goal: If a goal is declared as an own goal. Header: The scorer's last touch has to be with the head. Shot: Goal has to be with any other part of the body than the head and the other types do not apply.No Goal: If goal has not been scored. </div>
                        <div className="col-12 sportdetail">6.1.19. If the market was opened with a missing or incorrect red card, we reserve the right to void betting.</div>
                        <div className="col-12 sportdetail">6.1.20. If odds were offered with an incorrect match time (more than 5 minutes), we reserve the right to void the betting.</div>
                        <div className="col-12 sportdetail">6.1.21.  If a wrong score is entered, all markets will be cancelled for the time when the incorrect score was displayed.</div>
                        <div className="col-12 sportdetail">6.1.22. If the team names or tournament are displayed incorrectly, we reserve the right to void the betting.</div>
                        <div className="col-12 sportdetail">6.1.23. Yellow card counts as 1 card and red or yellow-red card as 2. The 2nd yellow for one player which leads to a yellow red card is not considered. As a consequence one player cannot cause more than 3 cards.</div>
                        <div className="col-12 sportdetail">6.1.24. Settlement will be made according to all available evidence of cards shown during the regular 90 minutes play.</div>
                        <div className="col-12 sportdetail">6.1.25. Cards shown after the match are not considered.</div>
                        <div className="col-12 sportdetail">6.1.26. Cards for non-players (already substituted players, managers, players on bench) are not considered.</div>
                        <div className="col-12 sportdetail">6.1.27. Yellow cards count as 10 points and red or yellow red cards as 25. The 2nd yellow for one player which leads to a yellow red card is not considered. As a consequence one player cannot cause more than 35 booking points.</div>
                        <div className="col-12 sportdetail">6.1.28. Settlement will be made according to all available evidence for cards shown during the regular 90 minutes play.</div>
                        <div className="col-12 sportdetail">6.1.29. If the match format was changed, the sportsbook reserves the right to void all bets. </div>
                        <div className="col-12 sportdetail">6.1.30. If a friendly match ended by referee decision earlier than 80 minutes, the bets will be canceled.</div>
                        <div className="col-12 sportdetail">6.1.31. All soccer player props bets are settled using the information provided by OPTA ( <a href="javascript:;" className="sportdetail">https://soccerstats.info/</a> ).</div>
                        <div className="col-12 sportdetail">6.1.32. The post or the crossbar (woodwork) — in the event that the ball hits the post of the crossbar and then remains in play (touched by a player or the referee, off the other post or crossbar, etc.). If the ball hits the goal post/crossbar then bounces onto another post/crossbar, only one post/crossbar hit is counted, if after the first hit the ball was not touched by a player or referee.</div>
                        <div className="col-12 sportdetail">6.1.33. Offsides. if there is a free kick after an offside is called - offside counted.</div>
                        <div className="col-12 sportdetail">6.1.34. The use of the Video Assistant Referee (VAR) ("Video Review") during matches is confirmed by TV broadcasts after the following events: the head referee gives the sign for a video review (a box drawn in the air), the head referee checks the incident on the on-pitch video review screen.</div>
                        <div className="col-12 sportdetail">Note. The message "Goal Check" on the scoreboard, or the referee putting their hand to their ear, are not indications of an official video review. All other conversations between referees and their associates are also not considered official view reviews. <a href="javascript::\;" className="sportdetail"> More on the official video review system (VAR).</a> </div>
                        <div className="col-12 sportdetail">6.1.35. Bet on the medical team entering the field. The medical team is only considered to have entered the field when the referee grants permission and the player receives assistance. In the case of two medical teams being called to enter the field at the same time for different soccer teams by the head referee, such entrances are counted as one entrance medical team entrance.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3 mt-md-1">6.1.35. <span className="text-white fw-semibold">Soccer Player Props</span></div>
                        <div className="col-12 sportdetail">6.1.36.1. Goal/Own Goal</div>
                        <div className="col-12 sportdetail">Different governing bodies have different rules, and where possible Opta works with the relevant people to reflect their official decisions on goal scorers.</div>
                        <div className="col-12 sportdetail">Regarding deflections, normally a goal is awarded if the original attempt is on target. An own goal is usually awarded if the attempt is off target and deflected into the goal by an opponent.</div>
                        <div className="col-12 sportdetail">6.1.37.2. Shots</div>
                        <div className="col-12 sportdetail">A shot target is defined as any clear attempt to score that:</div>
                        <div className="col-12 pt-md-4 pt-3">
                            <ul className="m-0">
                                <li className="sportdetail">Goes into the net regardless of intent.</li>
                                <li className="sportdetail">Is a clear attempt to score that would have gone into the net but for being saved by the goalkeeper or is stopped by a player who is the last-man with the goalkeeper having no chance of preventing the goal (last line block).</li>
                                <li className="sportdetail">Goes over or wide of the goal without making contact with another player.</li>
                                <li className="sportdetail">Would have gone over or wide of the goal but for being stopped by a goalkeeper’s save or by an outfield player.</li>
                                <li className="sportdetail">Directly hits the frame of the goal and a goal is not scored.</li>
                            </ul>
                        </div>
                        <div className="col-12 sportdetail">Blocked shots are not counted as shots.</div>
                        <div className="col-12 sportdetail py-md-4 py-3">6.1.38.3. Shots on goal - any goal attempt that:</div>
                        <div className="col-12 sportdetail">
                            <ul>
                                <li className="sportdetail">Goes into the net regardless of intent.</li>
                                <li className="sportdetail">Is a clear attempt to score that would have gone into the net but for being saved by the goalkeeper or is stopped by a player who is the last-man with the goalkeeper having no chance of preventing the goal (last line block).</li>
                            </ul>
                        </div>
                        <div className="col-12 sportdetail">Shots directly hitting the frame of the goal are not counted as shots on target, unless the ball goes in and is awarded as a goal.
                            Shots blocked by another player, who is not the last-man, are not counted as shots on target.</div>
                        <div className="col-12 sportdetail">6.1.39.4. Goal Assist</div>
                        <div className="col-12 sportdetail">The final touch (pass, pass-cum-shot or any other touch) leading to the recipient of the ball scoring a goal. If the final touch (as defined in bold) is deflected by an opposition player, the initiator is only given a goal assist if the receiving player was likely to receive the ball without the deflection having taken place. Own goals, directly taken free kicks, direct corner goals and penalties do not get an assist awarded.</div>
                        <div className="col-12 sportdetail">6.1.40.5. Tackle </div>
                        <div className="col-12 sportdetail">A tackle is defined as where a player connects with the ball in a ground challenge where he successfully takes the ball away from the player in possession.</div>
                        <div className="col-12 sportdetail py-md-4 py-3 my-md-2 ">The tackled player must clearly be in possession of the ball before the tackle is made.</div>
                        <div className="col-12 sportdetail">
                            <ul>
                                <li className="sportdetail">A tackle won is deemed to be where the tackler or one of his team-mates regains possession as a result of the challenge, or that the ball goes out of play and is “safe”.</li>
                                <li className="sportdetail">A tackle lost is where a tackle is made but the ball goes to an opposition player.</li>
                            </ul>
                        </div>
                        <div className="col-12 sportdetail">Both are deemed as successful tackles however, the outcome of the tackle (won or lost) is different based on where the ball goes after the tackle.</div>
                        <div className="col-12 sportdetail">It is not a tackle, when a player cuts out a pass by any means.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3 mt-md-2">6.2. <span className="text-white fw-semibold">Tennis</span></div>
                        <div className="col-12 sportdetail">6.2.1. In case of a retirement and walk over of any player all undecided bets are considered void.</div>
                        <div className="col-12 sportdetail">6.2.2. In case of any delay (rain, darkness...) all markets remain unsettled and the trading will be continued as soon as the match continues.</div>
                        <div className="col-12 sportdetail">6.2.3. If penalty point(s) are awarded by the umpire, all bets on that game will stand.</div>
                        <div className="col-12 sportdetail">6.2.4. In case a match is finished before certain points/games are finished, all affected point/game related markets are considered void.</div>
                        <div className="col-12 sportdetail">6.2.5. If markets remain open with an incorrect score which has a significant impact on the odds, we reserve the right to void betting.</div>
                        <div className="col-12 sportdetail">6.2.6. If the players/teams are displayed incorrectly, we reserve the right to void betting.</div>
                        <div className="col-12 sportdetail">6.2.7. If a player retires all undecided markets are considered void.</div>
                        <div className="col-12 sportdetail">6.2.8. If a match is decided by a Match tie-break then it will be considered to be the 3rd set</div>
                        <div className="col-12 sportdetail">6.2.9. Every tie-break or Match tie-break counts as 1 game</div>
                        <div className="col-12 sportdetail pt-md-5 pt-4">6.3. <span className="text-white fw-semibold">Basketball</span></div>
                        <div className="col-12 sportdetail">6.3.1. Markets do not consider overtime unless otherwise stated.</div>
                        <div className="col-12 sportdetail">6.3.2. If odds were offered with an incorrect match time(more than 2 minutes), we reserve the right to void the betting.</div>
                        <div className="col-12 sportdetail">6.3.3. If markets remain open with an incorrect score which has a significant impact on the odds, we reserve the right to void betting.</div>
                        <div className="col-12 sportdetail">6.3.4. In the event that a match does not finish in a tie, but overtime is played for qualification purposes, the markets will be settled according to the result at the end of regular time.</div>
                        <div className="col-12 sportdetail">6.3.5. If a match ends before the Xth is reached, this market is considered void (cancelled). Who scores Xth point? (incl. ot), Which team will win the race to x points? (incl. ot)</div>
                        <div className="col-12 sportdetail">6.3.6. Market (Will there be overtime?) will be settled as yes if at the end of regular time the match finishes in a draw, regardless of whether or not overtime is played. </div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.4. <span className="text-white fw-semibold">American football </span></div>
                        <div className="col-12 sportdetail">6.4.1. In case of any delay (rain, darkness...) all markets remain unsettled and the trading will be continued as soon as the match continues.</div>
                        <div className="col-12 sportdetail">6.4.2. Markets do not consider overtime unless otherwise stated.</div>
                        <div className="col-12 sportdetail">6.4.3. If markets remain open with an incorrect score which has a significant impact on the odds, we reserve the right to void
                            betting.</div>
                        <div className="col-12 sportdetail">6.4.4. If odds were offered with an incorrect match time (more than 89 seconds), we reserve the right to void betting.</div>
                        <div className="col-12 sportdetail">6.4.5. If a wrong score is displayed we reserve the right to void betting for this timeframe.</div>
                        <div className="col-12 sportdetail">6.4.6. In case of abandoned or postponed matches all markets are considered void unless the match continues in the same NFL weekly schedule (Thursday - Wednesday local stadium time).</div>
                        <div className="col-12 sportdetail">6.4.7. If the teams are displayed incorrectly, we reserve the right to void betting.</div>
                        <div className="col-12 sportdetail">6.4.8. All offered players are considered as runners.</div>
                        <div className="col-12 sportdetail">6.4.9. If no further touchdown is scored, the market (Next touchdown scorer (incl. overtime)) will be voided.</div>
                        <div className="col-12 sportdetail">6.4.10.  Players which are not listed are considered as “Competitor1 other player” or “Competitor2 other player” for settlement purposes. Note this does not include players which are listed without an active odd.</div>
                        <div className="col-12 sportdetail">6.4.11. Players of the Defense- or Special team are considered as “Competitor1 d/st player” or “Competitor2 d/st player” for settlement purposes, even if the player is listed as a dedicated outcome.</div>
                        <div className="col-12 sportdetail">6.4.12. Market will be settled based on TV insert and statistics provided by official associations unless there is clear evidence that statistics are not correct.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3 mt-md-2">6.5. <span className="text-white fw-semibold">Ice Hockey</span> </div>
                        <div className="col-12 sportdetail">6.5.1. All markets (except period, overtime and penalty shootout markets) are considered for regular time only unless it is mentioned in the market.</div>
                        <div className="col-12 sportdetail">6.5.2. In the event of a game being decided by a penalty shootout, then one goal will be added to the winning team's score and the game total for settlement purposes. This applies to all markets including overtime and penalty shootout.</div>
                        <div className="col-12 sportdetail">6.5.3. If the market remains open when the following events have already taken place: goals and penalties, we reserve the right to void betting.</div>
                        <div className="col-12 sportdetail">6.5.4. If odds were offered with an incorrect match time (more than 2 minutes), we reserve the right to void the betting. </div>
                        <div className="col-12 sportdetail">6.5.5. If a wrong score is entered all markets will be cancelled for the time when the incorrect score was displayed.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3 mt-md-2">6.6. <span className="text-white fw-semibold">Baseball </span></div>
                        <div className="col-12 sportdetail">6.6.1. If an inning ends before the Xth point is reached (incl. extra innings), this market (Which team wins race to x points?,
                            Who scores the Xth point (incl. ot)) is considered void (cancelled).</div>
                        <div className="col-12 sportdetail">6.6.2. Market (When will the match be decided?) will be settled as “Any extra inning” if at the end of regular time (After a full 9 Innings) the match finishes in a draw, regardless of whether or not overtime (Extra innings) is played.</div>
                        <div className="col-12 sportdetail">6.6.3. Market (Will there be overtime?) will be settled as “Yes” if at the end of regular time (After full 9 Innings) the match finishes in a draw, regardless of whether or not overtime (Extra innings) is played .</div>
                        <div className="col-12 sportdetail">6.6.4. Possible extra innings are not considered in any market unless otherwise stated.</div>
                        <div className="col-12 sportdetail">6.6.5. All markets will be cleared according to the final result after 9 innings (8 1⁄2 innings if the home team is leading at this point). The only exception is Winner market in pre-match (rule nr. 6.6.8.)</div>
                        <div className="col-12 sportdetail">6.6.6. If a match is interrupted or cancelled and won’t be continued on the same day, all undecided markets are considered void. The only exception is Winner market in pre-match (rule nr. 6.6.8.)</div>
                        <div className="col-12 sportdetail">6.6.7. If markets remain open with an incorrect score or incorrect match status which has a significant impact on the odds, we reserve the right to void the betting.</div>
                        <div className="col-12 sportdetail">6.6.8. The Winner market will be settled for pre-match if the match goes at least 5 innings (4.5 if the home team is leading) and is considered official.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3 ">6.7. <span>Handball </span></div>
                        <div className="col-12 sportdetail">6.7.1. If a match ends before the Xth is reached, this market (Who scores Xth point? (incl. ot)) is considered void (cancelled)</div>
                        <div className="col-12 sportdetail">6.7.2. If a match ends before the Xth is reached, this market (Which team will win race to x points? (incl. ot)) is considered void (cancelled).</div>
                        <div className="col-12 sportdetail">6.7.3. All markets (except Who scores the Xth point and Which team will win the race to X points) are considered for regular time only.</div>
                        <div className="col-12 sportdetail">6.7.4. If the match goes to a 7-metre shootout; the markets “Who scores Xth point?” and “Which team will win the race to X points?” will be voided.</div>
                        <div className="col-12 sportdetail">6.7.5. If the match goes to a 7-metre shootout; the markets “Who scores Xth point?” and “Which team will win the race to X points?” will be voided.</div>
                        <div className="col-12 sportdetail">6.7.6. If odds were offered with an incorrect match time (more than 3 minutes), we reserve the right to void the betting.</div>
                        <div className="col-12 sportdetail">6.7.7. If markets remain open with an incorrect score which has a significant impact on the odds, we reserve the right to void betting.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.8. <span className="text-white fw-semibold">Volleyball </span></div>
                        <div className="col-12 sportdetail">6.8.1. If a set ends before the Xth point is reached, this market (Who scores [Xth] point in set [y]) is considered void (cancelled)</div>
                        <div className="col-12 sportdetail">6.8.2. In the case of a match not being finished all undecided markets are considered void.</div>
                        <div className="col-12 sportdetail">6.8.3. Golden set is not considered in any of the mentioned markets.</div>
                        <div className="col-12 sportdetail">6.8.4. If markets remain open with an incorrect score which has a significant impact on the odds, we reserve the right to void betting.</div>
                        <div className="col-12 sportdetail">6.8.5. Official points deductions will be taken into account for all undetermined markets. Markets which have already been determined will not take deductions into account.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.9. <span className="text-white fw-semibold">Beach volleyball</span> </div>
                        <div className="col-12 sportdetail">6.9.1. if a set ends before the Xth point is reached, this market (Who scores [Xth] point in set [y]) is considered void (cancelled)</div>
                        <div className="col-12 sportdetail">6.9.2. In the case of a match not being finished all undecided markets are considered void.</div>
                        <div className="col-12 sportdetail">6.9.3. Golden set is not considered in any of the mentioned markets</div>
                        <div className="col-12 sportdetail">6.9.4. If markets remain open with an incorrect score which has a significant impact on the odds, we reserve the right to void betting.</div>
                        <div className="col-12 sportdetail">6.9.5. If a team retires all undecided markets are considered void.</div>
                        <div className="col-12 sportdetail">6.9.6. Official points deductions will be taken into account for all undetermined markets. Markets which have already been determined will not take deductions into account.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3 mt-md-0">6.10 <span className="text-white fw-semibold">Futsal</span></div>
                        <div className="col-12 sportdetail">6.10.1. All markets (except halftime, first half markets, overtime and penalty shoot out) are considered for regular time only.</div>
                        <div className="col-12 sportdetail">6.10.2. If a match is interrupted and continued within 48h after the initial kick-off date, all open bets will be settled with the final result. Otherwise all undecided bets are considered void.</div>
                        <div className="col-12 sportdetail">6.10.3. If the market remains open when the following events have already taken place: goals, red or yellow-red cards and penalties, we reserve the right to void the betting.</div>
                        <div className="col-12 sportdetail">6.10.4. If the market was opened with a missing or incorrect red card, we reserve the right to void betting.</div>
                        <div className="col-12 sportdetail">6.10.5. If odds were offered with an incorrect match time (more than 2 minutes), we reserve the right to void the betting.</div>
                        <div className="col-12 sportdetail">6.10.6 If a wrong score is entered, all markets will be cancelled for the time when the incorrect score was displayed.</div>
                        <div className="col-12 sportdetail">6.10.7. If the team names or tournament are displayed incorrectly, we reserve the right to void the betting.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3 mt-md-0">6.11. <span className="text-white fw-semibold">Badminton</span></div>
                        <div className="col-12 sportdetail">6.11.1. If a set ends before the Xth point is reached, this market (Who scores [Xth] point in [Nth] set) is considered void (cancelled)</div>
                        <div className="col-12 sportdetail">6.11.2. In the case of a match not being finished, all undecided markets are considered void.</div>
                        <div className="col-12 sportdetail">6.11.3. If markets remain open with an incorrect score which has a significant impact on the odds, we reserve the right to void betting.</div>
                        <div className="col-12 sportdetail">6.11.4. If a team retires all undecided markets are considered void.</div>
                        <div className="col-12 sportdetail">6.11.5. If the players/teams are displayed incorrectly, we reserve the right to void betting.</div>
                        <div className="col-12 sportdetail">6.11.6. Official points deductions will be taken into account for all undetermined markets. Markets which have already been determined will not take deductions into account.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3 mt-md-0">6.12. <span className="text-white fw-semibold">Rugby union and rugby league</span></div>
                        <div className="col-12 sportdetail">6.12.1. All markets (except halftime, first half markets, overtime and penalty shoot out) are considered for regular time only.</div>
                        <div className="col-12 sportdetail">6.12.2. Regular 80 Minutes: Markets are based on the result at the end of a scheduled 80 minutes play unless otherwise stated. This includes any added injury or stoppage time but does not include extra-time, time allocated for a penalty shootout or sudden death.</div>
                        <div className="col-12 sportdetail">6.12.3. If the market remains open when the following events have already taken place: score changes or red cards, we reserve the right to void the betting.</div>
                        <div className="col-12 sportdetail">6.12.4. If the market was opened with a missing or incorrect red card, we reserve the right to void betting.</div>
                        <div className="col-12 sportdetail">6.12.5. If odds were offered with an incorrect match time (more than 2 minutes), we reserve the right to void the betting.</div>
                        <div className="col-12 sportdetail">6.12.6. If the team names or tournament are displayed incorrectly, we reserve the right to void the betting.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3 mt-md-0">6.13. <span className="text-white fw-semibold"> Rugby Sevens</span></div>
                        <div className="col-12 sportdetail">6.15.1. In the case of a retirement of a player or disqualification all undecided markets are considered void. </div>
                        <div className="col-12 sportdetail">6.15.2. In case of a re-rack settlement stays if the outcome was determined before the re-rack</div>
                        <div className="col-12 sportdetail">6.15.3. No fouls or free balls are considered for settlement of any Potted- Colour market</div>
                        <div className="col-12 sportdetail">6.15.4. In case of a frame starting but not being completed, all frame related markets will be voided unless the outcome has already been determined</div>
                        <div className="col-12 sportdetail">6.15.5. If markets remain open with an incorrect score which has a significant impact on the odds, we reserve the right to void betting.</div>
                        <div className="col-12 sportdetail">6.15.6. If the players/teams are displayed incorrectly, we reserve the right to void betting.</div>
                        <div className="col-12 sportdetail">6.15.7. If a match is not completed all undecided markets are considered void.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3 mt-md-0">6.16. <span className="text-white fw-semibold">Table tennis </span></div>
                        <div className="col-12 sportdetail">6.16.1. If a set ends before the Xth point is reached, this market (Who scores [Xth] point in set [y]) is considered void (cancelled)</div>
                        <div className="col-12 sportdetail">6.16.2. In the case of a match not being finished all undecided markets are considered void.</div>
                        <div className="col-12 sportdetail">6.16.3. If markets remain open with an incorrect score which has a significant impact on the odds, we reserve the right to void betting.</div>
                        <div className="col-12 sportdetail">6.16.4. If the players/teams are displayed incorrectly, we reserve the right to void betting.</div>
                        <div className="col-12 sportdetail">6.16.5. If a player retires all undecided markets are considered void.</div>
                        <div className="col-12 sportdetail">6.16.6. Official points deductions will be taken into account for all undetermined markets. Markets which have already been determined will not take deductions into account.</div>
                        <div className="col-12 sportdetail mt-md-0 pt-md-4 pt-3">6.17. <span className="text-white fw-semibold">Bowls</span></div>
                        <div className="col-12 sportdetail">6.17.1. If a set ends before the Xth point is reached, this market (Xth set - which team wins race to x points, Xth set - which team scores Xth point) is considered void (cancelled)</div>
                        <div className="col-12 sportdetail">6.17.2. In case of a retirement and walk over of any player all undecided bets are considered void. </div>
                        <div className="col-12 sportdetail">6.17.3. If markets remain open with an incorrect score which has a significant impact on the odds, we reserve the right to void betting.</div>
                        <div className="col-12 sportdetail">6.17.4. If the players/teams are displayed incorrectly, we reserve the right to void betting.</div>
                        <div className="col-12 sportdetail">6.17.5. If a player retires all undecided markets are considered void.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3 mt-1">6.18. <span className="text-white fw-semibold">Cricket</span></div>
                        <div className="col-12 sportdetail">6.18.1. Match Betting</div>
                        <div className="col-12 sportdetail">Description: Who will win the match?</div>
                        <div className="col-12 sportdetail">Rules: All match betting will be settled in accordance with official competition rules. In matches affected by adverse weather, bets will be settled according to the official result.If there is no official result, all bets will be void.In the case of a tie, if the official competition rules do not determine a winner then deadheat rules will apply. In competitions where a bowl off or super over determines a winner, bets will be settled on the official result.In First Class Matches, if the official result is a tie, bets will be settled as a dead-heat between both teams. Bets on the draw will be settled as losers.If a match is abandoned due to external factors, then bets will be void unless a winner is declared based on the official competition rules.If a match is cancelled then all bets will be void if it is not replayed or restarted within 36 hours of its advertised start time.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.2. Double Chance</div>
                        <div className="col-12 sportdetail">Description: Will the match result be either of the three options given?Rules: A tie will be settled as a dead heat. All match betting will be settled in accordance with official competition rules.If there is no official result, all bets will be void.</div>
                        <div className="col-12 sportdetail">6.18.3. Match Betting: Draw No Bet</div>
                        <div className="col-12 sportdetail">Description: Who will win the match given that all bets will be void if the match is a draw?Rules: A tie will be settled as a dead heat. All match betting will be settled in accordance with official competition rules. If there is no official result, all bets will be void.</div>
                        <div className="col-12 sportdetail">6.18.4. Tied Match</div>
                        <div className="col-12 sportdetail">Description: Will the match be tied?Rules: All bets will be settled according to the official result. If the match is abandoned or there is no official result, all bets will be void. For First Class matches a tie is when the side batting second is bowled out for a second time with scores level.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.5. Most Fours</div>
                        <div className="col-12 sportdetail">Description: Which team will hit the most fours?Rules: In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the overs scheduled to be bowled in either innings due to external factors, including bad weather, unless settlement of the bet has already been determined before the reduction.In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.Only fours scored from the bat (off any delivery – legal or not) will count towards the total fours. Overthrows, all run fours and extras do not count.Fours scored in a super over do not count.In First Class games, only first innings fours will count.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.6. Most Sixes</div>
                        <div className="col-12 sportdetail">Description: Which team will hit the most sixes?Rules: In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the overs scheduled to be bowled in either innings due to external factors, including bad weather, unless settlement of the bet has already been determined before the reduction.In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.Only sixes scored from the bat (off any delivery – legal or not) will count towards the total sixes. Overthrows and extras do not count.Sixes scored in a super over do not count.In First Class games, only first innings sixes will count.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.7. Most Extras</div>
                        <div className="col-12 sportdetail">Description: Which team will have the most extras added to their batting score?Rules: In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the overs scheduled to be bowled in either innings due to external factors, including bad weather, unless settlement of the bet has already been determined before the reduction.In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.All wide deliveries, no balls, byes, leg byes and penalty runs in the match count towards the final result. If there are runs off the bat as well as extras from the same delivery, the runs off the bat do not count towards the final total.Extras in a super over do not count.In First Class games, only first innings extras will count.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.8. Most Run Outs Conceded</div>
                        <div className="col-12 sportdetail">Description: Which team will concede the most run outs in the match?Rules: A run out “conceded” means that a member of that team will be run out while batting.In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the scheduled overs in either innings due to external factors, including bad weather, unless settlement has already been determined before the reduction.In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.Run Outs in a super over do not count.In First Class games, only first innings run outs will count.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.9. Highest First Over</div>
                        <div className="col-12 sportdetail">Description: Which team will score the most runs in the first over of their innings?Rules: The first over must be completed for bets to stand unless settlement has already been determined. If during the first over the innings is ended due to external factors, including bad weather, all bets will be void, unless settlement has already been determined before the reduction.In First Class matches the market refers only to each team’s first innings.Extras and penalty runs in the particular over count towards settlement.                            </div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.10. Most Runs in Groups of Overs</div>
                        <div className="col-12 sportdetail">Description: Which team will score the most runs after the first specified number of overs of their innings?Rules: If the specified number of overs are not complete the bet will be void, unless the team is all out, declares, reaches their target or settlement of the bet has already been determined.In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the specified overs have been bowled at the time the bet was placed due to external factors, including bad weather, unless settlement of the bet has already been determined before the reduction.In First Class matches the market refers only to each team’s first innings.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.11. Highest First Partnership</div>
                        <div className="col-12 sportdetail">Description: Which team will score the most runs before losing their first wicket?Rules: If the batting team reaches the end of their allotted overs, reaches their target or declares before the first wicket falls, the result will be the total amassed. For settlement purposes, a batsman retiring hurt does not count as a wicket.In limited overs matches, bets will be void if the innings has been reduced due to external factors, including bad weather, unless settlement has already been determined before the reduction.In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.In First Class matches the market refers only to each team’s first innings.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.12. Match Markets Match Fours</div>
                        <div className="col-12 sportdetail">Description: How many fours will be in hit in the match?Rules: In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the overs scheduled to be bowled due to external factors, including bad weather, unless settlement of the bet has already been determined before the reduction.In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.Only fours scored from the bat (off any delivery – legal or not) will count towards the total fours. Overthrows, all run fours and extras do not count.Fours scored in a super over do not count.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.13. Match Sixes</div>
                        <div className="col-12 sportdetail">Description: How many sixes will be hit in the match?Rules: In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the overs scheduled to be bowled due to external factors, including bad weather, unless settlement of the bet has already been determined before the reduction.In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.Only sixes scored from the bat (off any delivery – legal or not) will count towards the total fours. Overthrows and extras do not count.Sixes scored in a super over do not count.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.14. Match Extras</div>
                        <div className="col-12 sportdetail">Description: How many extras will be scored in the match?Rules: In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the overs scheduled to be bowled due to external factors, including bad weather, unless settlement of the bet has already been determined before the reduction.In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.All wide deliveries, no balls, byes, leg byes and penalty runs in the match count towards the final result. If there are runs off the bat as well as extras from the same delivery, the runs off the bat do not count towards the final total.Extras in a super over do not count.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.15. Match Run Outs</div>
                        <div className="col-12 sportdetail">Description: How many run outs will there be in the match?Rules: In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the scheduled overs in either innings due to external factors, including bad weather, unless settlement of the bet has already been determined before the reduction.In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.Run outs in a super over do not count.Maximum Over in Match Description: How many runs will be scored in the highest scoring over of the match?Rules: In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the overs scheduled to be bowled due to external factors, including bad weather, unless settlement of the bet has already been determined before the reduction.In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.All runs, including extras, count towards settlement.Super overs do not count.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.16. Match Top Batsman</div>
                        <div className="col-12 sportdetail">Description: Which batsman will score the most runs in the match?Rules: The result of this market is determined on the batsman with the highest individual score in the match.In limited overs matches, bets will be void if it has not been possible to complete at least 50% of the overs scheduled to be bowled in either innings at the time the bet was placed due to external factors, including bad weather.Top batsmen bets for First Class matches apply only to the first innings of each team, and will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined. If a player was named at the toss, but later is removed as a concussion sub, that player will still be counted, as will the replacement player.If a batsman does not bat, but was named in the starting XI, bets on that batsman will stand.If a substitute (concussion, or otherwise) not named in the original XI scores the highest individual score in the team’s innings, bets on the market will be void.When two or more players score the same number of runs, dead-heat rules will apply. Runs scored in a super over do not count.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.17. Match Top Bowler</div>
                        <div className="col-12 sportdetail">Description: Which bowler will take the most wickets in the match?Rules: The result of this market is determined on the bowler with the most wickets in the match.In limited overs matches, bets will be void if it has not been possible to complete at least 50% of the overs scheduled to be bowled in either innings at the time the bet was placed due to external factors, including bad weather.Top bowler bets for First Class matches apply only to the first innings of each team, and will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined. If a player was named at the toss, but later is removed as a concussion sub, that player will still be counted, as will the replacement player.If a bowler does not bowl, but was named in the starting XI, bets on that bowler will stand.If a substitute (concussion, or otherwise) not named in the original XI takes the most wickets, bets on the market will be void.If two or more bowlers have taken the same number of wickets, the bowler who has conceded the fewest runs will be the winner. If there are two or more bowlers with the same wickets taken and runs conceded, dead heat rules will apply. Wickets taken in a super over don’t count.If no bowlers take a wicket in an innings then all bets will be void.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.18. Man of the Match</div>
                        <div className="col-12 sportdetail">Description: Who will be named man of the match?Rules: Bets will be settled on the officially declared man of the match. Dead-heat rules apply.If no man of the match is officially declared then all bets will be void.</div>
                        <div className="col-12 sportdetail">6.18.19. Delivery Markets Runs off Delivery</div>
                        <div className="col-12 sportdetail">Description: How many runs will be scored off the specified delivery?Rules: The result will be determined by the number of runs added to the team total, off the specified delivery.For settlement purposes, all illegal balls count as deliveries. For example, if an over starts with a wide, then the first delivery will be settled as 1 and, although there has not been a legal ball bowled, the next ball will be deemed as delivery 2 for that over.If a delivery leads to free hit or a free hit is to be re-bowled because of an illegal delivery, the runs scored off the additional delivery do not count.All runs, whether off the bat or not, are included. For example, a wide with three extra runs taken equates to 4 runs in total off that delivery.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.20. Exact Runs off Delivery</div>
                        <div className="col-12 sportdetail">Description: Exactly how many runs will be scored off the specified delivery?Rules: As “Runs off Delivery”.</div>
                        <div className="col-12 sportdetail">6.18.21. Over Markets Runs in Over</div>
                        <div className="col-12 sportdetail">Description: How many runs will be scored in the specified over?Rules: The specified over must be completed for bets to stand unless settlement has already been determined. If an innings ends during an over then that over will be deemed to be complete unless the innings is ended due to external factors, including bad weather, in which case all bets will be void, unless settlement has already been determined.If the over does not commence for any reason, all bets will be void.Extras and penalty runs in the particular over count towards settlement. If penalty runs cause the innings or match end before a ball is bowled in the over, bets on thatover will be void.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.22. Boundary in Over</div>
                        <div className="col-12 sportdetail">Description: Will there be a boundary scored in the specified over?Rules: As “Runs in Over”. Only boundaries scored from the bat (off any delivery – legal or not) will count as a boundary. Overthrows, all run fours and extras do not count as boundaries.</div>
                        <div className="col-12 sportdetail">6.18.23. Wicket in Over Description: Will a wicket fall in the specified over?Rules: As “Runs in Over”.For settlement purposes, any wicket will count, including run outs. A batsman retiring hurt does not count as a wicket. If a batsman is timed out or retired out then the wicket is deemed to have taken place on the previous ball. Retired hurt does not count as a dismissal.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.24. Over Odd/Even</div>
                        <div className="col-12 sportdetail">Description: Will the number of runs scored in the specified over be odd or even?Rules: As “Runs in Over”. Zero will be deemed to be an even number. If penalty runs cause the innings or match end before a ball is bowled in the over, bets on thatover will be void.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.25. Group Markets Runs in Groups of Overs</div>
                        <div className="col-12 sportdetail">Description: How many runs will be scored in the specified number of overs?Rules: If the specified number of overs are not complete the bet will be void, unless the team is all out, declares, reaches their target or settlement of the bet has already been determined.In limited overs matches, bets will be void if the total innings is reduced at any stage to less than 80% of the stated maximum overs at the time the bet was placed, unless settlement of the bet was already determined before the reduction.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.26. Wickets in Groups of Overs</div>
                        <div className="col-12 sportdetail">Description: How many wickets will fall in the specified number of overs? Rules: If the specified number of overs are not complete the bet will be void, unless the team is all out, declares, reaches their target or settlement of the bet has already been determined.In limited overs matches, bets will be void if the total innings is reduced at any stage to less than 80% of the stated maximum overs at the time the bet was placed, unless settlement of the bet was already determined.For settlement purposes, if a batsman is timed out or retired out then the wicket is deemed to have taken place on the previous ball. Retired hurt does not count as a dismissal.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.27. Runs in Session</div>
                        <div className="col-12 sportdetail">Description: How many runs will be scored in the specified session?Rules: The result is determined by the total number of runs scored in the specified session, regardless of which team has scored them.If fewer than 20 overs are bowled in a session, bets will be void unless settlement has already been determined.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.28. Innings Markets Innings Runs</div>
                        <div className="col-12 sportdetail">Description: How many runs will a team score in a specified innings?Rules: In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the overs scheduled to have been bowled at the time the bet was placed due to external factors, including bad weather, unless settlement of the bet has already been determined before the reduction. Bets placed on a future innings will remain valid regardless of the runs scored in any current or previous innings.In drawn First Class matches, will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined. Bets will also be void in drawn first class matches, if less than 60 overs have been bowled in an incomplete innings, unless settlement of the bet has already been determined. If a team declares, that innings will be considered complete for the purposes of settlement.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.29. Innings Wickets</div>
                        <div className="col-12 sportdetail">Description: How many wickets will the batting team lose in the current innings?Rules: In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the overs scheduled to have been bowled at the time the bet was placed due to external factors, including bad weather, unless settlement of the bet has already been determined before the reduction.In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.Retired hurt does not count as a dismissal.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.30. Innings Fours</div>
                        <div className="col-12 sportdetail">Description: How many fours will the batting team hit in their current innings?Rules: Same as Most Fours.</div>
                        <div className="col-12 sportdetail">6.18.31. Innings Sixes</div>
                        <div className="col-12 sportdetail">Description: How many sixes will the batting team hit in their current innings?Rules: Same as Most Sixes.</div>
                        <div className="col-12 sportdetail">6.18.32. Innings Extras</div>
                        <div className="col-12 sportdetail">Description: How many extras will be added to the named team’s batting innings?Rules: Same as Most Extras.</div>
                        <div className="col-12 sportdetail">6.18.33. Innings Run Outs</div>
                        <div className="col-12 sportdetail">Description: How many run outs will be conceded in the innings?Rules: Same as Most Extras.</div>
                        <div className="col-12 sportdetail">6.18.34. Maximum Over in Innings</div>
                        <div className="col-12 sportdetail">Description: How many runs will be scored off the highest scoring over of the current innings?Rules: Same as Maximum Over in Match</div>
                        <div className="col-12 sportdetail">6.18.35. Innings Runs, Odd or Even?</div>
                        <div className="col-12 sportdetail">Description: Will the total innings runs be odd or even?Rules: If the innings is abandoned, forfeited or there is no official result, all bets will be void.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.36. Innings to finish with a Boundary</div>
                        <div className="col-12 sportdetail">Description: Will the last ball of the innings be a boundary?Rules: Only boundaries scored from the bat (off any delivery – legal or not) will count as a boundary. Overthrows, all run fours and extras do not count as boundaries.In limited overs matches, bets will be void if there is any reduction in the number of overs scheduled to have been bowled at the time the bet was placed due to external factors, including bad weather. If the match is abandoned or there is no official result, all bets will be void.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.37. Exact Runs in Innings</div>
                        <div className="col-12 sportdetail">Description: How many runs exactly will the team batting in the final innings score?Rules: Bets will be settled according to the official result.In limited overs matches, bets will be void if there is any reduction in the number of overs scheduled to have been bowled at the time the bet was placed due to external factors, including bad weather.If the match is abandoned or there is no official result, all bets will be void.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.38. Top Batsman in Innings</div>
                        <div className="col-12 sportdetail">Description: Which batsman will score the most runs for the named team?Rules: The result of this market is determined on the batsman with the highest individual score in a team’s innings.In limited overs matches, bets will be void if it has not been possible to complete at least 50% of the overs scheduled to have been bowled at the time the bet was placed due to external factors, including bad weather.Top batsmen bets for First Class matches apply only to the first innings of each team, and will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined. If a player was named at the toss, but later is removed as a concussion sub, that player will still be counted, as will the replacement player.If a batsman does not bat, but was named in the starting XI, bets on that batsman will stand.If a substitute (concussion, or otherwise) not named in the original XI scores the highest individual score in the team’s innings, bets on the market will be void.When two or more players score the same number of runs, in the innings dead-heat rules will apply.Runs scored in a super over do not count.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.39. Top Bowler in Innings</div>
                        <div className="col-12 sportdetail">Description: Which bowler will take the most wickets for the named team?Rules: The result of this market is determined on the bowler with the highest individual number of wickets in an individual innings.In limited overs matches, bets will be void if it has not been possible to complete at least 50% of the overs scheduled to have been bowled at the time the bet was placed due to external factors, including bad weather.Top bowler bets for First Class matches apply only to the first innings of each team, and will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.If a player was named at the toss, but later is removed as a concussion sub, that player will still be counted, as will the replacement player.If a bowler does not bowl, but was named in the starting XI, bets on that bowler will stand.If a substitute (concussion, or otherwise) not named in the original XI takes the most wickets, bets on the market will be void.If two or more bowlers have taken the same number of wickets, the bowler who has conceded the fewest runs will be the winner. If there are two or more bowlers with the same wickets taken and runs conceded, dead heat rules will apply. Wickets taken in a super over don’t count.If no bowlers take a wicket in an innings then all bets will be void.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.40. Last Man Standing</div>
                        <div className="col-12 sportdetail">Description: Which batsman will be not out upon completion of the innings?Rules: If there are two or more batsmen who are not out upon completion of the innings, the winner for the purpose of settlement will be the last batsman to face a delivery (legal or not).Players will not be deemed to have been not out if they were no longer at the crease having retired hurt or did not bat. If more than 11 players bat, the market will be void.In limited overs matches, bets will be void if, subsequent to placing the bet, the innings has been reduced in any way due to external factors, including bad weather.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.41. Batsmen Markets Batsman Runs</div>
                        <div className="col-12 sportdetail">Description: How many runs will the named batsman score?Rules: If the batsman finishes the innings not out, as a result of a declaration, the team reaching the end of their allotted overs, or the team reaching their target; his score will be the final result. If a batsman does not bat, the bet will be void. If a batsman is not in the starting XI, bets will be void.If a batsman retires hurt, but returns later, the total runs scored by that batsman in the innings will count. If the batsman does not return later, the final result will be as it stood when the batsman retired.In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the scheduled overs in either innings due to external factors, including bad weather, unless settlement has been determined, or goes on to be determined. Result will be considered determined if the line at which the bet was placed is passed, or the batsman is dismissed.In drawn First Class matches, bets will be void if fewer than 200 overs are bowled, unless settlement of the bet has already been determined.Runs scored in a super over do not count.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.42. Combined Batsman Runs</div>
                        <div className="col-12 sportdetail">Description: How many total runs will the named batsmen score?Rules: As “Batsman Runs”, and if any of the named batsmen do not bat, the bet will be void, unless settlement of the bet has already been determined or goes on to be determined.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.43. Batsman Fours</div>
                        <div className="col-12 sportdetail">Description: How many fours will the named batsman hit?Rules: If a batsman finishes the innings not out, as a result of a declaration, the team reaching the end of their allotted overs, or the team reaching their target; his number of fours will be the final result. If a batsman does not bat, the bet will be void. If a batsman is not in the starting XI, bets will be void.If a batsman retires hurt, but returns later, the total fours hit by that batsman in the innings will count. If the batsman does not return later, the final result will be as it stood when the batsman retired.In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the scheduled overs in either innings due to external factors, including bad weather, unless settlement has been determined, or goes on to be determined. Result will be considered determined if the line at which the bet was placed is passed, or the batsman is dismissed.In drawn First Class matches, bets will be void if fewer than 200 overs are bowled, unless settlement of the bet has already been determined.Only fours scored from the bat (off any delivery – legal or not) will count towards the total fours. Overthrows, all run fours and extras do not count.Fours scored in a super over do not count.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.44. Batsman Sixes</div>
                        <div className="col-12 sportdetail">Description: How many sixes will the named batsman hit?Rules: If a batsman finishes the innings not out, as a result of a declaration, the team reaching the end of their allotted overs, or the team reaching their target; his number of sixes will be the final result. If a batsman does not bat, the bet will be void. If a batsman is not in the starting XI, bets will be void.If a batsman retires hurt, but returns later, the total sixes hit by that batsman in the innings will count. If the batsman does not return later, the final result will be as it stood when the batsman retired.In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the scheduled overs in either innings due to external factors, including bad weather, unless settlement has been determined, or goes on to be determined. Result will be considered determined if the line at which the bet was placed is passed, or the batsman is dismissed.In drawn First Class matches, bets will be void if fewer than 200 overs are bowled, unless settlement of the bet has already been determined. Only sixes scored from the bat (off any delivery – legal or not) will count towards the total fours. Overthrows and extras do not count.Sixes scored in a super over do not count.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.45. Batsman Milestones</div>
                        <div className="col-12 sportdetail">Description: Will the named batsman reach the specified milestone?Rules: As “Batsman Runs”. Method of Dismissal Description: How will the named batsman be out?Rules: If the specified batsman is not out, all bets will be void. If the specified batsman retires, and does not return to bat later, all bets will be void. If that batsman does return to bat later and is out, bets will stand.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.46. Partnership Markets Fall of Next Wicket</div>
                        <div className="col-12 sportdetail">Description: How many runs will the batting team have scored when the next wicket falls?Rules: If the batting team reaches the end of their allotted overs, reaches their target or declares before the specified wicket falls, the result will be the total amassed.For settlement purposes, a batsman retiring hurt does not count as a wicket.In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the scheduled overs in either innings due to external factors, unless settlement has already been determined, or goes on to be determined. Result will be considered determined if the line at which the bet was placed is passed, or the wicket in question falls.In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.47. Next Man Out</div>
                        <div className="col-12 sportdetail">Description: Which batsman will be the next to be dismissed?Rules: If either batsman retires hurt or the batsmen at the crease are different from those quoted, the bets placed on both batsmen will be declared void. If no more wickets fall, all bets will be void.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.48, Batsman Match Bet</div>
                        <div className="col-12 sportdetail">Description: Which batsman in the current partnership will score the most runs in this innings?Rules: Bets will settle based on the official scores for the specified batsmen in the innings, as detailed in the “Batsman Runs” section above.In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the scheduled overs in either innings due to external factors, including bad weather, after the bet is placed unless settlement has already been determined.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.49. Method of Next Wicket Dismissal</div>
                        <div className="col-12 sportdetail">Description: How will the next batsman be out?Rules: The result will be determined by the dismissal method of the next wicket that falls. A batsman retiring hurt does not count as a wicket. If a batsman is retired out, all bets will be void.If the specified wicket does not fall, all bets will be void.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.50. Player Markets Batsman Matchbet</div>
                        <div className="col-12 sportdetail">Description: Which of the named players will score the most runs?Rules: In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the scheduled overs in either innings due to external factors, including bad weather, unless settlement has been determined.In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.Both players must be named in the starting XI, or appear as a substitute. If either does not then subsequently bat all bets are still settled.Runs scored in a super over do not count.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.51. Bowler Matchbet</div>
                        <div className="col-12 sportdetail">Description: Which of the named players will take the most wickets?Rules: In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the scheduled overs in either innings due to external factors, including bad weather, unless settlement has been determined.In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.Both players must be named in the starting XI, or appear as a substitute. If either does not then subsequently bowl all bets are still settled.Wickets taken in a super over do not count.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.53. Keeper Matchbet</div>
                        <div className="col-12 sportdetail">Description: Which of the named wicket keepers score more points in the player performance scoring system?</div>
                        <div className="col-12 sportdetail">Rules: Points are scored as above. In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the scheduled overs in either innings due to external factors, including bad weather, unless settlement has been determined.In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.Both named players must start the match as a wicketkeeper, or appear as a substitute, but if their playing role changes for any reason all bets will still be settled in accordance with the scoring system above.Points scored in a super over do not count.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.54. Pop Up Markets Free Hit</div>
                        <div className="col-12 sportdetail">Description: How many team runs will be scored off the free hit delivery?Rules: The result will be determined by the number of runs added to the team total, off the specified delivery. If the free hit is re-bowled because of an illegal delivery, the runs scored off the second free hit do not count.Extras and penalty runs will count towards settlement.For example, if a wide is bowled on the free hit delivery specified, the result will be 1. Then another free hit market may be offered.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.55. Race to ‘X’ Runs</div>
                        <div className="col-12 sportdetail">Description: Which batsman will reach the specified number of runs first?Rules: All bets stand, regardless of any curtailment.If neither batsman reaches the specified number of runs the markets will be settled as ‘Neither’.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.56. Next to Hit Six</div>
                        <div className="col-12 sportdetail">Description: Which batsman will hit the next six?Rules: All bets stand, regardless of any curtailment. If neither batsman scores a six after the bet is offered, then the market will be settled as ‘Neither’.Overthrows and extras do not count.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.57. Next to Take a Wicket</div>
                        <div className="col-12 sportdetail">Description: Which bowler will take the next wicket in this innings?Rules: All bets stand, regardless of any curtailment. If none of the named bowlers take a wicket the market will be settled as ‘None of the above’.For settlement purposes, a batsman retiring hurt does not count as a wicket. Run outs, timed out, retired out and any other method of dismissal not awarded to a particular bowler will be settled as ‘None of the above’.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.58. Winning Over</div>
                        <div className="col-12 sportdetail">Description: In which over of the named team’s innings will the match be completed?Rules: All bets will be void if there is no official result.In limited overs matches, all bets will be void if, subsequent to placing the bet, the maximum overs possible are reduced in any way.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.59. One-sided Markets Both Teams to Score ‘X’ Runs</div>
                        <div className="col-12 sportdetail">Description: Will both teams score the specified number of runs?Rules: In line with Innings Runs rules, in limited overs matches, each innings will be judged to be complete if at least 80% of the overs scheduled to have been bowled at the time the bet was placed have been bowled, and this market will be settled accordingly.In drawn First Class matches, bets will be void if fewer than 100 overs have been bowled in either teams first innings, unless settlement of the bet has already been determined. Only runs scored in the first innings count If a team declares that innings will be considered complete for the purposes of settlement.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.60. Either Batsman Method of Dismissal</div>
                        <div className="col-12 sportdetail">Description: Will either of the named batsman be dismissed in the specified method?Rules: All bets will settle, regardless of whether either batsman remains not out, or retired hurt, at the end of the innings.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.61. Both Batsmen Method of Dismissal</div>
                        <div className="col-12 sportdetail">Description: Will both of the named batsman be dismissed in the specified method?Rules: As “Either Batsman Method of Dismissal”.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.62. Runs off Consecutive Deliveries</div>
                        <div className="col-12 sportdetail">Description: How many runs will be scored off each of the specified deliveries?Rules: As “Runs off Delivery” except the specified number of runs must be scored off both named deliveries.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.63. Wicket off Delivery</div>
                        <div className="col-12 sportdetail">Description: Will a wicket fall in the specified delivery?Rules: The specified delivery must be completed for bets to stand.For settlement purposes, any wicket will count, including run outs. A batsman retiring hurt does not count as a wicket. If a batsman is timed out or retired out then the wicket is deemed to have taken place on the previous ball.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.64. Both Batsmen to Score ‘X’ Runs in Over</div>
                        <div className="col-12 sportdetail">Description: Will both batsmen score the specified number of runs in the over?Rules: The specified over must be completed for bets to stand unless settlement has already been determined. If an innings ends during an over then that over will be deemed to be complete unless the innings is ended due to external factors, including bad weather, in which case all bets will be void, unless settlement has already been determined.If the over does not commence for any reason, all bets will be void.Runs must be scored off the bat to count towards settlement.Bets will settle regardless of whether or not either of the specified batsmen are dismissed or retired hurt before the over commences.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.65. Both Batsmen to Score a Boundary in Over</div>
                        <div className="col-12 sportdetail">Description: Will both batsmen score a boundary in the over?Rules: As “Both Batsmen to Score ‘X’ Runs in Over”. Both fours and sixes count as boundaries. Only fours or sixes scored from the bat (off any delivery – legal or not) will count. Overthrows, all run fours and extras do not count.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.66. Both a Four and a Six to be Scored in an Over</div>
                        <div className="col-12 sportdetail">Description: Will both a four and a six be scored in the over?Rules: The specified over must be completed for bets to stand unless settlement has already been determined. If an innings ends during an over then that over will be deemed to be complete unless the innings is ended due to external factors, including bad weather, in which case all bets will be void, unless settlement has already been determined. If the over does not commence for any reason, all bets will be void.Only fours or sixes scored from the bat (off any delivery – legal or not) will count. Overthrows, all run fours and extras do not count.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.67. Batsman and Bowler Combo Milestones</div>
                        <div className="col-12 sportdetail">Description: Will the named batsman, and the named bowler, reach their specified milestones?Rules: For batsman – same as “Batsman Runs”. In first class games, only runs scored in the first innings will count. If a batsman is not in the starting XI, or substituted in, bets will be void.For bowler – if a bowler does not bowl, they will be deemed to have taken 0 wickets. If a bowler is not in the starting XI, or substituted in, bets will be void. In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the scheduled overs in the relevant innings due to external factors, including bad weather, unless settlement has been determined.In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless the player’s bowling innings is complete. The result will be considered determined if the lines at which the bet was placed are passed. In First Class games, only first innings wickets will count and runs. Wickets and runs scored in a super over do not count.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.68. Batsmen Combo Milestones</div>
                        <div className="col-12 sportdetail">Description: Will both the batsmen reach their specified milestones?Rules: Same as “Combined Batsman Runs”.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.69. Notes for all Markets</div>
                        <div className="col-12 sportdetail">Players sent off/retired out A player being sent off is viewed as retired out, so will be settled as a wicket.Concussion substitutions When a player leaves the field as a concussion substitute, this will not count as a wicket. If the player does not return later, the final result will be as it stood when the player left the field. When a player enters the match as a concussion substitute, for settlement purposes both they and the player replaced will be looked upon as to have played a full part in the match.Penalty runs after the conclusion of an innings Penalty runs added to a team’s total after the start of the other team’s innings will not count towards settlement of markets in the previous innings.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.70. Player PerformancesDescription: choose whether a player will score more or less than a certain score - based on Fantasy-style points.</div>
                        <div className="col-12 sportdetail">Rules: Players score 1 point per run, 20 points per wicket, 10 points per catch, and 25 points per stumping.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.18.71. The Hundred</div>
                        <div className="col-12 sportdetail">Description: Cricket format with 100 balls per innings.</div>
                        <div className="col-12 sportdetail">Rules: For the Hundred, an over will consist of 5 legal deliveries, so a full Innings will be made up of 20 Overs. All other rules remain the same as other limited over formats.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.19. Squash</div>
                        <div className="col-12 sportdetail">6.19.1. If a set ends before the Xth point is reached, this market (Who scores [Xth] point in set [y]) is considered void (cancelled)</div>
                        <div className="col-12 sportdetail">6.19.2. If markets remain open with an incorrect score which has a significant impact on the odds, we reserve the right to void betting.</div>
                        <div className="col-12 sportdetail">6.19.3. If the players/teams are displayed incorrectly, we reserve the right to void betting.</div>
                        <div className="col-12 sportdetail">6.19.4. If a player retires, forfeits the match or is disqualified all undecided markets are considered void.</div>
                        <div className="col-12 sportdetail">6.19.5. Official points deductions will be taken into account for all undetermined markets. Markets which have already been determined will not take deductions into account.</div>
                        <div className="col-12 sportdetail">6.19.6. If penalty point(s) are awarded by the umpire, all bets on that game will stand.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.20. Aussie rules</div>
                        <div className="col-12 sportdetail">6.20.1. All markets exclude overtime unless otherwise stated0</div>
                        <div className="col-12 sportdetail">6.20.2. Regular 80 Minutes: Markets are based on the result at the end of a scheduled 80 minutes play unless otherwise stated. This includes any added injury or stoppage time but does not include extra-time.</div>
                        <div className="col-12 sportdetail">6.20.3. If odds were offered with an incorrect match time (more than 2 minutes), we reserve the right to void the betting.</div>
                        <div className="col-12 sportdetail">6.20.4. If the team names or category are displayed incorrectly, we reserve the right to void betting.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.21. Counter-Strike: Global Offensive</div>
                        <div className="col-12 sportdetail">6.21.1. If no bomb is planted, market ([mapNr!] Map [roundNr!] Round - Bomb defused) will be considered void</div>
                        <div className="col-12 sportdetail">6.21.2. Markets do not consider overtime unless otherwise stated.</div>
                        <div className="col-12 sportdetail">6.21.3. Markets will be settled based on officially published results.</div>
                        <div className="col-12 sportdetail">6.21.4. If the fixture is listed incorrectly, we reserve the right to void betting.</div>
                        <div className="col-12 sportdetail">6.21.5. In case of a walkover or retirement, all undecided markets are void.</div>
                        <div className="col-12 sportdetail">6.21.6. If a match or map is replayed due to a disconnection, or technical issues which are not player-related, all undecided markets will be void. The replayed match or map will be handled separately. </div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.21.7. If the standard number of maps is changed or differs from those offered for betting purposes, we reserve the right to void betting.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.22. Dota 2</div>
                        <div className="col-12 sportdetail">6.22.1. Xth map – 1st aegis: Settlement is determined by the team which picks up the Aegis of the Immortal, and not who slays Roshan.</div>
                        <div className="col-12 sportdetail">6.22.2. Xth map – 1st tower and Xth map – 1st barracks: For settlement purposes every method of tower destruction will be taken into account (Opponent & Creep destroy; destroy by Deny)</div>
                        <div className="col-12 sportdetail">6.22.3. Markets will be settled based on officially published results.</div>
                        <div className="col-12 sportdetail">6.22.4. If the fixture is listed incorrectly, we reserve the right to void betting.</div>
                        <div className="col-12 sportdetail">6.22.5. In case of a walkover or retirement, all undecided markets are void.</div>
                        <div className="col-12 sportdetail">6.22.6. If a match or map is replayed due to a disconnection, or technical issues which are not player-related, all undecided markets will be void. The replayed match or map will be handled separately.</div>
                        <div className="col-12 sportdetail">6.22.7. If the standard number of maps is changed or differs from those offered for betting purposes, we reserve the right to void betting.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.23. League of Legends</div>
                        <div className="col-12 sportdetail">6.23.1. Xth map – 1st inhibitor and Xth map – 1st tower: For settlement purposes every method of destruction will be taken into account </div>
                        <div className="col-12 sportdetail">6.23.2. Markets do not consider overtime unless otherwise stated. </div>
                        <div className="col-12 sportdetail">6.23.3. Markets will be settled based on officially published results. </div>
                        <div className="col-12 sportdetail">6.23.4. If the fixture is listed incorrectly, we reserve the right to void betting. </div>
                        <div className="col-12 sportdetail">6.23.5. In case of a walkover or retirement, all undecided markets are void. </div>
                        <div className="col-12 sportdetail">6.23.6. If a match or map is replayed due to a disconnection, or technical issues which are not player-related, all undecided markets will be void. The replayed match or map will be handled separately. </div>
                        <div className="col-12 sportdetail">6.23.7. If the standard number of maps is changed or differs from those offered for betting purposes, we reserve the right to void betting.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.24. eSports FIFA</div>
                        <div className="col-12 sportdetail">6.24.1. eSports Battle match duration - 2x4 minutes.</div>
                        <div className="col-12 sportdetail">6.24.2. Liga Pro EFootball match duration - 2x6 minutes.</div>
                        <div className="col-12 sportdetail">6.24.3.  All Markets will be settled as set out in the General and Soccer Market Rules.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.25. eSports NBA2K</div>
                        <div className="col-12 sportdetail">6.25.1. Match duration – 4x5 minutes. This includes overtime.</div>
                        <div className="col-12 sportdetail">6.25.2. All Markets will be settled as set out in the General and Basketball Market Rules.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">6.26. Formula1</div>
                        <div className="col-12 sportdetail">6.26.1.All race bets are settled on the official FIA classification at the time of the podium presentation, with subsequent disqualifications/penalties disregarded.</div>
                        <div className="col-12 sportdetail">6.26.2. All drivers who complete 90% of the race laps are deemed as classified finishers in line with the official FIA classification. However all drivers are given a ranking, and for the purpose of head to head betting this ranking shall apply.</div>
                        <div className="col-12 sportsubheading">7. Virtual sports</div>
                        <div className="col-12 sportdetail">7.1. Virtual football</div>
                        <div className="col-12 sportdetail">7.1.1. The Virtual Football Modes provide 24/7/365 real money betting experience on virtual football. Competitions are generated continuously and bets can be placed at any time, even within a season.</div>
                        <div className="col-12 sportdetail">7.1.2. Game structureEach mode has a different tournament structure:</div>
                        <div className="col-12 sportdetail py-md-4 py-3">Virtual Football League Mode VFLM:</div>
                        <div className="col-12 sportdetail">
                            <ul>
                                <li className="sportdetail">16 Teams</li>
                                <li className="sportdetail">Home & away matches</li>
                                <li className="sportdetail">30 match days</li>
                                <li className="sportdetail">8 concurrent matches per match day</li>
                                <li className="sportdetail">240 matches per season Group Stage</li>
                            </ul>
                        </div>
                        <div className="col-12 sportdetail pb-md-4 pb-3">Virtual Football World Cup VFWC:</div>
                        <div className="col-12 sportdetail">
                            <ul className="m-0">
                                <li className="sportdetail">32 Teams (8 groups of 4 teams per group)</li>
                                <li className="sportdetail">12 match day chunks (3 match days of 4 chunks per match day)</li>
                                <li className="sportdetail">4 concurrent matches per match day chunk</li>
                                <li className="sportdetail">48 matches per group stage</li>
                                <li className="sportdetail">Knock-Out-Stage</li>
                                <li className="sportdetail">16 Teams</li>
                                <li className="sportdetail">5 round (R16[1..4]; R16[5...8]; R8; Semi Finals; Final & 3rd Place)</li>
                                <li className="sportdetail">4 concurrent matches (R16[1..4]; R16[5...8]; R8);</li>
                                <li className="sportdetail">2 concurrent matches (Semi Finals; Final & 3rd Place)</li>
                                <li className="sportdetail">16 matches per knock-out-stage.</li>
                            </ul>
                        </div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">7.2. Virtual basketball</div>
                        <div className="col-12 sportdetail">7.2.1. The VBL provides 24/7/365 real money betting experience on virtual basketball. The league consists of 16 teams and seasons run continuously. Each season comprises 30 match days (home and away matches). Bets can be placed at any time – even within a season.</div>
                        <div className="col-12 sportdetail">7.2.2. Season Details.</div>
                        <div className="col-12 sportdetail">For the online version one season lasts 106:30 minutes in total, separated into a ‘Pre-League’ period, a ‘Match day Loop’, and a ‘Post league’ period. The ‘Pre-League’ period runs prior to the start of a season and lasts 60 seconds. All match days are summarised as the ‘Match day Loop’ period with a total duration of 105:00 minutes. At the end of every season there is a 30 second ‘Post Season’ period.</div>
                        <div className="col-12 sportdetail">7.2.3. Betting on a VBL match is allowed up to 10 seconds before tip-off. Betting markets for future match days of the current season remain open. When a future match day from the ‘Match Day’ bar at the bottom is selected, the matches related to that day along with the odds will be displayed in the lower odds section.</div>
                        <div className="col-12 sportdetail">7.3. Virtual horses</div>
                        <div className="col-12 sportdetail">7.3.1. The VHK provides 24/7/365 real money betting experience on virtual horse races. Bets can be placed up to 10 seconds prior to the start of the next upcoming race as well as on all future races of the current race days at any time.</div>
                        <div className="col-12 sportdetail pb-md-4 pb-3">7.3.2. Races are generated continuously - a new one will be started as soon as the current one has finished. Betting is possible in the next 10
                            upcoming races.:</div>
                        <div className="col-12 sportdetail">
                            <ul>
                                <li className="sportdetail">2 minute total event cycle</li>
                                <li className="sportdetail">40 seconds betting phase,</li>
                                <li className="sportdetail">65 seconds event phase,</li>
                                <li className="sportdetail">15 seconds results phase</li>
                                <li className="sportdetail">2 grass and 1 dirt track with a 1000m race randomly scheduled</li>
                                <li className="sportdetail">8, 10, 12, 14 runners randomly assigned</li>
                            </ul>
                        </div>
                        <div className="col-12 sportdetail pb-md-4 pb-3">7.3.3. Markets</div>
                        <div className="col-12 sportdetail">
                            <ul>
                                <li className="sportdetail">Win - select the runner which will finish first</li>
                                <li className="sportdetail">Place - select the runner which will finish either 1st, and 2nd (6-7 Runners), select the runner which will finish either 1st, 2nd and 3rd (7+ runners)</li>
                                <li className="sportdetail">Forecast (Correct Order) - select the runners that will finish 1st and 2nd in the correct order (exacta)</li>
                                <li className="sportdetail">Forecast (Any Order) - select the runners that will finish 1st and 2nd in any order (quinella)</li>
                                <li className="sportdetail">Tricast (Correct Order) - select the runners that will finish 1st, 2nd and 3rd in the correct order (trifecta)</li>
                                <li className="sportdetail">Tricast (Any Order) - select the runners that will finish 1st, 2nd and 3rd in any order (trio)</li>
                            </ul>
                        </div>
                        <div className="col-12 sportdetail">7.4. Virtual dogs</div>
                        <div className="col-12 sportdetail">7.4.1. The VDK provides 24/7/365 real money betting experience on virtual dog races.  Bets can be placed up to 10 seconds prior to the start of the next upcoming race as well as on the ten future races at any time.</div>
                        <div className="col-12 sportdetail">7.4.2. Game information. Races are generated continuously - a new one will be started as soon as the current one has finished. </div>
                        <div className="col-12 sportdetail">
                            <ul>
                                <li className="sportdetail">2 minute total event cycle</li>
                                <li className="sportdetail">37 seconds or 67 seconds betting phase,</li>
                                <li className="sportdetail">38 seconds or 68 seconds event phase,</li>
                                <li className="sportdetail">15 seconds results phase</li>
                                <li className="sportdetail">night and day track with distance 360m and 720m randomly scheduled</li>
                                <li className="sportdetail">6 or 8 runners randomly assigned</li>
                            </ul>
                        </div>
                        <div className="col-12 sportdetail pb-md-4 pb-3">7.4.3. Markets</div>
                        <div className="col-12 sportdetail">
                            <ul className="m-0">
                                <li className="sportdetail">Win - select the runner which will finish first</li>
                                <li className="sportdetail">Place - select the runner which will finish either 1st, and 2nd (6-7 Runners), select the runner which will finish either 1st, 2nd and 3rd (7+ runners)</li>
                                <li className="sportdetail">Forecast (Correct Order) - select the runners that will finish 1st and 2nd in the correct order (exacta</li>
                                <li className="sportdetail">Forecast (Any Order) - select the runners that will finish 1st and 2nd in any order (quinella)</li>
                                <li className="sportdetail">Tricast (Correct Order) - select the runners that will finish 1st, 2nd and 3rd in the correct order (trifecta)</li>
                                <li className="sportdetail">Tricast (Any Order) - select the runners that will finish 1st, 2nd and 3rd in any order (trio)</li>
                            </ul>
                        </div>
                        <div className="col-12 sportsubheading">8. Bonuses</div>
                        <div className="col-12 sportdetail">8.1. Comboboost.</div>
                        <div className="col-12 sportdetail pt-sm-0 pt-2">
                            <div className="d-flex ">
                                <div>8.1.1. </div>
                                <table className="table table-bordered mx-auto sporttable text-center">
                                    <thead>
                                        <tr className="sportheader">
                                            <th scope="col" className="tabletitle">Selections</th>
                                            <th scope="col" className="tabletitle">Multiplier</th>
                                        </tr>
                                    </thead>
                                    <tbody className="sportbody">
                                        <tr>
                                            <td scope="row" className="tableData">2</td>
                                            <td className="tableData">1.01</td>
                                        </tr>
                                        <tr>
                                            <td scope="row" className="tableData">3</td>
                                            <td className="tableData">1.02</td>
                                        </tr>
                                        <tr>
                                            <td scope="row" className="tableData">4</td>
                                            <td className="tableData">1.04</td>
                                        </tr>
                                        <tr>
                                            <td scope="row" className="tableData">5</td>
                                            <td className="tableData">1.05</td>
                                        </tr>
                                        <tr>
                                            <td scope="row" className="tableData">6</td>
                                            <td className="tableData">1.07</td>
                                        </tr>
                                        <tr>
                                            <td scope="row" className="tableData">7</td>
                                            <td className="tableData">1.1</td>
                                        </tr>
                                        <tr>
                                            <td scope="row" className="tableData">8</td>
                                            <td className="tableData">1.15</td>
                                        </tr>
                                        <tr>
                                            <td scope="row" className="tableData">9</td>
                                            <td className="tableData">1.17</td>
                                        </tr>
                                        <tr>
                                            <td scope="row" className="tableData">10</td>
                                            <td className="tableData">1.2</td>
                                        </tr>
                                        <tr>
                                            <td scope="row" className="tableData">11</td>
                                            <td className="tableData">1.25</td>
                                        </tr>
                                        <tr>
                                            <td scope="row" className="tableData">12</td>
                                            <td className="tableData">1.3</td>
                                        </tr>
                                        <tr>
                                            <td scope="row" className="tableData">13</td>
                                            <td className="tableData">1.35</td>
                                        </tr>
                                        <tr>
                                            <td scope="row" className="tableData">14</td>
                                            <td className="tableData">1.4</td>
                                        </tr>
                                        <tr>
                                            <td scope="row" className="tableData">15</td>
                                            <td className="tableData">1.45</td>
                                        </tr>
                                        <tr>
                                            <td scope="row" className="tableData">16</td>
                                            <td className="tableData">1.5</td>
                                        </tr>
                                        <tr>
                                            <td scope="row" className="tableData">17</td>
                                            <td className="tableData">1.65</td>
                                        </tr>
                                        <tr>
                                            <td scope="row" className="tableData">18</td>
                                            <td className="tableData">1.75</td>
                                        </tr>
                                        <tr>
                                            <td scope="row" className="tableData">19</td>
                                            <td className="tableData">1.85</td>
                                        </tr>
                                        <tr>
                                            <td scope="row" className="tableData">20</td>
                                            <td className="tableData">2</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-12 sportdetail">8.1.2. The final bonus amount calculation is based on the final odds of the Combo once all outcomes are settled.</div>
                        <div className="col-12 sportdetail">8.1.3. Cashed Out bets are not eligible to have a Combo boost.</div>
                        <div className="col-12 sportdetail">8.1.4. Operator reserves the right to amend, cancel, reclaim or refuse any promotion at its own discretion.</div>
                        <div className="col-12 sportdetail">8.1.5. Combo boost is only available on selections with odds of 1.50 or above.</div>
                        <div className="col-12 sportdetail">8.2. Freebets, freemoney, bet no risk.</div>
                        <div className="col-12 sportdetail">8.2.1. Freebet - player get just a winning part of the bet. For example, freebet 5 on odd 3.5 player get on account 5*3.5 - 5 = 12.5</div>
                        <div className="col-12 sportdetail">8.2.2. Freemoney - player gets a stake and wins a part on the account. For example, freemoney 5 on the odd 3.5 the player get on account 5*3.5 = 17.50</div>
                        <div className="col-12 sportdetail">8.2.3. Bet no risk - usual bet, but if player loses he get back his money on account</div>
                        <div className="col-12 sportdetail">
                            <div className="row">
                                <div className="col-md-8 col-12 mx-auto">
                                <div className="table-responsive">
                                    <table className="table w-100 table-bordered sporttabletwo  sporttable text-center align-middle">
                                        <thead className="align-middle">
                                            <tr className="sportheader">
                                                <th scope="col" className="tableData">type of freebet</th>
                                                <th scope="col" className="tabletitle">Refunded or voided</th>
                                                <th scope="col" className="tabletitle">Half lost</th>
                                                <th scope="col" className="tabletitle">Half win</th>
                                            </tr>
                                        </thead>
                                        <tbody className="sportbody align-middle">
                                            <tr>
                                                <td scope="row" className="tableData">freebet</td>
                                                <td className="tabletitle">count as a lost bet</td>
                                                <td className="tabletitle">count as a lost bet</td>
                                                <td className="tabletitle">As a usual bet, but without the stake (2.5*3.5+2.5*1)-5=6.25</td>
                                            </tr>
                                            <tr>
                                                <td scope="row" className="tableData">free money</td>
                                                <td className="tabletitle">The player gets stake amount on his account</td>
                                                <td className="tabletitle">As an usual bet</td>
                                                <td className="tabletitle">As an usual bet</td>
                                            </tr>
                                            <tr>
                                                <td scope="row" className="tableData">Bet no risk</td>
                                                <td className="tabletitle">As an usual bet</td>
                                                <td className="tabletitle">The player gets stake amount on his account</td>
                                                <td className="tabletitle">As an usual bet</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 sportdetail">9. Betby Games Rules</div>
                        <div className="col-12 sportdetail">9.1. General Rules.</div>
                        <div className="col-12 sportdetail">9.1.1. Sportsbook platform reserves the right to cancel any bet made on obviously “bad” odds, switched odds or a bet made after an event has started or match was affected by obvious technical problems.</div>
                        <div className="col-12 sportdetail">9.1.2. All bets will be settled, when the outcome of the market is decided.</div>
                        <div className="col-12 sportdetail">9.2. FIFA</div>
                        <div className="col-12 sportdetail">9.2.1. Match duration – 2x6 minutes. This includes injury time but does not include extra time or penalty shootouts.</div>
                        <div className="col-12 sportdetail">9.2.2. All Markets will be settled as set out in the General Market Rules.</div>
                        <div className="col-12 sportdetail">9.3. NBA 2K</div>
                        <div className="col-12 sportdetail">9.3.1. Match duration – 4x6 minutes. This includes overtime.</div>
                        <div className="col-12 sportdetail">9.3.2. All Markets will be settled as set out in the General and Basketball Market Rules.</div>
                        <div className="col-12 sportdetail">9.4. eTennis</div>
                        <div className="col-12 sportdetail">9.4.1. The winner of the match is the first player to win 2 sets.</div>
                        <div className="col-12 sportdetail">9.4.2. Player must win 3 games to win a set. If the score is tied at 2-2, then a player can win 4-2, or if players are still tied at 3-3 then the set is decided by a tie-break.</div>
                        <div className="col-12 sportdetail">9.4.3. The winner of the tie-break is the first player to win 5 points with a minimum 2 points difference. If the score is tied at 5-5, the player can win 7-5, 8-6, 9-7, etc.</div>
                        <div className="col-12 sportdetail">9.5. Rocket League</div>
                        <div className="col-12 sportdetail">9.5.1.Match duration – 5 minutes. This doesn’t include overtime.</div>
                        <div className="col-12 sportdetail">9.5.2.All Markets will be settled as set out in the General Market Rules.</div>
                        <div className="col-12 sportdetail">9.6. eFighting</div>
                        <div className="col-12 sportdetail">9.6.1.The winner of the match is the character who wins the fight.</div>
                        <div className="col-12 sportdetail">9.6.2. Explanation of eFighting market terms.</div>
                        <div className="col-12 sportdetail">Health Bar - Each character has 2 Health Bars. The second bar is active only after the first is completely spent.
                            First damage – first successful attack.</div>
                        <div className="col-12 sportdetail">Clash – Situation in the fight, when both characters challenge each other on special occasions. to increase hit points. Both fighters can win the clash, but there can also be a draw.</div>
                        <div className="col-12 sportdetail">Supermove – Special move for each character, that occurs very rarely.</div>
                        <div className="col-12 sportdetail">9.6.3. All markets will be settled according to the definitions above.</div>
                        <div className="col-12 sportdetail">9.7. eCricket</div>
                        <div className="col-12 sportdetail">9.7.1. Match consists of two innings - one for each team.</div>
                        <div className="col-12 sportdetail">9.7.2. Each inning consists of five overs with 6 deliveries of each. </div>
                        <div className="col-12 sportdetail">9.7.3. All Markets will be settled as set out in the Cricket Market Rules.</div>
                    </div>
                </div>

            </div>
        </div>
        <AppFooter/>
    </main>
</>
)
}

export default SportsPolicy