import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { number } from "yup";
import { WALLET_MODAL_TABS } from "../../../constants";
import { useWalletModal } from "../../../hooks/useWallet";
import { useTranslation } from "react-i18next";
import walletIcon from '../../../assets/img/totalBalance.png'
import usdt from '../../../assets/img/usdtIcon.png';
import searchh from '../../../assets/img/search.png';
import Form from 'react-bootstrap/Form';
import NewWithdraw from "../../../components/newWithdraw/NewWithdraw";
import { getAPIAuth } from "../../../service/apiInstance";
// import { getFavCryptoAction, getFavFiatAction } from "../../../store/action";
import { getFavCryptoAction, getFavFiatAction, selectCrypto, selectCurrency, selectFiat } from "../../../store/action";
import { convertToEightDigitsAfterDecimal } from "../../../utils/convertToEightDigitsAfterDecimal";

function formatNumber(num) {
  const numString = num.toString();
  const decimalIndex = numString.indexOf('.');

  if (decimalIndex !== -1 && numString.length - decimalIndex - 1 < 8) {
    // If decimal point exists and there are less than 8 digits after the decimal
    return num.toFixed(numString.length - decimalIndex - 1);
  } else {
    // If no decimal point or already 8 or more digits after decimal
    return numString;
  }
}

function Wallet() {
  const dispatch = useDispatch();
  const [showWithdraw, setShowWithdraw] = useState(false);
  const favCryptoDetails = useSelector(state => state.favCrypto);
  const favFiatDetails = useSelector(state => state.favFiat);
  const userDetails = useSelector(state => state.userDetails);
  const [search, setSearch] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const userBalance = useSelector(state => state.userBalance);
  const { walletModalObject, setWalletModalObject } = useWalletModal()
  const [activeTab, setActiveTab] = useState('crypto')
  const [favCrypto, setFavCrypto] = useState([]);
  const [favFiat, setFavFiat] = useState([]);

  // console.log("favCrypto", { isChecked, favCrypto });

  const getFav = async () => {
    try {
      const res = await getAPIAuth(`crypto/get-fav-currency-crypto?userId=${userDetails?.id}&favcryptocurrency=${search}`);
      if (res?.data?.success) {
        const crypto = res?.data?.crypto
        const fiat = res?.data?.fiat
        const newArray = [...crypto, ...fiat]
        setFavCrypto(res?.data?.crypto ? res.data.crypto : [])
        setFavFiat(res?.data?.fiat ? res.data.fiat : [])
        dispatch(getFavFiatAction(res?.data?.fiat ? res.data.fiat : []))
        dispatch(getFavCryptoAction(res?.data?.crypto ? res.data.crypto : []))
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    if (userDetails.id) {
      getFav();
    }
  }, [userDetails.id, showWithdraw, userDetails])

  useEffect(() => {
    if (search) {
      const filterFiat = favFiatDetails.filter(item => item.code.toLowerCase().includes(search.toLowerCase()) || item.name.toLowerCase().includes(search.toLowerCase()))
      setFavFiat(filterFiat)
      const filterCrypto = favCryptoDetails.filter(item => item.code.toLowerCase().includes(search.toLowerCase()) || item.name.toLowerCase().includes(search.toLowerCase()))
      setFavCrypto(filterCrypto)
    } else {
      setFavFiat(favFiatDetails)
      setFavCrypto(favCryptoDetails)
    }
  }, [search])
  
  return (
    <>
      {
        // showWithdraw ? <NewWithdraw /> : <WalletUi setShowWithdraw={setShowWithdraw} favFiat={favFiat} favCrypto={favCrypto} setSearch={setSearch} search={search} isChecked={isChecked} setIsChecked={setIsChecked} userBalance={userBalance} />
        showWithdraw ? (<NewWithdraw
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />) : (<WalletUi setShowWithdraw={setShowWithdraw} favFiat={favFiat} favCrypto={favCrypto} setSearch={setSearch} search={search} isChecked={isChecked} setIsChecked={setIsChecked} userBalance={userBalance}
          setActiveTab={setActiveTab}
        />)
      }

      {/* old wallet ui */}

      {/* <div className="row updatedWallet">
        <div className="col-12 pb-1">
          <div className="cardsubHeading d-flex align-items-center justify-content-between">
            <div className=" d-flex align-items-center">
              <span className="me-3 d-flex align-items-center hjustify-content-center">
                <img
                  src="assets/img/sidebar/switch.png"
                  alt="switch"
                  className="h-100 w-100 img-fluid"
                />
              </span>
              {accountPageLabels.SWITCH_WALLET}
            </div>
            <div className="totalWallet">
              {accountPageLabels.Total} : <span> ${(Number(userDetails?.totalbonus) + Number(userDetails?.totalbalance)).toFixed(2)}</span>
            </div>
          </div>
        </div>
        <div className="col-12 pt-md-3 pt-sm-2 pt-1">
          <div className="mainCard">
            <div className="mainHeading padLef">{accountPageLabels.MAIN}</div>
            <div className="mainHeading mainHeadingVal pt-3 d-flex align-items-center gap-2">
              <div className="CoinIcon d-flex align-items-center justify-content-center overflow-hidden rounded-circle">
                <img
                  src="assets/img/sidebar/coin.png"
                  alt="coin"
                  className="h-100 w-100 img-fluid"
                />
              </div>
              ${userDetails?.totalbalance ? Number(userDetails?.totalbalance).toFixed(2) : '0.00'}
            </div>
          </div>
        </div>
        <div className="col-sm-4 col-auto mx-auto pt-md-5 pt-4">
          <button
            onClick={() => setWalletModalObject((prev) => ({
              isWalletOpen: true,
              selectedTab: WALLET_MODAL_TABS.DEPOSIT
            }))}
            className="customBtn customBtntwo d-flex align-items-center justify-content-center w-100 "
          >
            {accountPageLabels.DEPOSIT}
          </button>
        </div>
      </div> */}
    </>
  );
}

export default Wallet;


const WalletUi = ({ setShowWithdraw, favFiat, favCrypto, setSearch, search, setIsChecked, isChecked, userBalance, setActiveTab }) => {
  const dispatch = useDispatch();
  const userDetails = useSelector(state => state.userDetails)
  const { setWalletModalObject } = useWalletModal()
  const { t } = useTranslation()
  const accountPageLabels = t("accountPageLabels", { returnObjects: true })
  return (
    <>
      <div className="row updatedWallet">
        <div className="col-12 d-md-block d-none">
          <div className="d-flex align-items-center accountCardHeading">
            <div className="cardImg">
              <img
                src="assets/img/sidebar/wallet.png"
                loading='lazy'
                alt="WALLET"
                className="h-100 w-100 img-fluid "
              />
            </div>
            {accountPageLabels.WALLET}
          </div>
        </div>
        <div className="col-12 px-0 mt-md-3">
          <div className="row mx-0 justify-content-between">
            <div className="col-xl-7 col-xxl-6 col-12">
              <div className="balanceBox">
                <div className="row mx-sm-0">
                  <div className="col-sm-6 col-xl col-xxl-6 col-12">
                    <div className="row align-items-start align-items-sm-center justify-content-center border-end-0 position-relative flex-nowrap">
                      <div className="col-auto pe-0 px-sm-0">
                        {/* <img className="walleticon" src={walletIcon} /> */}
                        <img className="walleticon" src={userBalance?.setCurrencyData?.icon} />
                        </div>
                      <div className="col">
                        <div className="total">Total Balance</div>
                        <div className="dollors">
                          {/* {userBalance?.baseCurrency?.symbol} {userBalance?.balance?.toFixed(2)} */}
                          {userDetails.fiatStatus ? userDetails?.currency?.symbol : ''}
                          {userBalance?.balance || userBalance?.balance === 0 ? Number(userBalance?.balance)?.toFixed(2) : userDetails?.totalbalance ? Number(userDetails?.totalbalance).toFixed(2) : "0.00"}
                          </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-sm-4 col-xl col-xxl-4 col-12 borderLineCol py-3 py-sm-0">
                    <div className="row align-items-start align-items-sm-center justify-content-center border-end-0 position-relative">
                    <div className="col-auto pe-0 px-sm-0 d-sm-none"><img className="walleticon" src={walletIcon}/></div>
                      <div className="col col-sm-auto">
                        <div className="total">Real Money</div>
                        <div className="dollors">$0.01</div>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-sm-6 col-xl col-xxl-6 col-12 borderLineCol">
                    <div className="row align-items-start align-items-sm-center justify-content-center border-end-0 position-relative">
                      <div className="col-auto pe-0 px-sm-0 d-sm-none"><img className="walleticon" src={walletIcon} /></div>
                      <div className="col col-sm-auto">
                        <div className="total">Bonus Money</div>
                        <div className="dollors">$ {userBalance?.lockedBonus?.toFixed(2)}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-12 ps-xl-1">
              <div className="row align-items-center mt-2 mt-xl-0 mx-0 h-100 justify-content-between flex-xl-column">
                <div className="col-xl-12 col-sm-auto order-2 order-xl-1">
                  <div className="row">
                    <div className="col-xl-9 col-auto d-flex justify-content-xl-end justify-content-start px-0">
                      <div className="hidetext me-1">Hide 0 balance</div>
                    </div>
                    <div className="col-xxl-3 col-auto px-0 ps-xxl-0">
                      <div class="form-check form-switch ps-1 position-relative mb-0 walletInpXheck">
                        <input class="form-check-input fomInp ms-1 shadow-none d-none" type="checkbox" role="switch" id="flexSwitchCheckChecked" onChange={(e) => setIsChecked(e.target.checked)} />
                        <label class="form-check-label formLbl" for="flexSwitchCheckChecked"></label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-12 col-sm-6 col-lg-5 order-1 order-xl-2 px-0 mb-2 mb-sm-0">
                  <div className="searchWrapper position-relative">
                    <input
                      className="inpBox"
                      type="text"
                      placeholder="Search"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <div className="searchIcoon">
                      <img src={searchh} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 pt-3 pb-2">
          {search ? "" : (<div className="subHeading">Fiat</div>)}
        </div>
        <div className="col-12">
          <div className="table-responsive">
            <table className="w-100 tableBody">
              <tbody className="tBody">
                {
                  favFiat?.map((item) => (
                    // <tr key={item.id} className={`tablelist ${isChecked && (parseFloat(item.wallet?.totalBalance) === 0 && parseFloat(item.bonus) === 0) ? "d-none" : ""}`}>
                    <tr key={item.id} className={`tablelist  ${isChecked && (parseFloat(item?.totalBalance) === 0 && parseFloat(item.totalBonus) === 0) ? "d-none" : ""}`}>
                      <td className="tableWidthh"><div className="usdtWrapper ps-sm-2"><img src={item.icon} alt="currency icon" />
                        {item.code?.toUpperCase()}

                      </div></td>
                      <td>
                        <div>
                          <div className="bold text-end">
                            {/* {item.wallet?.totalBalance} */}
                            {userDetails.fiatStatus ? userDetails?.currency?.symbol : ""}{" "}
                            {/* {item.wallet?.totalBalance ? item.wallet?.totalBalance?.toFixed(2) : "0"} */}
                            {item?.totalBalance ? convertToEightDigitsAfterDecimal(item?.totalBalance) : "0.00"}

                          </div>
                          {userDetails.fiatStatus ? <>
                            <div className="thin text-end">
                              {" "} {item.wallet?.totalBalance ? convertToEightDigitsAfterDecimal(item?.wallet?.totalBalance) : "0.00"}
                            </div>
                          </> : ""}

                        </div>
                      </td>
                      <td className="tableWidth"> <div className="yellowTxt"
                        onClick={() => {
                          setWalletModalObject((prev) => ({
                            isWalletOpen: true,
                            selectedTab: WALLET_MODAL_TABS.FIAT
                          }))
                          dispatch(selectFiat(item))
                        }}

                      >
                        Deposit</div></td>
                      <td className="tableWidth"><div onClick={() => {
                        setShowWithdraw(true)
                        dispatch(selectFiat(item))
                        setActiveTab("fiat")

                      }} className="yellowTxt yellowTxt2">Withdraw</div></td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
        {/* <div className="subHeading">Crypto Currency</div> */}
        {search ? "" : (
          <div className="col-12 pt-3 pb-2">
            <div className="subHeading">Crypto Currency</div>
          </div>
        )}
        <div className="col-12">
          <div className="table-responsive">
            <table className="w-100 tableBody">
              <tbody className="tBody">
                {
                  favCrypto?.map((item) => (
                    <tr key={item.id} className={`tablelist ${isChecked && (parseFloat(item?.totalBalance) === 0 && parseFloat(item?.totalBonus) === 0) ? "d-none" : ""}`}>
                      <td className="tableWidthh"><div className="usdtWrapper ps-sm-2"><img src={item.icon} alt="currency icon" />{item.code?.toUpperCase()}</div></td>
                      <td>
                        <div>
                          <div className="bold text-end">
                            {/* {item.wallet?.totalBalance} */}
                            {userDetails.fiatStatus ? userDetails?.currency?.symbol : ""}{" "} {item?.totalBalance ? convertToEightDigitsAfterDecimal(item?.totalBalance) : "0.00"}
                          </div>
                          {userDetails.fiatStatus ? <>
                            <div className="thin text-end">
                              {" "} {item.wallet?.totalBalance ? convertToEightDigitsAfterDecimal(item.wallet?.totalBalance) : '0.00'}
                            </div>
                          </> : ""}
                          {/* <div className="thin text-end">{item.totalBonus}</div> */}
                        </div>
                      </td>
                      <td className="tableWidth"><div
                        className="yellowTxt"
                        onClick={() => {
                          setWalletModalObject((prev) => ({
                            isWalletOpen: true,
                            selectedTab: WALLET_MODAL_TABS.DEPOSIT
                          }))
                          dispatch(selectCrypto(item))
                        }
                        }
                      >Deposit</div></td>
                      <td className="tableWidth"><div onClick={() => {
                        setShowWithdraw(true)
                        dispatch(selectCrypto(item))
                        setActiveTab("crypto")
                      }} className="yellowTxt yellowTxt2">Withdraw</div></td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </div >
    </>
  )
}
