import React from 'react'
import { APP_NAME } from '../../../../../constants'

const WeeklyBonus = () => {
    return (
        <>
            <div className="row">
            <div className="col-12">
                <div className="heading mb-3">Make a minimum wager of $150 to unlock 
                    the bonus.The bonus calculation is based on 
                    recent activity from saturday to friday 
                    and lost coins.</div>
                {/* <div className="text mb-3">Unless you are in profit</div> */}
                <div className="divider"></div>
            </div>
            <div className="col-12">
                <div className="headingBold mt-3 mb-2">Аccrual frequency:</div>
                <div className="textBold mb-3">Every 7 days</div>
                <div className="divider"></div>
            </div>
            <div className="col-12">
                <div className="headingBold mt-3 mb-2">Аccrual in:</div>
                <div className="textBold mb-2">Every 7 days</div>
                <div className="noteTxt mb-3"><span>ℹ️</span> The bonus expires if you do not withdraw it before the next one is accrued.</div>
                <div className="divider"></div>
            </div>
            <div className="col-12">
                <div className="headingBold mt-3 mb-2">Withdrawal distribution:</div>
                <div className="textBold mb-3">25% of the amount goes to your balance <br/>75% of the amount goes to the calendar for<br/>5 days</div>
                <div className="noteTxtBorder mb-3">
                    <div className="vip">For VIP users:</div>
                    <div className="balance">30% to your balance</div>
                    <div className="balance">70% to the calendar for 5 days</div>
                </div>
                <div className="text mb-3">1- The minimum amount for distribution to the 
                    calendar is $0.003 per day. The smaller amount is 
                    credited to the balance in full.
                </div>
                <div className="divider"></div>
            </div>
            <div className="col-12 mt-3">
                <div className="headingBold mb-1">What is the bonus calculated for?</div>
                <div className="headingBold mb-3">- Casino bets <span className='text-white'>(Slots, {APP_NAME} Originals)</span></div>
                <div className="divider"></div>
            </div>
            </div>
        </>
    )
}

export default WeeklyBonus