import React from 'react'
import AppFooter from '../../components/AppFooter/AppFooter'
import { APP_INFO_MAIL, APP_NAME_URL } from '../../constants'

const ResponsibleGamble = () => {
    return (
        <>
        <main className="main vh-100" id="main">
                <div className="mainContaint">
                        <div className="policypage">
                            <div className="row policymain">
                                <div className="col-12">
                                    <div className="policamlHeading policyHeading">Gambling with responsibility</div>
                                    <div className="policyDate pt-3">Last updated: August, 01 2024.</div>
                                </div>
                                <div className="col-12 policyDetail">
                                Please read this information carefully for your own benefit. <br/>
                                        {APP_NAME_URL} is operated under curacao gaming law.

                                </div>
                                <div className="col-12">
                                    <div className="row policyInner">
                                        <div className="policysubHeading col-12">
                                        Interpretation
                                        </div>
                                        <div className="col-12 policyDetail">The words of which the initial letter is capitalized have meanings defined under the following conditions.</div>
                                        <div className="col-12 policyDetail">The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="row policyInner">
                                        <div className="col-12 policysubHeading">
                                        Definitions
                                        </div>
                                        <div className="col-12 ">
                                            <div className="policyDetail"> For the purposes of these Terms and Conditions:</div>
                                            <div className="">
                                                <ul className="d-flex flex-column m-0">
                                                    <li className="policyDetail">Account means a unique account created for You to access our Service or parts of our Service.</li>
                                                    <li className="policyDetail">Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Curacao Co </li>
                                                    <li className="policyDetail">Service refers to the Website.</li>
                                                    <li className="policyDetail">Website refers to  <a href="javascript:;" className="text-decoration-none policyDetail">{APP_NAME_URL}</a></li>
                                                </ul>
                                            </div>
                                            <div className="policyDetail">You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="row policyInner">
                                        <div className="col-12 policysubHeading">Responsible Gambling and Self Exclusion</div>
                                        <div className="col-12 policyDetail">Gambling means for the majority of our Users, entertainment, fun and excitement. But we also know that for some of our Users gambling has negative side effects. In the medical science is pathologic gambling since many years as serious sickness recognised.</div>
                                        <div className="col-12 policyDetail">Since our first day we think about this problematic and try out best to help. Under “Responsible Gambling” We understand multiple steps of measures, with which a gambling provider can help to lower the possibility of negative side effects appearing. -In case they already appear we also try to take active steps against them.
                                        </div>
                                        <div className="col-12 policyDetail">The most important instrument against negative side effects from gambling are knowledge and education about the risks of gambling too support our Users self-control in order to make sure they do not suffer from negative side effects.</div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="row policyInner">
                                        <div className="col-12 policysubHeading">Information and contact</div>
                                        <div className="col-12">
                                            <div className="policyDetail">Our Support will help you via email at all time without any additional costs for you: </div>
                                            <ul className="">
                                                <li className="policyDetail">email: {APP_INFO_MAIL}</li>
                                            </ul>
                                        </div>
                                        <div className="col-12">
                                            <div className="policyDetail">Our Support will of course not give out any information about You without Your consent to anyone else.</div>
                                            <div className="policyDetail">In addition you also can take a self-test, if You are already gambling addicted at:</div>
                                            <a href="https://www.begambleaware.org/gambling-problems/do-i-have-a-gambling-problem/" target='_blank' className="policyLink">https://www.begambleaware.org/gambling-problems/do-i-have-a-gambling-problem/</a>
                                            <div className="policyDetail">And you can also find additional information about gambling addictions at:</div>
                                            <a href="https://www.begambleaware.org/safer-gambling/" target='_blank' className="policyLink">https://www.begambleaware.org/safer-gambling/</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="row policyInner">
                                        <div className="col-12 policysubHeading">Helpful hints for responsible gambling at  <a href="javascript:;" className="policysubHeading text-decoration-none">{APP_NAME_URL}</a> </div>
                                        <div className="col-12 policyDetail">We recommend you think about the following hints, before gambling in order to insure gambling stays fun for You and without any negative side effects:</div>
                                        <div className="col-12">
                                            <ul className="d-flex flex-column">
                                                <li className="policyDetail ">Set yourself a deposit limit  </li>
                                         
                                                <li className="policyDetail pt-4">Before you start to gambling think about how much you can afford to gamble with according to Your financial situation. Play with amounts which are for fun and for Your entertainmentDo not try to win back a loss at every cost</li>
                                                
                                                <li className="policyDetail pt-4">Try to not take to huge risks to win back what You lost before at any cost. Play for Entertainment and not to earn money.Set yourself a time limit  </li>
                                       
                                                <li className="policyDetail pt-4">Set yourself a time limit and do not break it. Keep in mind gambling should stay in balance with your other hobbies and not be Your only hobby. Play smart:</li>
                                         
                                                <li className="policyDetail pt-4">It is smarter to not play, when You are extremely stressed, depressed or under to much pressure. Also do not play when you are under the influence of Medications, Drugs or Alcohol. Take breaks:  </li>
                                        
                                                <li className="policyDetail pt-4">You should take breaks when You notice, that You get tired or can´t concentrate anymoreOnly one account:</li>
                                                <div className="policyDetail">To make it easier to have an overview how much time and money You spend on gambling it is highly advised to not create more than one Account per Person.
                                                    </div>
                                                  </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="row policyInner">
                                        <div className="col-12 policysubHeading">Minor Protection</div>
                                        <div className="col-12 policyDetail">To use our Service, You have to be 18 years or older. To avoid abuse, keep Your login data save from any minors near You.</div>
                                        <div className="col-12 policyDetail">Principally we recommend a filter program to avoid minors, especially children, to access any context on the internet, which is not healthy for them.</div>
                                        <div className="col-12 ">
                                            <div className="policyDetail">For parents we can recommend a list of internet filters, to support them, from keeping their children from any context, which was not made for them: </div>
                                            <a href="https://famisafe.wondershare.com/internet-filter/best-internet-filters.html" target='_blank' className="policyLink">https://famisafe.wondershare.com/internet-filter/best-internet-filters.html</a>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="row policyInner">
                                        <div className="col-12 policysubHeading">Self-Exclusion:</div>
                                        <div className="col-12 policyDetail">In case You are diagnosed with a gambling addiction or try to stay away from gambling for a different reason, we want to assist you to stay away from anything, that does nothing good for you. “Self-Exclusion” means, that You exclude yourself, out of Your own choice, from all gambling services. This exclusion cannot be undone for a set amount of time. If you wish to self-exclude yourself from gambling, please message our support and give them a time span between 6 months and 5 years.  </div>
                                        <div className="col-12 policyDetail">They also will explain you all future steps and what is needed from you.</div>
                                        <div className="col-12 ">
                                            <ul className="m-0">
                                                <li className="policyDetail">email: {APP_NAME_URL}</li>
                                                <li className="policyDetail">Please keep in mind that Self Exclusion is permanent for the set time span and will not be undone for your own protection.    </li>
                                            </ul>
                                        </div>
                                        <div className="col-12 policyDetail ">During Self Exclusion you are not allowed to create a new Account and every attempt to create a new Account during Self Exclusion is a violation of our Terms of Service and may result in the permanent ban of your original account.  </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                <AppFooter/>
            </main>
        </>
    )
}

export default ResponsibleGamble