import React, { useEffect, useRef, useState } from "react";
import { calculateDays } from '../../../utils/calculateDays.js'
import { getAPI } from "../../../service/apiInstance.js";
import AllBetsTable from "./AllBetsTable.jsx";
import HighBetsTable from "./HighBetsTable.jsx";
import RareBetsTable from "./RareBetsTable.jsx";
import { useTranslation } from "react-i18next";
import { socket } from "../../../service/socket.js";
import SportsBetTable from "./SportsBetTable.jsx";

const DambleTable = ({ design }) => {
    const [endPoint, setEndPoint] = useState('get-bets')
    const [allBets, setAllBets] = useState([])
    const [highBets, setHighBets] = useState([])
    const [lowBets, setLowBets] = useState([])
    const { t } = useTranslation()
    const casinoLabels = t("casinoLabels", { returnObjects: true })
    const [loading, setLoading] = useState(true)
    const timerRef = useRef(null)


    const getAllBets = async()=> {
        // setLoading(true)
        try {
            if(endPoint === 'sports-bet') return;
            const res = await getAPI(`casino/${endPoint}`)
            if(endPoint === "get-bets") {
                setAllBets(res?.data?.data)
            }
            if(endPoint === "high-bet") {
                setHighBets(res?.data?.data)
            }
            if(endPoint === "low-bet") {
                setLowBets(res?.data?.data)
            }
        } catch (error) {
            
        } finally {
            // setLoading(false)
        }
    }


    useEffect(()=> {
        const interval = setInterval(() => {
            getAllBets()
        }, 10000);
        return ()=> {
            clearInterval(interval)
        }
    },[])

    useEffect(()=> {
        setLoading(true)
        getAllBets()
        setLoading(false)
    }, [endPoint])

    return (
        <div className="container-fluid dambleTable liveBets">
            <div className="row gap-5">
                <div className="col-12 px-0">
                    <div className="sectionInner">
                        <div className="tableCard row mx-0">
                            <div className="col-12 px-0">
                                <div className="row align-items-center justify-content-between mx-0">
                                    {design ?
                                        <>
                                            <div className="col d-flex justify-content-start px-0">
                                                <nav>
                                                    <ul className="nav nav-tabs border-0 d-sm-inline-flex d-flex align-items-center tableNavBtns">
                                                        <li className="nav-item">
                                                            <button
                                                                className={`nav-link border-0 shadow-none rounded-pill ${endPoint === 'get-bets' ? 'active' : ''}`}
                                                                onClick={() => setEndPoint('get-bets')}
                                                            >
                                                                {casinoLabels.All_bets}
                                                            </button>
                                                        </li>
                                                        <li className="nav-item">
                                                            <button
                                                                className={`nav-link border-0 shadow-none rounded-pill ${endPoint === 'high-bet' ? 'active' : ''}`}
                                                                onClick={() => setEndPoint('high-bet')}
                                                            >
                                                                {casinoLabels.High_rollers}
                                                            </button>
                                                        </li>
                                                        {/* <li className="nav-item">
                                                            <button
                                                                className={`nav-link border-0 shadow-none rounded-pill ${endPoint === 'low-bet' ? 'active' : ''}`}
                                                                onClick={() => setEndPoint('low-bet')}
                                                            >
                                                                {casinoLabels.Rare_wins}
                                                            </button>
                                                        </li> */}
                                                               <li className="nav-item">
                                                            <button
                                                                className={`nav-link border-0 shadow-none rounded-pill ${endPoint === 'sports-bet' ? 'active' : ''}`}
                                                                onClick={() => setEndPoint('sports-bet')}
                                                            >
                                                                {/* {casinoLabels.Rare_wins} */}
                                                                Sports Bet
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </> : <>
                                            <div className="col-auto px-0">
                                                <div className="livebets">
                                                    <img src="assets/img/index/livebets.png" alt="" />
                                                    {casinoLabels.LIVE_BETS}
                                                    
                                                </div>
                                            </div>
                                            <div className="col d-flex justify-content-end px-0">
                                                <nav>
                                                    <ul className="nav nav-tabs border-0 d-sm-inline-flex d-flex align-items-center">
                                                        <li className="nav-item">
                                                            <button
                                                                className={`nav-link border-0 shadow-none rounded-pill ${endPoint === 'get-bets' ? 'active' : ''}`}
                                                                onClick={() => setEndPoint('get-bets')}
                                                            >
                                                                {casinoLabels.All_bets}
                                                            </button>
                                                        </li>
                                                        <li className="nav-item">
                                                            <button
                                                                className={`nav-link border-0 shadow-none rounded-pill ${endPoint === 'high-bet' ? 'active' : ''}`}
                                                                onClick={() => setEndPoint('high-bet')}
                                                            >
                                                                {casinoLabels.High_rollers}
                                                            </button>
                                                        </li>
                                                        {/* <li className="nav-item">
                                                            <button
                                                                className={`nav-link border-0 shadow-none rounded-pill ${endPoint === 'low-bet' ? 'active' : ''}`}
                                                                onClick={() => setEndPoint('low-bet')}
                                                            >
                                                                {casinoLabels.Rare_wins}
                                                            </button>
                                                        </li> */}
                                                        <li className="nav-item">
                                                            <button
                                                                className={`nav-link border-0 shadow-none rounded-pill ${endPoint === 'sports-bet' ? 'active' : ''}`}
                                                                onClick={() => setEndPoint('sports-bet')}
                                                            >
                                                                {/* {casinoLabels.Rare_wins} */}
                                                                Sports Bet
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </>
                                    }
                                    <div className="col-12  px-0">
                                        {
                                            endPoint === 'get-bets' ? <AllBetsTable data={allBets} loading={loading} /> : <></>
                                        }
                                        {
                                            endPoint === "high-bet" ? <HighBetsTable data={highBets} loading={loading}/> : <></>
                                        }
                                        {
                                            endPoint === "low-bet" ? <RareBetsTable data={lowBets} loading={loading}/> : <></>
                                        }
                                        {
                                            endPoint === "sports-bet" ? <SportsBetTable/> : <></>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DambleTable;







