// use this file to add generic constants
export const TOKEN_NAME = "memestop-token"
export const BT_TOKEN_NAME = "memestop-bt-token"
export const DEFAULT_IMAGE = "assets/img/gd.png"
export const SELECTED_CURRENCY = "currency"
export const SPIN_TOKEN = 'memestop-spin-token'
export const SPIN_STRING = 'spin-string'
export const APP_NAME = 'MemeStop'
export const APP_NAME_URL = 'www.memestop.win'
export const APP_INFO_MAIL = 'support@memestop.win'
export const APP_LEGAL_MAIL = 'support@memestop.win'


// for development mode
// export const isDev = true ///when changing to dev uncomment this and vise-verse
// export const baseURL = 'http://192.168.1.51:3005/api'


// for production mode
export const baseURL = 'https://api.memestop.win/api'
export const isDev = false ///when changing to live uncomment this and vise-verse


// export const baseURL = 'http://3.6.185.241:3005/api'
export const USER_LANG = 'user-lang' // has to replace 
export const OPEN_CAGE_API_KEY = '8d5951e1a3ef47afa1ed5ed6a07e80d6'
export const MOBILE_SCREEN_WIDTH = 550
// export const OPEN_CAGE_API_KEY= '8d5951e1a3ef47afa1ed5ed6a07e80d6'
// export const MOBILE_SCREEN_WIDTH =  575 
export const SM_SCREEN_WIDTH = 767
export const MD_SCREEN_WIDTH = 991
export const LG_SCREEN_WIDTH = 1199
export const XL_SCREEN_WIDTH = 1399
// export const BT_BRAND_ID = "2335883550055862272"
export const BT_BRAND_ID = "2346726299164352512"
export const POKER_OPERATOR_ID = "18759437"   // for live
// export const POKER_OPERATOR_ID = "18761150"    // for development


export const CLICK_ID = "click_id"
export const NEW_CLICK_ID = "clickid"
export const BT_THEME = "memestop"

export const SESSION_TOKEN = 'isAllowed'


export const PRE_SALE = "https://pancakeswap.finance/swap?outputCurrency=0x2528A195D09EEA15e07BB9CbbcE62BedBb5eF721&inputCurrency=0x55d398326f99059fF775485246999027B3197955"


// Default currency 
export const DEFAULT_CURRENCY = {
    "_id": "64ba08ca7971fec6d7702f6e",
    "name": "United States Dollar",
    "id": "usd",
    "code": "USD",
    "symbol": "$",
    "icon": "https://cdn.onramper.com/icons/tokens/usd.svg",
    "createdAt": "2023-07-21T04:25:46.239Z",
    "updatedAt": "2023-07-21T04:25:46.239Z",
    "isFav": false,
    "type": "currency"
}

export const TABS_OPTIONS = {
    language: "language",
    currency: "View in fiat",
    
}


export const NEWS_CATE_LIMIT = 10

export const AUTH_MODAL_TABS = {
    SIGN_UP: "signupOptions",
    LOG_IN: 'signinOptions'
}

export const WALLET_MODAL_TABS = {
    DEPOSIT: "deposit",
    WITHDRAW: 'withdraw',
    BUY_CRYPTO: 'buyCrypto',
    BUY_RAMPER: 'buyRamper',
    RUBLE_PAY: 'rublePay',
    FIAT: 'fiat'
}

export const reCaptcha_Config = {
    siteKey: "6LdHlRIpAAAAAAiyUFMr5LE9J0mimHpUH22_D9ph",
    secretKey: "6LdHlRIpAAAAAHm0xoDEX-IL7ftxAiqdM7E2Myzo"
}

export const GAME_OPTION_TABS = {
    LOBBY: 'lobby',
    FAVOURITE: 'favourite',
    RECENT: 'recent',
    FREE_SPIN: 'free-spin'
}


export const KADO_WALLET_TABS = {
    BUY: 'buy',
    SELL: 'sell',
    SWAP: 'swap',
    SELECT_WALLET: 'select-wallet',
    SELECT_CURRENCY: 'select-currency',
    SELECT_NETWORK: 'select-network'
}

export const BONUS_DROPDOWN_OPTIONS = {
    BONUS_MAIN: 'bonus-main',
    BONUS_DETAIL: 'bonus-detail',
    FREE_SPIN_GAMES: 'free-spin-games',
    FREE_SPIN_BONUS_CLAIM: 'free-spin-bonus-claim'
}

export const SPIN_MODAL_OPTIONS = {
    SPIN_MODAL: 'spin-modal',
    WINNER_MODAL: 'winner-modal'
}

export const CLAIM_BONUS_OPTIONS = {
    LOCKED_BONUS: 'lockedBonus',
    CASHBACK_BONUS: 'cashback',
    DEPOSIT_BONUS: 'deposit',
    DAILY_BONUS: 'daily',
    RACKNACK_BONUS: 'rackback',
    WEEKLY_BONUS: 'weekly',
    MONTHLY_BONUS: 'monthly'
}