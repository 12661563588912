import React, { useState } from 'react'
import AppFooter from '../../components/AppFooter/AppFooter'
import Lobby from './components/Lobby'
import { useGames } from '../../hooks/useGame'
import { AUTH_MODAL_TABS, GAME_OPTION_TABS, MOBILE_SCREEN_WIDTH } from '../../constants'
import Favourite from './components/Favourite'
import { useAuth } from '../../hooks/useAuth'
import { useAuthModal } from '../../hooks/useAuthModal'
import { getAPI } from '../../service/apiInstance'
import { useEffect } from 'react'
import SelectedGame from './components/SelectedGame'
import Recent from './components/Recent'
import {useLocation, useSearchParams } from 'react-router-dom'
import FreeSpin from './components/FreeSpin'

const GamePage = () => {
  const {gameObject, setGameObject} = useGames()
  const { token } = useAuth();
  const { setAuthModalObject } = useAuthModal();
  const [categories, setCategories] = useState([])
  const [loading, setLoading] = useState(true)
  const [query, setQuery] = useSearchParams()
  const location = useLocation()

  const getCategory = async ()=> {
    setLoading(true)
    try {
        const res = await getAPI(`get-all-category`)
        if (res?.data?.success) {
          setCategories(res?.data?.data)
        }
    } catch (error) {
      
    }finally {
      setLoading(false)
    }
  }
  useEffect(()=> {
    getCategory()
  },[])

  useEffect(()=> {
    if(!location?.search || location?.search === "?action=") {
      console.log('location in')
      setGameObject((prev)=> ({
        selectedTab : GAME_OPTION_TABS?.LOBBY
      }))
      setQuery({ q: "lobby" })
    }
  }, [location])



  useEffect(() => {
      if (query.get("q") == 'lobby') {
        setGameObject((prev)=> ({
          selectedTab : GAME_OPTION_TABS?.LOBBY
        }))
      } else if (query.get("q") == 'favourite') {
        if(token) {
          setGameObject((prev)=> ({
            selectedTab : GAME_OPTION_TABS?.FAVOURITE
          }))
        } else {
          setAuthModalObject(pre => ({
            isAuthOpen: true,
            selectedTab: AUTH_MODAL_TABS.LOG_IN
          }))
        }
      }
      else if (query.get("q") == 'recent') {
        if(token) {
          setGameObject((prev)=> ({
            selectedTab : GAME_OPTION_TABS?.RECENT
          }))
        } else {
          setAuthModalObject(pre => ({
            isAuthOpen: true,
            selectedTab: AUTH_MODAL_TABS.LOG_IN
          }))
        }
      }
      else if (query.get("q") == 'freeSpin') {
        if(token) {
          setGameObject((prev)=> ({
            selectedTab : GAME_OPTION_TABS?.FREE_SPIN
          }))
        } else {
          setAuthModalObject(pre => ({
            isAuthOpen: true,
            selectedTab: AUTH_MODAL_TABS.LOG_IN
          }))
        }
      } else {
        const catId = query.get("categoryId") 
        const catName = query.get("q") 

        setGameObject((prev)=> ({
          selectedTab : catName,
          selectedId : catId
        }))
      }
  }, [query.get("q"), query.get("categoryId"), token])

  return (
    <>
      <main className="main vh-100" id="main">
        <div className="mainContaint">
          <div className="homepage">
          <div className="row optionPillsOuter gx-2 flex-nowrap overflow-x-auto mt-4" >
            <div className="col-auto optionPill">
              <div 
                onClick={()=>
                {setGameObject((prev)=> ({
                selectedTab : GAME_OPTION_TABS?.LOBBY
              }))
              setQuery({ q: "lobby" })
            }}
                className={`optionPillInner text-decoration-none rounded-pill d-flex align-items-center justify-content-center ${gameObject?.selectedTab === GAME_OPTION_TABS?.LOBBY ? 'active' : ''}`}>
                <img src='assets/img/options/lobby.png' alt="" />
                Lobby
              </div>
            </div>
            <div className="col-auto optionPill">
              <div 
                onClick={()=>{
                  if(token) {
                    setGameObject((prev)=> ({
                      selectedTab : GAME_OPTION_TABS?.FAVOURITE
                    }))
                    setQuery({ q: "favourite" })
                  } else {
                    setAuthModalObject(pre => ({
                      isAuthOpen: true,
                      selectedTab: AUTH_MODAL_TABS.LOG_IN
                    }))
                  }
                }}
                className={`optionPillInner text-decoration-none rounded-pill d-flex align-items-center justify-content-center ${gameObject?.selectedTab === GAME_OPTION_TABS?.FAVOURITE ? 'active' : ''}`}>
                <img src='assets/img/options/favourite.png' alt="" />
                Favourites
              </div>
            </div>
            <div className="col-auto optionPill">
              <div 
                onClick={()=>{
                  if(token) {
                    setGameObject((prev)=> ({
                      selectedTab : GAME_OPTION_TABS?.RECENT
                    }))
                    setQuery({ q: "recent" })
                  } else {
                    setAuthModalObject(pre => ({
                      isAuthOpen: true,
                      selectedTab: AUTH_MODAL_TABS.LOG_IN
                    }))
                  }
                }}
                className={`optionPillInner text-decoration-none rounded-pill d-flex align-items-center justify-content-center ${gameObject?.selectedTab === GAME_OPTION_TABS?.RECENT ? 'active' : ''}`}>
                <img loading='lazy' src='assets/img/options/recent.png' alt="" />
                Recent
              </div>
            </div>
            <div className="col-auto optionPill">
              <div 
                onClick={()=>{
                  if(token) {
                    setGameObject((prev)=> ({
                      selectedTab : GAME_OPTION_TABS?.FREE_SPIN
                    }))
                    setQuery({ q: "freeSpin" })
                  } else {
                    setAuthModalObject(pre => ({
                      isAuthOpen: true,
                      selectedTab: AUTH_MODAL_TABS.LOG_IN
                    }))
                  }
                }}
                className={`optionPillInner text-decoration-none rounded-pill d-flex align-items-center justify-content-center ${gameObject?.selectedTab === GAME_OPTION_TABS?.FREE_SPIN ? 'active' : ''}`}>
                <img src='assets/img/options/freeSpin.svg' alt="" />
                Free Spin
              </div>
            </div>
            {!loading ?
              categories?.length > 0 ?
                categories?.map((item)=> (
                  <div className="col-auto optionPill">
                    <div 
                      onClick={()=>{
                        setGameObject((prev)=> ({
                          selectedTab : item?.category_name?.split(" ").join(""),
                          selectedId : item?._id
                        }))
                        setQuery({ q: item?.category_name?.split(" ").join(""), categoryId: item?._id})
                      }}
                      className={`optionPillInner text-decoration-none rounded-pill d-flex align-items-center justify-content-center
                        ${gameObject?.selectedTab === item?.category_name?.split(" ").join("") ? 'active' : ''}
                        `}>
                      <img src={item?.image} alt="" />
                      {item?.category_name?.toLowerCase()}
                    </div>
                  </div>
                ))
              : ''
            : ''}
          </div>

            {
              gameObject?.selectedTab === GAME_OPTION_TABS?.LOBBY ? <Lobby/> : 
              gameObject?.selectedTab === GAME_OPTION_TABS?.FAVOURITE ? <Favourite/> : 
              gameObject?.selectedTab === GAME_OPTION_TABS?.RECENT ? <Recent/> : 
              gameObject?.selectedTab === GAME_OPTION_TABS?.FREE_SPIN ? <FreeSpin/> : 
              <SelectedGame selectedCategory={gameObject?.selectedId}/>
            }
          
          </div>
        </div>
        {/*  **** Footer section *** */}
        <AppFooter />
      </main>
    </>
  )
}

export default GamePage