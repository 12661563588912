import React, { useEffect, useState } from "react";
import { errorToaster, succesToaster } from "../../../utils/toaster";
import { getAPIAuth, postAPIAuth } from "../../../service/apiInstance";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTES_CONST } from "../../../constants/routeConstant";

function Affiliate() {
  const [refercode, setReferCode] = useState("");
  const [alldata, setAlldata] = useState([]);
  const [referal, setReferalcode] = useState([]);
  const [tabopen, setTabopen] = useState("");
  const [copied, setNCopied] = useState({ id: "", type: "", status: false });
  const userDetails = useSelector((state) => state.userDetails);
  const { t } = useTranslation()
  const accountPageLabels = t("accountPageLabels", { returnObjects: true })
  const navigate = useNavigate()


  const ReferalCodes = async () => {
    try {
      const res = await getAPIAuth("get-affiliate-details");
      setReferalcode(res.data?.data);
    } catch (error) { }
  };

  const handleAllData = async () => {
    try {
      
      const res = await getAPIAuth("get-affiliate-friend-details");
      setAlldata(res.data?.data);
    } catch (error) {
      
    }
  };

  useEffect(() => {
    ReferalCodes();
    handleAllData();
    setTabopen("reffaral");
  }, []);

  const GenerateCodeFun = async () => {
    const body = {
      refer_code: refercode,
    };
    if (refercode.length < 6 && refercode.length < 15) {
      errorToaster("charcters must be between 6 to 15 digits");
      return;
    }
    const res = await postAPIAuth("create-refer-code", body);
    if (res.status) {
      setReferCode("");
      succesToaster(res.data.message);
      ReferalCodes();
    }
  };

  const handleCopy = (val, isActive, index) => {
    navigator.clipboard
      .writeText(val)
      .then(() => {
        setNCopied((prev) => {
          const updatevalue = { ...prev };
          updatevalue["id"] = index;
          updatevalue["type"] = isActive;
          updatevalue["status"] = true;
          return updatevalue;
        });

        setTimeout(
          () =>
            setNCopied((prev) => {
              const updatevalue = { ...prev };
              updatevalue["id"] = index;
              updatevalue["type"] = isActive;
              updatevalue["status"] = false;
              return updatevalue;
            }),
          2000
        );
      })
      .catch((err) => console.error("Failed to copy reference code:", err));
  };

  return (
    <>
      <div className="row">
        <div className="col-12 pb-2 d-none d-md-block">
          <div className="d-flex align-items-center accountCardHeading">
            <div className="cardImg">
              <img
                src="assets/img/sidebar/affiliate.png"
                alt="affiliate"
                className="h-100 w-100 img-fluid "
                loading='lazy'
              />
            </div>
            {accountPageLabels.AFFILIATE}
          </div>
        </div>
        <nav className="col-12 pt-md-4">
          <div
            className="nav nav-tabs gap-2 reffralsTab border-0"
            id="nav-tab"
            role="tablist"
          >
            <button
              className={`nav-link refferalBtn ${tabopen == "reffaral" ? "active" : ""
                }`}
              onClick={() => setTabopen("reffaral")}
            >
              {accountPageLabels.REFERRALS}
            </button>
            <button
              className={`nav-link refferalBtn ${tabopen == "reffered" ? "active" : ""
                }`}
              onClick={() => setTabopen("reffered")}
            >
              {accountPageLabels.REFERRED_USERS}
            </button>
          </div>
        </nav>
        <div className="tab-content col-12 pt-5" id="nav-tabContent">
          <div
            className={`tab-pane fade ${tabopen == "reffaral" ? "show active" : ""
              }`}
            id="nav-reffaral"
            role="tabpanel"
            aria-labelledby="nav-reffaral-tab"
            tabIndex={0}
          >
            <div className="setcodeCard pb-1 justif-end">
              <div className="setCodetitle textcenter">
                {accountPageLabels.SET_CUSTOM}
              </div>
              <div className="accountInput pt-2">
                <div className="inputmain d-flex w-100 align-items-center ">
                  <input
                    type="text"
                    className="userNameInput form-control shadow-none border-0"
                    placeholder="Set referral code..."
                    value={refercode}
                    onChange={(e) => setReferCode(e.target.value)}
                  />
                  <span
                    className="customBtn d-flex align-items-center justify-content-center cursor-pointer"
                    onClick={() => GenerateCodeFun()}
                  >
                    {accountPageLabels.SET_CODE}
                  </span>
                </div>
              </div>
            </div>
            <div className="row pt-3 g-3">
              <div className="col-4">
                <div className="reffralsCard text-center h-100">
                  <div className="totalComission">
                    ${referal[0]?.total_commission_reward ? Number(referal[0]?.total_commission_reward).toFixed(2) : '0.00'}
                    {/* ${referal[0]?.total_commission_reward ? Math.floor(referal[0]?.total_commission_reward) : '0'} */}
                  </div>
                  <div className="Comission">{accountPageLabels.COMMISSION_EARNED}</div>
                </div>
              </div>
              <div className="col-4">
                <div className="reffralsCard text-center h-100">
                  <div className="totalComission">
                    ${referal[0]?.totalClaimed ? Number(referal[0]?.totalClaimed).toFixed(2) : '0.00'}
                    {/* ${referal[0]?.totalClaimed ? Math.floor(referal[0]?.totalClaimed) : '0'} */}

                  </div>
                  <div className="Comission">{accountPageLabels.TOTAL_CLAIMED}</div>
                </div>
              </div>
              <div className="col-4">
                <div className="reffralsCard text-center h-100">
                  <div className="totalComission">
                    ${userDetails?.totalbalance ? Number(userDetails?.totalbalance).toFixed(2) : '0.00'}
                    {/* ${userDetails?.totalbalance ? Math.floor(userDetails?.totalbalance) : '0'} */}

                  </div>
                  <div className="Comission">{accountPageLabels.TOTAL_AVAILABLE}</div>
                </div>
              </div>
            </div>
            <div className="pt-4 pt-md-5">
              <div className="table-responsive">
                <table className="table affiliateTable mb-2 align-middle">
                  <thead>
                    <tr className="tableHead">
                      <th scope="col" className="border-0 rounded-start-2 px-3">
                        {accountPageLabels.CODE}
                      </th>
                      <th scope="col" className="border-0 px-3">
                        {accountPageLabels.CLAIMED}
                      </th>
                      <th scope="col" className="border-0 px-3">
                        {accountPageLabels.REFS}
                      </th>
                      <th scope="col" className="border-0 px-3">
                        %
                      </th>
                      <th scope="col" className="border-0 px-3">
                        {accountPageLabels.WAGERED}
                      </th>
                      <th scope="col" className="border-0 px-3">
                        {accountPageLabels.CLAIM}
                      </th>
                      <th scope="col" className="border-0 rounded-end-2 px-3">
                        {accountPageLabels.LINK}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="tablebody">
                    {
                      Array.isArray(referal) &&
                      referal?.map((item, i) => (
                        <tr key={`${Math.random()}--${Math.random()}`}>
                          <td
                            scope="row"
                            className="border-0 px-3 rounded-start-2 text-white"
                          >
                            {item?.refer_code ? item?.refer_code : ""}
                          </td>
                          <td className="border-0 px-3  text-white">
                            ${item?.claimed ? Number(item?.claimed).toFixed(2) : '0.00'}
                            {/* ${item?.claimed ? Math.floor(item?.claimed) : '0'} */}
                          </td>
                          <td className="border-0 px-3  text-white">
                            {item?.refs ? item.refs : '0'}
                          </td>
                          <td className="border-0 px-3  text-white">
                            10%
                          </td>
                          <td className="border-0 px-3  text-white">
                            ${item?.wagred ? Number(item.wagred).toFixed(2) : "0.00"}
                            {/* ${item?.wagred ? Math.floor(item.wagred) : "0"} */}
                          </td>
                          <td className="border-0 px-3  text-white">
                            <span
                              className="text-decoration-none text-nowrap claimBtn cursor-pointer"
                            >
                              {accountPageLabels.CLAIM} ${item?.claim ? Number(item?.claim).toFixed(2) : "0.00"}
                              {/* {accountPageLabels.CLAIM} ${item?.claim ? Math.floor(item?.claim) : "0"} */}
                            </span>
                          </td>
                          <td className="border-0 px-3 rounded-end-2">
                            <button
                              className="copyBtn text-nowrap border-0"
                              onClick={() =>
                                handleCopy(item?.refer_link, "Link", i)
                              }
                            >
                              {copied.id == i &&
                                copied.type == "Link" &&
                                copied.status == true ? (
                                <span style={{ color: "green" }}>
                                  {accountPageLabels.copied}!
                                </span>
                              ) : (
                                <span> 
                                  {accountPageLabels.copy} 
                                </span>
                              )
                              }
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div
            className={`tab-pane fade ${tabopen == "reffered" ? "show active" : ""
              }`}
            id="nav-reffered"
            role="tabpanel"
            aria-labelledby="nav-reffered-tab"
            tabIndex={0}
          >
            <div className="pt-2">
              <div className="table-responsive">
                <table className="table affiliateTable mb-2 align-middle">
                  <thead>
                    <tr className="tableHead text-nowrap">
                      <th scope="col" className="border-0 rounded-start-2 px-4">
                        {accountPageLabels.USERNAME}
                      </th>
                      <th scope="col" className="border-0 p4">
                        {accountPageLabels.JOINED}
                      </th>
                      <th scope="col" className="border-0 px4 txt-center">
                        {accountPageLabels.WAGERED}
                      </th>
                      <th
                        scope="col"
                        className="border-0 px4 rounded-end-2"
                      >
                        {accountPageLabels.COMMISSION_EARNED}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="tablebody">
                    {Array.isArray(alldata) &&
                      alldata?.map((item) => (
                        <tr key={`${Math.random()}-${Math.random()}`}>
                          <td
                            scope="row"
                            className="border-0  rounded-start-2 ps-4 text-white"
                          >
                            {item?.fullname}
                          </td>
                          <td className="border-0 text-center  text-white">
                            {item?.createdAt?.slice(0, 10)}
                          </td>
                          <td className="border-0 text-center  text-white">
                            {item?.total_wager ? Number(item.total_wager).toFixed(2) : '0.00'}
                            {/* {item?.total_wager ? Math.floor(item.total_wager) : '0'} */}
                          </td>
                          <td className="border-0 text-center  text-white">
                            ${item?.total_commission_rewards ? Number(item.total_commission_rewards).toFixed(2) : '0.00'}
                            {/* ${item?.total_commission_rewards ? Math.floor(item.total_commission_rewards) : '0'} */}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-4 pt-md-5 col-12 affiliateCardTitle">
          {accountPageLabels.If_you_are}{" "}
          <span
            className="affliateProgram text-decoration-none cursor-pointer"
            onClick={()=> navigate(ROUTES_CONST.AFFILIATE_PAGE)}
          >
            {accountPageLabels.Affiliate_Program}
          </span>
        </div>
      </div>
    </>
  );
}

export default Affiliate;
