import { Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from "yup";
import ErrorMessage from '../../../common/ErrorMessage/ErrorMessage';
import { errorToaster, succesToaster } from '../../../../utils/toaster';
import { useAuth } from '../../../../hooks/useAuth';
import { useSelector } from 'react-redux';
import { useAuthModal } from '../../../../hooks/useAuthModal';
import OtpSignUp from './OtpSignUp';
import { getAPI, postAPI } from '../../../../service/apiInstance';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { auth } from '../../../../config/firebase';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getCustomAuthError } from '../../../../utils/customFirebaseError';


const validationSchema = Yup.object({
  number: Yup.string()
    .required("Phone Number is required")
    .matches(
      /^[0-9]{10}$/,
      "Invalid mobile number"
    ),
});


const SearchIcon = () => {
  return (
    <div className='searchImg'>
      <svg stroke="currentColor" fill="none" stroke-width="0" viewBox="0 0 15 15" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10 6.5C10 8.433 8.433 10 6.5 10C4.567 10 3 8.433 3 6.5C3 4.567 4.567 3 6.5 3C8.433 3 10 4.567 10 6.5ZM9.30884 10.0159C8.53901 10.6318 7.56251 11 6.5 11C4.01472 11 2 8.98528 2 6.5C2 4.01472 4.01472 2 6.5 2C8.98528 2 11 4.01472 11 6.5C11 7.56251 10.6318 8.53901 10.0159 9.30884L12.8536 12.1464C13.0488 12.3417 13.0488 12.6583 12.8536 12.8536C12.6583 13.0488 12.3417 13.0488 12.1464 12.8536L9.30884 10.0159Z" fill="currentColor"></path></svg>
    </div>
  )
}

const SignUpNumber = ({
  browserName,
  setChecks,
  checks,
  click_id,
  new_click_id,
  bonus,
  // setChecks
}) => {
  const [showCountry, setShowCountry] = useState(false);
  // const [isLoading, sFetIsLoading] = useState(false);
  const [isOtp, setIsOtp] = useState(false)
  const [isMetamask, setIsMetamask] = useState(false);
  const { authModalObject } = useAuthModal();
  const [isTelegram, setIsTelegram] = useState(false);
  const [userObj, setUserObj] = useState({});
  const [matchedItem, setmatchedItem] = useState([]);
  const [countrycodedata, setCountryCode] = useState([]);
  const countryName = useSelector((state) => state.country);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false)
  const { token } = useAuth()
  const [select, setSelect] = useState('')
  const [number, setNumber] = useState('')
  const ipAddress = useSelector(state => state.ipAddress)
  const [referral, setReferral] = useState(localStorage.getItem("middlePart"))
  const { t } = useTranslation()
  const authLabels = t("AuthLabels", { returnObjects: true }) //string has to be same as language json key 



  const onSubmit = async (values, resetForm) => {
    if (!checks.terms) {
      errorToaster("User agreement is required");
      setIsLoading(false);
      return;
    }
    setNumber(values.number);
    setReferral(values.referral);
    if (!!values.referral) {
      const res = await postAPI("user/check-email", {
        email: "",
        refer_code: values.referral,
      });
      if (res?.data?.status) {
        handleSend(values, resetForm);

        // setIsLoading(false);
      } else {
        errorToaster(res?.data?.message)
        setIsLoading(false);
      }
      return
    }
    handleSend(values, resetForm);

  };

  const generateRecaptcha = (random) => {
    const recaptchaParent = document.getElementById("recaptcha-parent");
    if (recaptchaParent) {
      recaptchaParent.innerHTML = `<span id=${random} />`
    }
    window.recaptchaVerifier = new RecaptchaVerifier(
      `${random}`,
      {
        size: "invisible",
        callback: (response) => { },
      },
      auth
    );
    window.recaptchaVerifier.render().then((widgetId) => {
      window.recaptchaWidgetId = widgetId;
    });
  };

  const handleSend = (values, resetForm) => {
    const random = Math.random() + 1000000
    generateRecaptcha(random);
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(
      auth,
      `${select?.phoneCode ? select.phoneCode : "+91"} ${values.number}`,
      appVerifier
    ) //dynamic code
      .then((confirmationResult) => {
        succesToaster("OTP SENT!");
        // setOtpId(confirmationResult.verificationId);
        window.confirmationResult = confirmationResult;
        // setActive(false);
        // setOtpId(true);
        // setIsNumber(false)
        window?.recaptchaVerifier?.recaptcha?.reset(window.recaptchaWidgetId)
        setIsOtp(true)
      })
      .catch((error) => {
        // errorToaster(error.message);
        errorToaster(getCustomAuthError(error))
        const recaptchaContainer = document.getElementById(`${random}`)
        if (recaptchaContainer) { recaptchaContainer.remove(); }
        window?.recaptchaVerifier?.recaptcha?.reset(window.recaptchaWidgetId)
      })
      .finally(() => {
        setIsLoading(false);
        resetForm()
        setChecks(pre => ({ ...pre, terms: false }))
      });
  };
  const CountryCodeFun = async () => {
    const res = await getAPI("get-countries");
    setCountryCode(res.data.data);
  };
  useEffect(() => {
    const codedata =
      countrycodedata.filter((item) => item.country == countryName).length == 0
        ? countrycodedata
        : countrycodedata.filter((item) => item.country === countryName);
    setmatchedItem(countrycodedata);
    setSelect(countryName && codedata[0]);
    localStorage.setItem("country_Id", codedata[0]?._id);
  }, [countryName, countrycodedata]);

  useEffect(() => {
    if (!token) {
      CountryCodeFun();
    }
  }, []);

  // useEffect(() => {
  //   if (!isNumber) {
  //     setIsLoading(false);
  //   }
  // }, [isNumber]);

  const handlesearch = (val) => {
    if (val.length > 0) {
      const filterdata = countrycodedata?.filter((item) =>
        item.country.toLowerCase().includes(val.toLowerCase()) || item.phoneCode.toLowerCase().includes(val.toLowerCase())
      );
      setmatchedItem(filterdata);
    } else {
      setmatchedItem(countrycodedata)
    }
  };
  return (
    <>
      {
        !isOtp ? (
          <Formik
            initialValues={{
              number: "",
              referral: referral
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              setIsLoading(true);
              onSubmit(values, resetForm);
            }}
          >
            {
              ({
                values,
                errors,
                touched,
                handleSubmit,
                handleChange,
                handleBlur,
              }) => (
                <form>
                  <div className="col-12">
                      <div>
                        <label className='inputLabelNew'>Phone Number</label>
                      </div>
                    <div className="mobileInputWrapper position-relative">
                      <input
                        className='inp'
                        type="text"
                        // placeholder='Phone number'
                        placeholder={authLabels.Phone_Number}
                        name="number"
                        id="number"
                        value={values.number}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <div onClick={() => setShowCountry(!showCountry)} className="mobileNoTxt">
                        {select?.phoneCode ? select?.phoneCode : "+91"}
                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                          <path d="M5.02282 5.73994C4.92844 5.74049 4.83488 5.7224 4.74751 5.68671C4.66014 5.65102 4.58067 5.59844 4.51366 5.53198L0.210901 1.22922C0.0758633 1.09418 0 0.911032 0 0.72006C0 0.529089 0.0758633 0.345938 0.210901 0.210901C0.345938 0.0758633 0.529089 0 0.72006 0C0.911032 0 1.09418 0.0758633 1.22922 0.210901L5.02282 4.01167L8.81641 0.218072C8.9536 0.100588 9.13007 0.0391974 9.31055 0.0461687C9.49104 0.05314 9.66224 0.12796 9.78996 0.255676C9.91768 0.383392 9.99249 0.554598 9.99946 0.735082C10.0064 0.915565 9.94505 1.09203 9.82756 1.22922L5.52481 5.53198C5.39123 5.66446 5.21095 5.73915 5.02282 5.73994Z" fill="#ffffff" />
                        </svg>
                      </div>
                      {
                        showCountry ?
                          <div className="phoneNumberWrapper">
                            <div className="row">
                              <div className="col-12">
                                <div className="inputWrapper position-relative">
                                  <input
                                    type="text"
                                    className='inp'
                                    placeholder='Search'
                                    name="Search"
                                    id="Search"
                                    onChange={(e) => handlesearch(e.target.value)}

                                  />
                                  <SearchIcon />

                                </div>
                              </div>
                              <div className="col-12 listParent">
                                {
                                  matchedItem.map((item, index) => (
                                    <div className="row mx-0 list my-2"
                                      key={index}
                                      onClick={() => {
                                        setSelect(item);
                                        localStorage.setItem("country_Id", item?._id)
                                        setIsOpen(false)
                                        setShowCountry(false)
                                      }}
                                    >
                                      <div className="col-auto ps-0">
                                        <div className="number">
                                          {item.phoneCode}
                                        </div>
                                      </div>
                                      <div className="col pe-0">
                                        <div className="country">
                                          {item.country}
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                }
                              </div>
                            </div>
                          </div>
                          : ''
                      }
                    </div>
                    <ErrorMessage>
                      {
                        errors.number &&
                        touched.number &&
                        errors.number
                      }
                    </ErrorMessage>
                  </div>
                  <div className="col-12">
                    <button
                      className="refferalBtn collepsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseExample"
                      aria-expanded="false"
                      aria-controls="collapseExample"
                    >
                      {/* Enter Referral/Promo Code */}
                      {authLabels.Enter_Referral}/{authLabels.Promo_Code}
                      <svg
                        xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                        <path d="M5.02282 5.73994C4.92844 5.74049 4.83488 5.7224 4.74751 5.68671C4.66014 5.65102 4.58067 5.59844 4.51366 5.53198L0.210901 1.22922C0.0758633 1.09418 0 0.911032 0 0.72006C0 0.529089 0.0758633 0.345938 0.210901 0.210901C0.345938 0.0758633 0.529089 0 0.72006 0C0.911032 0 1.09418 0.0758633 1.22922 0.210901L5.02282 4.01167L8.81641 0.218072C8.9536 0.100588 9.13007 0.0391974 9.31055 0.0461687C9.49104 0.05314 9.66224 0.12796 9.78996 0.255676C9.91768 0.383392 9.99249 0.554598 9.99946 0.735082C10.0064 0.915565 9.94505 1.09203 9.82756 1.22922L5.52481 5.53198C5.39123 5.66446 5.21095 5.73915 5.02282 5.73994Z" fill="#B2B6C5" />
                      </svg>
                    </button>
                    <div className="collapse" id="collapseExample">
                      <div className="card card-body collapseBody">
                
                        <input
                          className='inp'
                          type="text"
                          // placeholder='Enter Referral/Promo Code (optional)'
                          placeholder={`${authLabels.Enter_Referral}/${authLabels.Promo_Code}`}
                          name="referral"
                          id="referral"
                          value={values.referral}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-check formCheck">
                      <input
                        className="form-check-input formInp"
                        type="checkbox"
                        id="flexCheckDefault"
                        checked={checks.terms}
                        onChange={(e) => {
                          setChecks(pre => ({
                            ...pre,
                            terms: e.target.checked,
                          }))
                        }}
                      />
                      <label className="form-check-label formLbl" for="flexCheckDefault">
                        {/* I agree to the <span>User Agreement</span> & confirm I am at least 18 years old */}
                        {authLabels.I_agree_to_the} <span>{authLabels?.User_Agreement}</span> {authLabels.confirm_I_am}
                      </label>
                    </div>

                    <div className="form-check formCheck mt-0">
                      <input
                        className="form-check-input formInp"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault1"
                        checked={checks.promotions}
                        onChange={(e) => {
                          setChecks(pre => ({
                            ...pre,
                            promotions: e.target.checked,
                          }))
                        }}
                      />
                      <label className="form-check-label formLbl" for="flexCheckDefault1">
                        {/* I agree to receive marketing promotions from */}
                        {authLabels.I_agree_to_receive_marketing}
                      </label>
                    </div>
                  </div>
                  <div className="col-12 mt-3">
                    <button
                      className='signUpBtnNew'
                      type='button'
                      onClick={(e) => {
                        e.preventDefault()
                        handleSubmit()
                      }}
                    // >{isLoading ? <Spinner size="sm" color="dark" /> : 'Sign Up'}</button>
                    >{isLoading ? <Spinner size="sm" color="dark" /> : authLabels.Sign_Up}</button>
                  </div>
                  <div id="recaptcha-parent" />
                </form>
              )
            }

          </Formik>
        ) : (
          <OtpSignUp

            browserName={browserName}
            checks={checks}
            referral={referral}
            click_id={click_id}
            new_click_id={new_click_id}
            setIsOtp={setIsOtp}
            select={select}
            setSelect={setSelect}
            number={number}
            setChecks={setChecks}
            bonus={bonus}
          />
        )
      }
    </>
  )
}

export default SignUpNumber

