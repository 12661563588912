import React from 'react'
import { APP_NAME } from '../../../../../constants'

const RackBack = () => {
    return (
        <>
            <div className="row">
            <div className="col-12">
                <div className="heading mb-3">Get a bonus for your bets regardless of the result. Multiply the bonus with a Boost.</div>
                {/* <div className="text mb-3">Unless you are in profit</div> */}
                <div className="divider"></div>
            </div>
            <div className="col-12">
                <div className="headingBold mt-3 mb-2">Аccrual frequency:</div>
                <div className="textBold mb-3">Every 20 min</div>
                <div className="divider"></div>
            </div>
            <div className="col-12">
                <div className="headingBold mt-3 mb-2">Withdrawal distribution:</div>
                <div className="textBold mb-3">50% of the amount goes to your balance <br/>50% of the amount goes to the calendar for <br/>3 days</div>
                <div className="noteTxtBorder mb-3">
                    <div className="vip">For VIP users:</div>
                    <div className="balance">70% to your balance</div>
                    <div className="balance">30% to the calendar for 2 days</div>
                </div>
                <div className="text mb-3">1- The minimum amount for distribution to the 
                    calendar is $0.003 per day. The smaller amount is 
                    credited to the balance in full.
                </div>
                <div className="divider"></div>
            </div>
            <div className="col-12 mt-3">
                <div className="headingBold mb-1">What is the bonus calculated for?</div>
                <div className="headingBold">- <span className='text-white'>Casino bets </span>(Slots, {APP_NAME} Originals)</div>
                <div className="headingBold mb-3">- <span className='text-white'>Sports bets </span>(Closed bets with the coefficient 1.1 and more)
                </div>
                <div className="divider"></div>
            </div>
            <div className="col-12 mt-3">
                <div className="rankBox">
                    <div className="row mx-0">
                        <div className="col-12 mb-3">
                            <div className="rakeHeading mb-3">Rakeback Boost</div>
                            <div className="headingBold mb-1">Boost duration:</div>
                            <div className="textBold mb-3">60 minutes after activation</div>
                            <div className="divider"></div>
                        </div>
                        <div className="col-12 mb-3">
                            <div className="headingBold mb-1">How does it work?</div>
                            <div className="textBold mb-3">Get 2 times bigger Rakeback bonus.</div>
                            <div className="divider"></div>
                        </div>
                        <div className="col-12">
                            <div className="headingBold mb-1">How to activate the Boost?</div>
                            <div className="textBold mb-1">- Claim Cashback</div>
                            <div className="textBold mb-1">- Claim Weekly bonus </div>
                            <div className="textBold mb-1">- Claim Monthly bonus </div>
                            <div className="textBold mb-1"> - Claim calendar</div>
                            <div className="textBold">- Claim calendar bonuses</div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}

export default RackBack