import React, { useEffect, useRef, useState } from "react";
import ChatRulesModal from "./ChatRulesModal";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import EmojiPicker from 'emoji-picker-react';
import { socket } from "../../service/socket";
import UserInfoModal from "./UserInfoModal";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../hooks/useAuth";


let cursorRef = null
const MessageOffcanvas = () => {
    const [globalChat, setGlobalChat] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [message, setMessage] = useState('')
    const [messageLoading, setMessageLoading] = useState(false)
    const [isEmoji, setIsEmoji] = useState(false)
    const userDetails = useSelector(state => state.userDetails)
    const inputRef = useRef(null)
    const lastMsgRef = useRef(null)
    const [selectedUser, setSelectedUser] = useState({})
    const [isUserInfoOpen, setIsUserInfoOpen] = useState(false)
    const emojiContainerRef = useRef(null)
    const emojiIconRef = useRef(null)
    const emojiDiv = document.getElementById('emojiDiv');
    const { t } = useTranslation()
    const chatOffcanvas = t("chatOffcanvas", { returnObjects: true })
    const {token} = useAuth()


    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (emojiContainerRef?.current?.contains(event?.target) || emojiIconRef?.current?.contains(event?.target)) {
                setIsEmoji(true);
            }
            else {
                setIsEmoji(false);
            }

        };
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isEmoji]);


    // adding emoji
    const handleEmojiClick = (event, emojiObject) => {
        const emoji = emojiObject.emoji;
        const cursorPosition = inputRef.current.selectionStart;
        if (cursorPosition !== 0) {
            cursorRef = cursorPosition
        }
        if (cursorRef) {
            const newValue = message.slice(0, cursorRef) + emoji + message.slice(cursorRef);
            setMessage(newValue);
            inputRef.current.selectionStart = cursorRef + emoji?.length;
            inputRef.current.selectionEnd = cursorRef + emoji?.length;

        } else {
            const newValue = message.slice(0, cursorPosition) + emoji + message.slice(cursorPosition);
            setMessage(newValue);
            inputRef.current.selectionStart = cursorPosition + emoji?.length;
            inputRef.current.selectionEnd = cursorPosition + emoji?.length;
        }

        cursorRef = inputRef.current.selectionStart
    };
    //send chat
    const sendChatGlobal = async () => {
        if (!!!message) {
            setMessageLoading(false)
            return
        }
        // send message using emit
        socket.emit("sendMessage", { message: message, userId: userDetails?.id });
        setMessage('')
        // we will the updated chats (for sender)
        setGlobalChat(pre => ([
            ...pre,
            {
                message: message,
                messageType: "",
                _id: userDetails?.id,
                userData: {
                    image: userDetails?.image,
                    username: userDetails?.username,
                    _id: userDetails?.id
                }
            }
        ]));
        setMessageLoading(false)
    }
    // get chats and update chat
    useEffect(() => {
        try {
            // this is for global chat only
            // get all the chat in the room with .on
            socket.on('roomidCreated', (data) => {
                // we will get all the chats 
                setGlobalChat(data.prevChats);
            });

            // receive updated chat
            socket.on("reciviedMessage", (data) => {
                // we will the updated chats (not for sender, for others only)
                setGlobalChat((list) => [...list, data]);
                setIsLoading(false)
            });

        } catch (error) {
            console.log('socket error', error)
            // handle errors
        } finally {
            setIsLoading(false)
        }
    }, []);
    // to scroll the new message into view
    useEffect(() => {
        if (lastMsgRef?.current) {
            lastMsgRef?.current?.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "start",
            });
        }
    }, [globalChat?.length])

    const userInfoDetails = async (user) => {
        setSelectedUser(user)
        setIsUserInfoOpen(true)
    }
    // showMsgOffcanvas remove this class from body 

    console.log('globalChat', globalChat)
    return (
        <>
            <div
                className="offcanvas offcanvas-end msgoffcanvas"
                style={{ backgroundColor: '#161a25' }}
                tabIndex={-1}
                id="message"
                aria-labelledby="offcanvasExampleLabel"
            >
                <div className="offcanvas-header msgHeader p-0">
                    <div className="row w-100 align-items-center">
                        <div className="col-auto">
                            <div className="msgImg d-flex align-items-center">
                                <span className="d-flex align-items-center justify-content-center msgicon">
                                    <img src="assets/img/sidebar/chat.png" alt="message" className="h-100 w-100 img-fluid" />
                                </span>
                                {chatOffcanvas.chat}
                            </div>
                        </div>
                        <div className="col text-center px-0"
                        >
                            <span
                                className="chatrules cursor-pointer"
                                data-bs-toggle="modal"
                                data-bs-target="#chatrules"
                            >
                                {chatOffcanvas.CHAT_RULES}
                            </span>
                        </div>
                        <div className="col-auto pe-0">
                            <div className="d-flex align-items-center gap-2 h-100">
                                <button
                                    type="button"
                                    className="btn-close offcanvasclose p0 shadow-none border-0 d-flex align-items-center justify-content-center"
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close"
                                    onClick={() => document.body.classList.remove("showMsgOffcanvas")}
                                >
                                    <img
                                        src="assets/img/sidebar/offcanvasclose.png"
                                        alt="close"
                                        className="h-100 w-100 img-fluid"
                                    />
                                </button>
                                <button
                                    type="button"
                                    className="btn-close shadow-none border-0 closebtn pe-0 d-flex align-items-center justify-content-center"
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close"
                                    onClick={() => document.body.classList.remove("showMsgOffcanvas")}
                                >
                                    <img
                                        src="assets/img/sidebar/close.png"
                                        alt="close"
                                        className="h-100 w-100 img-fluid"
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="offcanvas-body d-flex flex-column msgBody position-relative pb-0">
                    <div className="row msgContent flex-column g-0">
                        {
                            isLoading ? (
                                <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                    <Spinner animation="border" variant="dark" size="sm" />
                                </div>
                            ) : (
                                globalChat?.length === 0 ? (
                                    <div className="w-100 h-100">
                                        <h6 className="text-light"> No data available</h6>
                                    </div>
                                ) : (
                                    globalChat?.map((item, index) => (
                                        <div
                                            className="col-12"
                                            key={item._id}
                                            ref={index === globalChat.length - 1 ? lastMsgRef : null}
                                        >
                                            <span className="msgBtn text-decoration-none d-flex align-items-start ">
                                                <span
                                                    onClick={() => {
                                                        if(token) {
                                                            userInfoDetails(item)
                                                        } else {
                                                            return
                                                        }
                                                    }}
                                                    className="userimg d-flex align-items-center overflow-hidden justify-content-center rounded-circle cursor-pointer">
                                                    <img src={item?.userData?.image ? item?.userData?.image : "assets/img/sidebar/user1.png"} alt="user" className="h-100 w-100 img-fluid" />
                                                </span>
                                                <div className="usermsg">
                                                    <span className="username cursor-pointer pe-1 "
                                                        onClick={() => {
                                                            userInfoDetails(item)
                                                        }}
                                                    >
                                                        {item?.userData?.username ? item?.userData?.username : "Guest"} :
                                                    </span>
                                                    {item.message}
                                                </div>
                                            </span>
                                        </div>
                                    ))
                                )
                            )
                        }
                    </div>
                    {
                        isEmoji ? (
                            <div ref={emojiContainerRef} className="sticky-bottom mt-auto w-100 border border-light rounded-top" style={{ backgroundColor: '#071432', height: '250px', zIndex: 9, borderColor: '#00D4D6'}}>
                                <EmojiPicker
                                    emojiStyle="native"
                                    width={"100%"}
                                    height={"100%"}
                                    onEmojiClick={(item) => handleEmojiClick("e", item)}
                                />
                            </div>
                        ) : (
                            <></>
                        )
                    }

                </div>
                <div className={`offcanvasFooter`}>
                    <div className="input-group InputMain align-items-center">
                        <input
                            type="text"
                            className="form-control sendInput shadow-none border-0 bg-transparent"
                            placeholder="Start chat... "
                            aria-label="Start chat...  with two button addons"
                            ref={inputRef}
                            value={message}
                            onChange={(e) => {
                                setMessage(e.target.value)
                                if (cursorRef !== null || cursorRef !== undefined) {
                                    cursorRef = null
                                }
                            }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    setMessageLoading(true)
                                    sendChatGlobal()
                                } else {
                                    setMessage(e.target.value)
                                    if (cursorRef !== null || cursorRef !== undefined) {
                                        cursorRef = null
                                    }
                                }
                            }}
                        />
                        <span
                            className="btn d-flex text-decoration-none align-items-center justify-content-center bg-transparent border-0 shadow-none smileFace rounded-circle overflow-hidden p-0"
                        >
                            <img
                                ref={emojiIconRef}
                                src="assets/img/sidebar/smileFace.png"
                                alt="smileface"
                                loading='lazy'
                                className="h-100 w-100 img-fluid"
                                onClick={() => setIsEmoji(!isEmoji)}
                            />
                        </span>
                        <button
                            className="btn sendBtn shadow-none border-0 p-0 d-flex align-items-center justify-content-center"
                            type="button"
                            onClick={() => {
                                setMessageLoading(true)
                                sendChatGlobal()
                            }}
                        >
                            <span className="d-flex align-items-center justify-content-center sendImg">

                                {
                                    messageLoading ? (
                                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                            <Spinner animation="border" variant="light" size="sm" />
                                        </div>
                                    ) : (
                                        <img
                                            src="assets/img/sidebar/send.png"
                                            alt="send"
                                            className="h-100 w-100 img-fluid"
                                        />
                                    )
                                }
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <ChatRulesModal />
            <UserInfoModal isOpen={isUserInfoOpen} setIsOpen={setIsUserInfoOpen} selectedUser={selectedUser} />
        </>
    );
};

export default MessageOffcanvas;
