import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import modalbg from '../../../assets/img/modalBg.png'
import modalIcon from '../../../assets/img/modalIcon.png'
import { getAPIAuth } from '../../../service/apiInstance';
import moment from 'moment';
import { useAuth } from '../../../hooks/useAuth';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES_CONST } from '../../../constants/routeConstant';
import TimerComponent from '../../../components/newWallet/components/TimerComponent';
import { useDispatch } from 'react-redux';
import { getUserBonusDetails } from '../../../store/action';
import { succesToaster } from '../../../utils/toaster';
import { useClaimBonus } from '../../../hooks/useClaimBonus';
import { GAME_OPTION_TABS } from '../../../constants';
import { useGames } from '../../../hooks/useGame';

const CashBackClaim = () => {

    const [data, setData] = useState({})
    const { token } = useAuth()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {claimBonusObject, setClaimBonusObject} = useClaimBonus()
    const [query, setQuery] = useSearchParams()
  const { gameObject, setGameObject } = useGames()



    const handleClose = () => {
        setClaimBonusObject(prev=>({
            ...prev,
            isClaimBonusOpen : false,
            // isClaimHistoryOpen: false
        }))
    }

    const getCashbackDetails = async () => {
        // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWJiMzMwZDFkZmIzZWI1NDVkZDhjNDUiLCJyZWZlcl9jb2RlIjoiZGVnZW5XaW4taWFtc1VHRkciLCJpYXQiOjE3MDY3NjcxMTd9.qjzg-mcvDPkNgpn0pLJnCrGgnV2oNd7b2qm8jF8pwws'
        try {
            const res = await getAPIAuth(`user/get-claim-data?type=${claimBonusObject?.selectedTab === 'deposit' || claimBonusObject?.selectedTab === 'daily' ? 'lockedBonus' : claimBonusObject?.selectedTab}`, token)
            if (res?.data?.status) {
                setData(res?.data?.data)
            } else {
                setData({})
            }
        } catch (error) {
            setData({})
        }
    }

    useEffect(() => {
        if (claimBonusObject?.isClaimBonusOpen) {
            getCashbackDetails()
        }
    }, [claimBonusObject?.isClaimBonusOpen, claimBonusObject?.selectedTab])

    // const checkStatus = () => {
    //     if (data?.userId) {
    //         if (data?.isExpired) {
    //             return 'disabled'
    //         } else if (data?.isClaimed) {
    //             return 'disabled'
    //         } else {
    //             return ''
    //         }
    //     } else {
    //         return 'disabled'
    //     }
    // }

    const handleBonusClaim = async () => {
        // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWJiMzMwZDFkZmIzZWI1NDVkZDhjNDUiLCJyZWZlcl9jb2RlIjoiZGVnZW5XaW4taWFtc1VHRkciLCJpYXQiOjE3MDY3NjcxMTd9.qjzg-mcvDPkNgpn0pLJnCrGgnV2oNd7b2qm8jF8pwws'
        try {
            let res = ''
            res = await getAPIAuth(`user/claimBonus?type=${claimBonusObject?.selectedTab === 'deposit' || claimBonusObject?.selectedTab === 'daily' ? 'lockedBonus' : claimBonusObject?.selectedTab}`, token)
            if (res?.data?.status) {
                if(claimBonusObject?.selectedTab === 'deposit' || claimBonusObject?.selectedTab === 'daily') {
                    handleClose()
                    getCashbackDetails()
                    
                } else {
                    getCashbackData()
                    handleClose()
                }
                succesToaster('Bonus Claimed Sussessfully')
                // setBonusData(res?.data?.data)
            } else {
                // setBonusData({})
            }
        } catch (error) {
            getCashbackDetails({})
        }
    }


    const getCashbackData = async () => {
        // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWJiMzMwZDFkZmIzZWI1NDVkZDhjNDUiLCJyZWZlcl9jb2RlIjoiZGVnZW5XaW4taWFtc1VHRkciLCJpYXQiOjE3MDY3NjcxMTd9.qjzg-mcvDPkNgpn0pLJnCrGgnV2oNd7b2qm8jF8pwws'
        try {
          const res = await getAPIAuth(`user/get-userbonus-stats`, token)
          if (res?.data?.success) {
            dispatch(getUserBonusDetails({
              bonusData: res?.data?.data?.[0],
              lockedStatus: res.data?.lockStatus
            }))
            // setLockStatus(res.data?.lockStatus)
            // setBonusData(res?.data?.data?.[0])
          }
        } catch (error) {
    
            }
        }

    return (
        <>
            <Modal className='modalWrapper usdtBonusModal '
                centered
                scrollable
                show={claimBonusObject?.isClaimBonusOpen} onHide={handleClose}>
                <div className="row align-items-center modalHeaderWrapper mx-0">
                    <div className="col px-0">
                        <div className="modalHeader">
                            {
                                claimBonusObject?.selectedTab === 'weekly' ? 'Weekly Claim' :
                                claimBonusObject?.selectedTab === 'monthly' ? 'Monthly Claim' :
                                claimBonusObject?.selectedTab === 'deposit' ? 'Deposit Claim' :
                                claimBonusObject?.selectedTab === 'rackback' ? 'Rackback Claim' :
                                claimBonusObject?.selectedTab === 'daily' ? 'Daily Claim' : 'Cashback Claim'
                            }

                        </div>
                    </div>
                    <div className="col-auto px-0">
                        <div
                            className="closeModal cursor-pointer d-flex"
                            onClick={handleClose}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
                                <path d="M9.29293 2.06224C9.55211 1.80304 9.55211 1.3828 9.29293 1.1236C9.03374 0.864399 8.61348 0.864399 8.35429 1.1236L4.77492 4.70298L1.19554 1.1236C0.936346 0.864399 0.516098 0.864399 0.256902 1.1236C-0.00230066 1.3828 -0.00230066 1.80304 0.256902 2.06224L3.83629 5.64162L0.256902 9.22099C-0.00230066 9.48018 -0.00230066 9.90044 0.256902 10.1596C0.516098 10.4188 0.936346 10.4188 1.19554 10.1596L4.77492 6.58025L8.35429 10.1596C8.61348 10.4188 9.03374 10.4188 9.29293 10.1596C9.55211 9.90044 9.55211 9.48018 9.29293 9.22099L5.71355 5.64162L9.29293 2.06224Z" fill="white" />
                            </svg>
                        </div>
                    </div>
                </div>
                <Modal.Body className='modalBody'>
                    <div className="row mx-0 gx-0">
                        <div className="col-12">
                            <div className="cashbackcardWrapper">
                                <div className="cashbackcard d-flex align-items-center justify-content-center flex-column">
                                    <div className="modalIconn"><img src={modalIcon} alt="" /></div>
                                    <div className="modalTxt mb-1">Claim Cashback</div>
                                    <div className="modalDarkBtn mb-3">
                                        {
                                            claimBonusObject?.selectedTab === 'deposit' || claimBonusObject?.selectedTab === 'daily' ?
                                                <>
                                                    {data?.unlockAmount ? Number(data?.unlockAmount)?.toFixed(2) : 0}
                                                </>
                                            : <>
                                                {data?.[0]?.amount ? Number(data?.[0]?.amount)?.toFixed(2) : 0}
                                            </>
                                        }
                                    
                                        <span> USDT</span></div>
                                        {
                                            claimBonusObject?.selectedTab === 'deposit' || claimBonusObject?.selectedTab === 'daily' ?
                                            <>
                                                <div className={`modalBtn mb-2 ${data?.claminStatus ? '' : 'disabled'}`}
                                                    onClick={handleBonusClaim}>Claim</div>
                                            </> :
                                            <>
                                                <div className={`modalBtn mb-2`}
                                                    onClick={handleBonusClaim}>Claim</div>
                                                    <div className="modalTxt">Claim before: <span>
                                                        <TimerComponent targetTime={data?.[0]?.expireDate}/>
                                                        </span></div>
                                            </>
                                        }
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-3">
                            <div className="row mx-0 lockedWrapper align-items-center">
                                <div className="col">
                                    <div className="locked d-flex align-items-center">Locked USDT:<svg xmlns="http://www.w3.org/2000/svg" width="8" height="10" viewBox="0 0 8 10" fill="none">
                                        <path d="M4.19787 0.376465C2.73625 0.376465 1.5471 1.56557 1.5471 3.02724V4.54116C1.5471 4.55836 1.52587 4.58735 1.50919 4.5929C1.36584 4.6407 1.24943 4.68212 1.14286 4.7232C1.01577 4.7722 0.919922 4.91303 0.919922 5.05082V8.52939C0.919922 8.66623 1.01515 8.80719 1.14143 8.85724C2.11849 9.24441 3.14682 9.44068 4.19785 9.44068C5.24888 9.44068 6.27721 9.24436 7.25431 8.85719C7.38055 8.80716 7.47573 8.66623 7.47573 8.52939V5.05082C7.47573 4.91303 7.3799 4.7722 7.25275 4.72316C7.14614 4.68207 7.02974 4.64065 6.8865 4.59285C6.86987 4.58731 6.84864 4.5583 6.84864 4.5411V3.02719C6.84856 1.56557 5.65948 0.376465 4.19787 0.376465ZM3.47586 6.11027C3.47586 5.71124 3.79911 5.38833 4.19782 5.38833C4.5965 5.38833 4.91975 5.71121 4.91975 6.11027C4.91975 6.37468 4.77064 6.59605 4.55878 6.72154V7.91514C4.55878 8.11468 4.39733 8.27612 4.1978 8.27612C3.99827 8.27612 3.83683 8.11468 3.83683 7.91514V6.72154C3.62498 6.59605 3.47586 6.37468 3.47586 6.11027ZM5.93716 3.02719V4.33343C5.36678 4.21069 4.78162 4.14854 4.1981 4.14849C3.61484 4.14849 3.0298 4.21066 2.45854 4.33329V3.02719C2.45854 2.06813 3.23877 1.28791 4.19782 1.28791C5.15685 1.2879 5.93716 2.06813 5.93716 3.02719Z" fill="#B2B6C5" />
                                    </svg>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    {/* <div className="usdt">{data?.lockedUsd ? Number(data?.lockedUsd)?.toFixed(2) : 0} USDT</div> */}
                                    <div className="usdt">
                                    {
                                            claimBonusObject?.selectedTab === 'deposit' || claimBonusObject?.selectedTab === 'daily' ?
                                            <>
                                                {data?.lockedUsd ? Number(data?.lockedUsd)?.toFixed(2) : 0}
                                            </> :
                                            <>
                                                {data?.[0]?.lockedUsd ? Number(data?.[0]?.lockedUsd)?.toFixed(2) : 0}
                                            </>
                                        }
                                    
                                    USDT</div>
                                    <div className="rupee">$0.00</div>
                                </div>
                            </div>
                        </div>
                        <div className="text-center py-3 howToClaim">How to claim</div>
                        <div className='col-12'>
                            <div className="redirectBox row mx-0 gx-3">
                                <div className="col-12 hClainTxtt mb-2">Unlock amount = wager amount * 1% * 20%</div>
                                <div className="col-12 hClainTxtt mb-3 pb-1">You still need to wager about $ .... more in order to reach minimum unlockable amount of 5 USDT.</div>
                                <div className="col-6">
                                    <button className='hClainBtnn'
                                        onClick={() => {
                                            handleClose()
                                            // navigate(ROUTES_CONST.GAME_PAGE)
                                            
                                            setGameObject((prev) => ({
                                                selectedTab: GAME_OPTION_TABS?.LOBBY
                                            }))
                                            setQuery({ q: "lobby" })
                                            navigate(`${ROUTES_CONST.GAME_PAGE}?q=${encodeURIComponent('lobby')}`)
                                        }}
                                    >Go to Casino</button>
                                </div>
                                <div className="col-6">
                                    <button className='hClainBtnn'
                                        onClick={() => {
                                            handleClose()
                                            navigate(ROUTES_CONST.SPORTS)
                                        }}
                                    >Go to Sports</button>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 mt-3">
                            <div onClick={() => {
                                setClaimBonusObject(prev=>({
                                    ...prev,
                                    isClaimBonusOpen : false,
                                    isClaimHistoryOpen: true
                                }))
                            }} className="cashBackBtn mx-auto">Cashback <span>bonus History<svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" viewBox="0 0 6 11" fill="none">
                                <path d="M0.90818 10.1351C0.77782 10.1359 0.650187 10.0974 0.541554 10.0245C0.43292 9.95169 0.348208 9.84778 0.298219 9.72606C0.248229 9.60434 0.235227 9.47032 0.260869 9.3411C0.286511 9.21188 0.349636 9.09331 0.442196 9.0005L3.92067 5.49043L0.442196 1.98037C0.318609 1.85542 0.249178 1.68596 0.249178 1.50926C0.249178 1.42177 0.266224 1.33513 0.299342 1.2543C0.33246 1.17347 0.381002 1.10002 0.442196 1.03816C0.50339 0.976289 0.576037 0.927214 0.655991 0.893733C0.735945 0.860251 0.821639 0.843018 0.90818 0.843018C1.08296 0.843018 1.25058 0.913211 1.37417 1.03816L5.31206 5.01933C5.4343 5.14365 5.50292 5.31182 5.50292 5.48712C5.50292 5.66241 5.4343 5.83058 5.31206 5.9549L1.37417 9.93608C1.31338 9.99877 1.24089 10.0486 1.1609 10.0828C1.08092 10.117 0.995016 10.1348 0.90818 10.1351Z" fill="#B2B6C5" />
                            </svg></span>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CashBackClaim