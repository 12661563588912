import { signInWithEmailAndPassword, updateEmail, updatePassword } from 'firebase/auth';
import React, { useEffect, useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { auth } from '../../../config/firebase';
import { errorToaster, succesToaster } from '../../../utils/toaster';
import { postAPIAuth } from '../../../service/apiInstance';
import { useAuth } from '../../../hooks/useAuth';

const ConfirmPassword = ({showConfirmPassword, setShowConfirmPassword, userEmail, userPassword, setUserPassword, actionType}) => {
  const handleClose = () => {
    setShowConfirmPassword(false);
    setError(false)
    setPassword('')
    setIsLoading(false)
  };
  const [isLoading, setIsLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [error, setError] = useState(false)
  const userDetail = useSelector(state=> state.userDetails)
  const [userId , setUserId] = useState('')
  const {setToken} = useAuth()

  useEffect(()=> {
    setUserId(userDetail?.id)
  }, [userDetail])


  const handleChangeEmail = async () => {
    if(password.length >= 8){
      setError(false)
      setIsLoading(true)
      try {
        if(userDetail?.email) {
          const userCredential = await signInWithEmailAndPassword(
            auth,
            userDetail?.email,
            password
          );
          if(userCredential?.user?.accessToken) {
                  try {
                    updateEmail(auth.currentUser, userEmail).then(() => {
                      handleEmailChangeInNode()
                    }).catch((error) => {
                      handleClose()
                      console.log('error')
                    });

                  } catch (error) {
                    console.log(error)
                    errorToaster(error.message)
                    handleClose()
                  }
          }
        }
      } catch (error) {
        errorToaster(error?.message)
      }
    } else {
      setError(true)
    }
  }

  const handleEmailChangeInNode = async ()=> {
    try {
      const res = await postAPIAuth('user/update-username', {
        userId,
        email: userEmail
      })
      if (res?.data?.success) {
        setToken(res?.data?.data?.auth_token)
        succesToaster('Email Changed Successfully')
        handleClose()
        // setUserEmail('')
      } else {
        errorToaster('Something error has happened')
        updateEmail(auth.currentUser, userDetail?.email).then(() => {
        }).catch((error) => {
        });
        handleClose()
      }
    } catch (error) {
      
    }
  }

  const handleChangePassword = async () => {
    if(password.length >= 8){
      setError(false)
      setIsLoading(true)
      try {
        if(userDetail?.email) {
          const userCredential = await signInWithEmailAndPassword(
            auth,
            userDetail?.email,
            password
          );
          
          if(userCredential?.user?.accessToken) {
              try {
                updatePassword(auth.currentUser, userPassword).then(() => {
                  handlePassChangeInNode()
                }).catch((error) => {
                  errorToaster('Something error has happened')
                  handleClose()
                });
              } catch (error) {
                console.log(error)
                errorToaster(error.message)
                handleClose()
              }
          }
        }
      } catch (error) {
        errorToaster(error?.message)
      }
    } else {
      setError(true)
    }
  }

  const handlePassChangeInNode = async ()=> {
    try {
      const res = await postAPIAuth('user/update-username', {
        userId,
        password: userPassword
      })
      if (res?.data?.success) {
        succesToaster('Password Changed Successfully')
        setUserPassword('')
        handleClose()
      } else {
        errorToaster('Something error has happened')
        handleClose()
        updatePassword(auth.currentUser, password).then(() => {
        }).catch((error) => {
          errorToaster('Something error has happened')
        });
      }
    } catch (error) {
      
    }
  }

  const handleClick = ()=> {
    if(actionType === 'changeEmail') {
      handleChangeEmail()
    } else if (actionType === 'changePassword') {
      handleChangePassword()
    } else {
      return
    }
  }

  return (
    <>
      <Modal className='confirmPassModal' centered show={showConfirmPassword} onHide={handleClose}>
        <Modal.Header>
          <div className="row w-100 mx-0 gx-0">
            <div className="col modalHeadTxt cardsubHeading ">confirm password</div>
            <div className="col-auto">
              <div class="closeBtn cursor-pointer" onClick={handleClose}><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 10 10" fill="none"><path d="M9.22987 1.13297C9.48903 0.873792 9.48903 0.45357 9.22987 0.19439C8.9707 -0.0647967 8.55046 -0.0647967 8.29129 0.19439L4.71213 3.77356L1.13297 0.19439C0.873792 -0.0647967 0.45357 -0.0647967 0.19439 0.19439C-0.0647967 0.45357 -0.0647967 0.873792 0.19439 1.13297L3.77356 4.71213L0.19439 8.29129C-0.0647967 8.55046 -0.0647967 8.9707 0.19439 9.22987C0.45357 9.48903 0.873792 9.48903 1.13297 9.22987L4.71213 5.65071L8.29129 9.22987C8.55046 9.48903 8.9707 9.48903 9.22987 9.22987C9.48903 8.9707 9.48903 8.55046 9.22987 8.29129L5.65071 4.71213L9.22987 1.13297Z" fill="white"></path></svg></div>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div class="inputGroup w-100 position-relative d-flex">
            <input value={password} onChange={(e)=>setPassword(e.target.value)} class="EmailInput form-control w-100 shadow-none" type="password" id="password" placeholder="Old Password"/>
          </div>
          {
            error && (
              <div className="text-danger errorTxt mt-1">Password must have at least 8 characters</div>
            )
          }
          <button onClick={handleClick} 
          style={{width: '195px', height: '42px'}}
          class="customBtn mt-2 mx-auto rounded-pill border-0 shadow-none changeGmailBtn text-nowrap d-flex align-items-center justify-content-center ">
            
            {
              isLoading ? <Spinner size='sm'/> : 'CONFIRM PASSWORD'
            }
          </button>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ConfirmPassword