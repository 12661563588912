import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './assets/publicAssets/scss/style.scss'
import './index.scss';
import './appUi.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-toastify/dist/ReactToastify.css";
import Providers from './providers';
import "./i18n";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Providers>
    <App />
  </Providers>
  // </React.StrictMode >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
