import React, { useEffect, useState } from "react";
import AllBetsTable from "../../casinoPage/components/AllBetsTable";
import HighBetsTable from "../../casinoPage/components/HighBetsTable";
import RareBetsTable from "../../casinoPage/components/RareBetsTable";
import { socket } from "../../../service/socket";
import { useTranslation } from "react-i18next";
import { getAPI } from "../../../service/apiInstance";

const BetLeaderBoard = () => {
    const [data, setData] = useState([])
    const [endPoint, setEndPoint] = useState('get-bets')
    const [loader, setLoader] = useState(true)
    const [allBets, setAllBets] = useState([])
    const [highBets, setHighBets] = useState([])
    const [lowBets, setLowBets] = useState([])
    const { t } = useTranslation()
    const PlaySlotLabels = t("PlaySlotLabels", { returnObjects: true })
    const [loading, setLoading] = useState(true)

    // const allBetsHandler = () => {
    //     socket.emit("check", { type: "allBets" });
    //     socket.on("allBets", (data) => {
    //         setAllBets(data?.betdata?.data);
    //     });
    // }
    // const highBetsHandler = () => {
    //     socket.emit("check", { type: "highBets" });

    //     socket.on("highBets", (data) => {
    //         setHighBets(data?.betdata?.data);
    //     });
    // }
    // const lowBetsHandler = () => {
    //     socket.emit("check", { type: "lowBets" });

    //     socket.on("lowBets", (data) => {
    //         setLowBets(data?.betdata?.data);
    //     });
    // }

    // useEffect(() => {
    //     allBetsHandler()
    //     highBetsHandler()
    //     lowBetsHandler()
    // }, [socket])

    const getAllBets = async()=> {
        // setLoading(true)
        try {
            const res = await getAPI(`casino/${endPoint}`)
            if(endPoint === "get-bets") {
                setAllBets(res?.data?.data)
            }
            if(endPoint === "high-bet") {
                setHighBets(res?.data?.data)
            }
            if(endPoint === "low-bet") {
                setLowBets(res?.data?.data)
            }
        } catch (error) {
            
        } finally {
            // setLoading(false)
        }
    }


    useEffect(()=> {
        const interval = setInterval(() => {
            getAllBets()
        }, 10000);
        return ()=> {
            clearInterval(interval)
        }
    },[])

    useEffect(()=> {
        setLoading(true)
        getAllBets()
        setLoading(false)
    }, [endPoint])


    return (
        <div className="container-fluid dambleTable  liveBets mt-4 mb-5">
            <div className="row gap-5">
                <div className="col-12 px-0">
                    <div className="sectionInner">
                        <div className="tableCard row mx-0">
                            <div className="col-12 px-0">
                                <div className="row align-items-center justify-content-between mx-0">
                                    <div className="col-auto gamesHeading">
                                        <div className="livebets"><img src="assets/img/index/livebets.png" alt="" />{PlaySlotLabels.LATEST_BETS}</div>
                                    </div>
                                    <div className="col d-flex justify-content-end px-0">
                                        <nav>
                                            <ul className="nav nav-tabs border-0 d-sm-inline-flex d-flex" id="myTab" role="tablist ">
                                                <li className="nav-item">
                                                    <button className={`nav-link border-0 shadow-none rounded-pill ${endPoint === 'get-bets' ? 'active' : ''}`} onClick={() => setEndPoint('get-bets')}>{PlaySlotLabels.All_bets}</button>
                                                </li>
                                                <li className="nav-item">
                                                    <button className={`nav-link border-0 shadow-none rounded-pill ${endPoint === 'high-bet' ? 'active' : ''}`} onClick={() => setEndPoint('high-bet')}>{PlaySlotLabels.High_rollers}</button>
                                                </li>
                                                <li className="nav-item">
                                                    <button className={`nav-link border-0 shadow-none rounded-pill ${endPoint === 'low-bet' ? 'active' : ''}`} onClick={() => setEndPoint('low-bet')}>{PlaySlotLabels.Rare_wins}</button>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                    <div className="col-12  px-0">
                                        {
                                            endPoint === 'get-bets' ? <AllBetsTable data={allBets} loading={loading}/> : <></>
                                        }
                                        {
                                            endPoint === "high-bet" ? <HighBetsTable data={highBets} loading={loading}/> : <></>
                                        }
                                        {
                                            endPoint === "low-bet" ? <RareBetsTable data={lowBets} loading={loading}/> : <></>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BetLeaderBoard