import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getAPIAuth, postAPIAuth } from "../../../service/apiInstance";
import { errorToaster, succesToaster } from "../../../utils/toaster";
import { getAuth, updateEmail, updatePassword } from "firebase/auth";
import { Spinner } from "react-bootstrap";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../hooks/useAuth";
import ConfirmPassword from "./ConfirmPassword";

function Settings({ openaccordian, tabopen }) {
  const userDetails = useSelector((state) => state.userDetails);
  const [userEmail, setUserEmail] = useState(userDetails.email)
  const [userPassword, setUserPassword] = useState('')
  const [userId, setUserId] = useState('')
  const auth = getAuth();
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [actionType, setActionType] = useState('')
  const [error, setError] = useState({
    email: '',
    password: ''
  })
  const [isLoading, setIsLoading] = useState(true)
  const [loginData, setLoginData] = useState([])
  const { t } = useTranslation()
  const accountPageLabels = t("accountPageLabels", { returnObjects: true })
  const commonText = t("commonText", { returnObjects: true })
  const [isEmailLogin, setIsEmailLogin] = useState(false)
const {setToken} = useAuth()
  useEffect(() => {
    setUserEmail(userDetails?.email)
    setUserId(userDetails?.id)
  }, [userDetails])

  // change user email password
  // const handleEmail = async () => {
  //   if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userEmail)) {
  //     setError((prev) => ({
  //       ...prev,
  //       email: '',
  //     }));
  //     try {
  //       const res = await postAPIAuth('user/update-username', {
  //         userId,
  //         email: userEmail
  //       })
  //       if (res?.data?.success) {
          
  //         setToken(res?.data?.data?.auth_token)
  //         updateEmail(auth.currentUser, userEmail).then(() => {
  //         }).catch((error) => {
  //         });
  //         succesToaster('Email Changed Successfully')
  //         setUserEmail('')
  //         // 

  //       } else {
  //         errorToaster('Something error has happened')
  //       }
  //     } catch (error) {

  //     }
  //   } else {
  //     setError((prev) => ({
  //       ...prev,
  //       email: 'Enter valid email',
  //     }));
  //   }
  // }

  const handleEmail = async () => {
    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userEmail)) {
      setError((prev) => ({
        ...prev,
        email: '',
      }));
      setActionType('changeEmail')
      setShowConfirmPassword(true)
      // try {
      //   updateEmail(auth.currentUser, userEmail).then(() => {
      //     handleEmailChangeInNode()
      //   }).catch((error) => {
      //   });

      // } catch (error) {
      //   console.log(error)
      //   errorToaster(error.message)
      // }
    } else {
      setError((prev) => ({
        ...prev,
        email: 'Enter valid email',
      }));
    }
  }

  // const handleEmailChangeInNode = async ()=> {
  //   try {
  //     const res = await postAPIAuth('user/update-username', {
  //       userId,
  //       email: userEmail
  //     })
  //     if (res?.data?.success) {
  //       setToken(res?.data?.data?.auth_token)
  //       succesToaster('Email Changed Successfully')
  //       setUserEmail('')
  //     } else {
  //       errorToaster('Something error has happened')
  //     }
  //   } catch (error) {
      
  //   }
  // }

  // change user password
  // const handlePassword = async () => {
  //   if (userPassword.length >= 8) {
  //     setError((prev) => ({
  //       ...prev,
  //       password: '',
  //     }));
  //     try {
  //       const res = await postAPIAuth('user/update-username', {
  //         userId,
  //         password: userPassword
  //       })
  //       if (res?.data?.success) {
  //         updatePassword(auth.currentUser, userPassword).then(() => {
  //           // Update successful.
  //         }).catch((error) => {
  //           // An error ocurred
  //           // ...
  //         });
  //         succesToaster('Password Changed Successfully')
  //         setUserPassword('')
  //       } else {
  //         errorToaster('Something error has happened')
  //       }
  //     } catch (error) {

  //     }
  //   } else {
  //     setError((prev) => ({
  //       ...prev,
  //       password: 'Password must have at least 8 characters',
  //     }));
  //   }
  // }

  const handlePassword = async () => {
    if (userPassword.length >= 8) {
      setError((prev) => ({
        ...prev,
        password: '',
      }));

      
      setActionType('changePassword')
      setShowConfirmPassword(true)

      // try {
      //   updatePassword(auth.currentUser, userPassword).then(() => {
      //     handlePassChangeInNode()
      //   }).catch((error) => {
      //     errorToaster('Something error has happened')
      //   });

      // } catch (error) {

      // }
    } else {
      setError((prev) => ({
        ...prev,
        password: 'Password must have at least 8 characters',
      }));
    }
  }

  const handlePassChangeInNode = async ()=> {
    try {
      const res = await postAPIAuth('user/update-username', {
        userId,
        password: userPassword
      })
      if (res?.data?.success) {
        succesToaster('Password Changed Successfully')
        setUserPassword('')
      } else {
        errorToaster('Something error has happened')
      }
    } catch (error) {
      
    }
  }

  const getLoginHistory = async () => {
    if (!userId) return
    try {
      const res = await getAPIAuth(`user/get-login-history?userId=${userId}`)
      if (res.data.success) {
        setLoginData(res.data.data)
        setIsEmailLogin(res?.data?.data?.[0]?.isLoginWithEmail)
      } else {
        //error
      }
    } catch (error) {
      // err
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (tabopen === 'Settings' || openaccordian === "Settings") {
      getLoginHistory()
    }
  }, [userId, tabopen, openaccordian])
  return (
    <>
      <div className="row">
        <div className="col-12 d-none d-md-block">
          <div className="d-flex align-items-center accountCardHeading">
            <div className="cardImg">
              <img
                src="assets/img/sidebar/setting.png"
                alt="SETTINGS"
                className="h-100 w-100 img-fluid "
              />
            </div>
            {accountPageLabels.SETTINGS}
          </div>
        </div>
        {
          isEmailLogin && (
            <>
              <div className="col-12 cardsubHeading settingHeading">{accountPageLabels.CHANGE_EMAIL}</div>
              <div className="col-12 pt-2 pt-sm-3">
                <div className="d-flex align-items-sm-center flex-column flex-sm-row gap-2 gap-sm-3">
                  <div className="inputGroup w-100 position-relative d-flex">
                    <input
                      className="EmailInput form-control w-100 shadow-none"
                      type="email"
                      id="email"
                      placeholder={"New Email"}
                      value={userEmail}
                      onChange={(e) => { setUserEmail(e.target.value) }}
                    />
                  </div>
                  <button
                    onClick={handleEmail}
                    className="customBtn shadow-none changeGmailBtn text-nowrap d-flex align-items-center justify-content-center "
                  >
                    {accountPageLabels.CHANGE_EMAIL}
                  </button>
                </div>
                {error?.email ? <div className="text-danger errorTxt">{error?.email}</div> : ''}
              </div>
              <div className="col-12 cardsubHeading settingHeading pt-4">{accountPageLabels.CREATE_PASSWORD}</div>
              <div className="col-12 pt-2 pt-sm-3">
                <div className="d-flex align-items-sm-center flex-column flex-sm-row gap-2 gap-sm-3">
                  <div className="inputGroup w-100 position-relative d-flex">
                    <input
                      className="EmailInput form-control w-100 shadow-none"
                      type="password"
                      id="password"
                      placeholder="New Password"
                      value={userPassword}
                      onChange={(e) => setUserPassword(e.target.value)}
                    />
                  </div>
                  <button
                    onClick={handlePassword}
                    className="customBtn shadow-none changeGmailBtn text-nowrap d-flex align-items-center justify-content-center"
                  >
                    {accountPageLabels.CHANGE_PASSWORD}
                  </button>
                </div>
                {error?.password ? <div className="text-danger errorTxt">{error?.password}</div> : ''}
              </div>
            </>
          )
        }
        {/* will be of future use */}
        {/* <div className="col-12 cardsubHeading settingHeading pt-5">
          TWO-FACTOR AUTHENTICATION{" "}
          <button
            className="border-0 cardsubHeading settingHeading disabled bg-transparent"
            disabled
          >
            DISABLED
          </button>
        </div>
        <div className="col-12 affiliateCardTitle pt-3">
          Using two-factor authentication is highly recommended because it
          protects your account with both your password and your phone.
        </div>
        <div className="col-12 pb-2 affiliateCardTitle pt-3">
          An email and password must be set to activate 2FA. While 2FA is
          enabled, you will not be able to login via Steam.
        </div>
         <div className="col-auto pt-4">
          <a
            href="javascript:;"
            className="customBtn shadow-none changeGmailBtn text-nowrap d-flex align-items-center justify-content-center "
          >
            ENABLE 1 FA
          </a>
        </div> */}
        {/* <div className="col-12 cardsubHeading settingHeading pt-4 pt-sm-5">
          {accountPageLabels.VERIFY_YOUR_IDENTITY}
        </div>
        <div className="col-auto pt-3">
          <span
            className="customBtn shadow-none changeGmailBtn text-nowrap d-flex align-items-center justify-content-center "
          >
            {accountPageLabels.VERIFY}
          </span>
        </div> */}
        <div className="col-12 cardsubHeading settingHeading pt-4 pt-sm-5">{accountPageLabels.LOGIN_HISTORY}</div>
        <div className="col-12 pt-3">
          <div className="table-responsive scrollShortTable">
            <table className="table affiliateTable mb-2 align-middle">
              <thead>
                <tr className="tableHead text-nowrap">
                  <th scope="col" className="border-0 rounded-start-2 px-4">
                    {accountPageLabels.DATE}
                  </th>
                  <th scope="col" className="border-0 px-4">
                    {accountPageLabels.LOGIN_METHOD}
                  </th>
                  <th scope="col" className="border-0 px-4 texter">
                    {accountPageLabels.COUNTRY}
                  </th>
                  <th
                    scope="col"
                    className="border-0 px-4 text-er rounded-end-2"
                  >
                    {accountPageLabels.IP}
                  </th>
                </tr>
              </thead>
              {
                isLoading ? (
                  <span className="w-100 h-100 d-flex justify-content-center align-items-center">
                    {/* <Spinner animation="border" variant="light" size="sm" /> */}
                  </span>
                ) : (
                  <tbody className="tablebody">
                    {
                      loginData?.length === 0 ? (
                        <tr className="text-nowrap">
                          <td colSpan={4} className="border-0 text-center px-4">
                            <div className="text-center">{commonText.NoDataAvailable}</div>
                          </td>
                        </tr>
                      ) : (
                        loginData?.map(
                          (item) => (
                            <tr key={item._id} className="text-nowrap">
                              <td scope="row" className="border-0  rounded-start-2 px-4">
                                {moment(item.createdAt).format("MMM DD YYYY , HH:mm")}
                              </td>
                              <td className="border-0 px-4">{item.login_method === "Registeration" ? "Registration" : item.login_method}</td>
                              <td className="border-0 px-4">{item.country}</td>
                              <td className="border-0 px-4">{item.ip_address}</td>
                            </tr>
                          )
                        )
                      )
                    }
                  </tbody>
                )
              }
            </table>
          </div>
        </div>
      </div>
      <ConfirmPassword 
        showConfirmPassword={showConfirmPassword} 
        setShowConfirmPassword={setShowConfirmPassword} 
        userEmail={userEmail}
        userPassword={userPassword}
        setUserPassword={setUserPassword}
        actionType={actionType}
      />
    </>
  );
}

export default Settings;
