import React, { useEffect, useState } from "react";
import { getAPIAuth } from "../../../service/apiInstance";
import { useSelector } from "react-redux";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function Withdraw({ tabopen, openaccordian }) {
  const [cryptoList, setCryptoList] = useState([])
  const [activeCrypto, setActiveCrypto] = useState('ALL')
  const [withdraws, setWithdraws] = useState([])
  const [transactionId, setTransactionId] = useState('')
  const [loader, setLoader] = useState(false)
  const [listLoader, setListLoader] = useState(true)
  const userDetails = useSelector((state) => state.userDetails);
  const userId = userDetails?.id;
  const userBalance = userDetails?.totalbalance;
  const [showTransaction, setShowTransaction] = useState(false)
  const { t } = useTranslation()
  const accountPageLabels = t("accountPageLabels", { returnObjects: true })
  const [pagination, setPagination] = useState({
    btnOne: 1,
    btnTwo: 2,
    btnThree: 3,
    btnFour: "...",
    btnFive: 0
  })
  const [pageNo, setPageNo] = useState(1)
  const [isLoadMore, setIsLoadMore] = useState(false)
  const [totalPages, setTotalPage] = useState()

  const paginationHandler = (count) => {
    if (pagination.btnOne != pageNo) {
      if (pageNo - 1 == pagination.btnThree) {
        setPagination(
          prev => ({
            ...prev,
            btnOne: pagination.btnOne + 1,
            btnTwo: pagination.btnTwo + 1,
            btnThree: pagination.btnThree + 1
          })
        )
      } else if (pageNo + 1 == pagination.btnOne) {
        setPagination(
          prev => ({ ...prev, btnOne: pagination.btnOne - 1, btnTwo: pagination.btnTwo - 1, btnThree: pagination.btnThree - 1 })
        )
      } else if (pageNo == totalPages) {
        setPagination(
          prev => ({ ...prev, btnOne: totalPages - 2, btnTwo: totalPages - 1, btnThree: totalPages })
        )
      }
    }
  }


  const loadMore = async () => {
    try {
      const res = await getAPIAuth(`user/get-withdraw-list?userId=${userId}&chainId=${activeCrypto == 'ALL' ? "" : activeCrypto}&transactionId=${transactionId}&page=${pageNo}`)

      if (res.data.success) {
        // setTransactionData(res.data.data)
        setWithdraws(res?.data?.data)
        setTotalPage(res.data.totalPages)
      }
    } catch (error) {
      //error handling
    }

  }

  useEffect(() => {
    if (userDetails?.id && (openaccordian === "Deposits" || tabopen === "Deposits")) {
      if (pageNo >= 1 && isLoadMore) {
        paginationHandler()
        loadMore()
        setIsLoadMore(false)
      } else if (pageNo >= 1) {
        paginationHandler()
        loadMore()
      }
    }
  }, [pageNo, userDetails?.id, openaccordian])


  // getting all crypto id
  const getCryptoList = async () => {
    setListLoader(true)
    try {
      const res = await getAPIAuth(`crypto/get-crypt-favorite-by-admin`)
      if (res?.data?.success) {
        setCryptoList(res?.data?.data)
        setActiveCrypto("ALL")
      }
    } catch (error) {

    } finally {
      setListLoader(false)
    }
  }

  useEffect(() => {
    getCryptoList()
  }, [])


  const getWithdraws = async () => {
    setLoader(true)
    try {
      if (userId) {
        // const res = await getAPIAuth(`user/find-transaction?userId=${userId}&chain=${activeCrypto}&type=withdraw&transactionId=${transactionId}`)
        const res = await getAPIAuth(`user/get-withdraw-list?userId=${userId}&chainId=${activeCrypto == 'ALL' ? "" : activeCrypto}&transactionId=${transactionId}&page=${pageNo}`)
        if (res?.data?.success) {
          setWithdraws(res?.data?.data)
          setTransactionId('')
          setTotalPage(res.data.pagination?.[0]?.totalPages)
          setPagination({
              btnOne: 1,
              btnTwo: 2,
              btnThree: 3,
              btnFour: "...",
              btnFive: res.data.pagination?.[0]?.totalPages
          })
        } else {
          setWithdraws([])
        }
      }
    } catch (error) {

    } finally {
      setLoader(false)
    }
  }

  useEffect(() => {
    if (openaccordian === "Withdrawals" || tabopen === "Withdrawals") {
      if (activeCrypto) {
        getWithdraws()
      }
    }
  }, [userId, activeCrypto, tabopen, openaccordian])
  // getting data from transaction id
  const getDataFromTransactionId = () => {
    getWithdraws()
  }

  // for set  active crypto
  const handleActiveCrypto = (id) => {
    setActiveCrypto(id)
  }

  return (
    <>
      <div className="row pb-2">
        <div className="col-12 d-none d-md-block">
          <div className="d-flex align-items-center accountCardHeading">
            <div className="cardImg">
              <img
                src="assets/img/sidebar/withdraw.png"
                alt="WITHDRAWALS"
                className="h-100 w-100 img-fluid "
              />
            </div>
            {accountPageLabels.WITHDRAWALS}
          </div>
        </div>
        <nav className="col-12">
          <div
            className="nav nav-tabs gap-2 pt-md-5 pb-2 flex-nowrap overflow-x-auto text-nowrap reffralsTab border-0"
          >
            {!listLoader ?
              cryptoList?.length > 0 ?
                (
                  <>
                    <button
                      className={`nav-link refferalBtn ${"ALL" === activeCrypto ? 'active' : ''}`}
                      onClick={() => handleActiveCrypto("ALL")}
                    >
                      {accountPageLabels.ALL}
                    </button>
                    {
                      cryptoList?.map((item) => (
                        <button
                          key={item?._id}
                          className={`nav-link refferalBtn ${item?._id === activeCrypto ? 'active' : ''}`}
                          onClick={() => handleActiveCrypto(item?._id)}
                        >
                          {item?.code}
                        </button>
                      ))
                    }
                  </>
                )
                : (
                  <></>
                )
              : (
                <></>
              )
            }

          </div>
        </nav>
        <div className="tab-content col-12 pt-2 pt-md-4 pb-4 pb-md-5">
          <div
            className="tab-pane fade show active"
            id="nav-allChains"
            role="tabpanel"
            aria-labelledby="nav-allChains-tab"
            tabIndex={0}
          >
            {!loader ?
              withdraws?.length > 0 ?
                <>
                  <div className="col-12 pt-3">
                    <div className="table-responsive">
                      <table className="table affiliateTable mb-2 align-middle">
                        <thead>
                          <tr className="tableHead text-nowrap">
                            <th scope="col" className="border-0 rounded-start-2 px-4">
                              {/* {accountPageLabels.CREDIT_AMOUNT} */}
                              WITHDRAW AMOUNT
                            </th>
                            {/* <th scope="col" className="border-0 px-4">
                            {accountPageLabels.AMOUNT}
                          </th> */}
                            <th scope="col" className="border-0 px-4">
                              {accountPageLabels.NETWORK_FEE}
                            </th>
                            <th scope="col" className="border-0 px-4 texter">
                              {accountPageLabels.DATE_TIME}
                            </th>
                            <th
                              scope="col"
                              className="border-0 px-4 text-er rounded-end-2"
                            >
                              {/* {accountPageLabels.TRANSACTION} */}
                              STATUS
                            </th>
                          </tr>
                        </thead>
                        <tbody className="tablebody">
                          {withdraws?.map((item) => (
                            <tr className="text-nowrap" key={item?._id}>
                              <td className="border-0  rounded-start-2 px-4">
                                {item?.withdraw_amount ? Number(item?.withdraw_amount).toFixed(2) : '0.00'}
                                {/* {item?.withdraw_amount ? Math.floor(item?.withdraw_amount) : '0'} */}
                              </td>
                              {/* <td className="border-0 px-4">
                              {userBalance ? Number(userBalance).toFixed(2) : '0.00'}
                            </td> */}
                              <td className="border-0 px-4">
                                {item?.fee ? Number(item?.fee).toFixed(2) : '0.00'}
                                {/* {item?.fee ? Math.floor(item?.fee) : '0'} */}
                              </td>
                              <td className="border-0 px-4">{moment(item.createdAt).format("MMM DD YYYY , HH:mm")}</td>
                              {/* <td className="border-0 px-4">{item?._id}</td> */}
                              <td className="border-0 px-4 text-decoration-underline cursor-pointer">{item?.approve_status}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-12 pt-3">
                    <div className="row gap-4 mt-4">
                      {/* pagination */}
                      <div className="col-12">
                        <ul className="pageNavigationSection mb-0 d-flex gap-2 list-unstyled align-items-center justify-content-center">
                          <li
                            className=" cursor-pointer bg-dark p-2"
                            onClick={() => {
                              if (pageNo < 2) return
                              setPageNo(pre => pre - 1)
                            }}
                          >
                            <span
                              className="text-decoration-none d-flex align-items-center justify-content-center pageNavgation"
                            >
                              <img
                                src="assets/img/newsApp/leftarro.svg"
                                alt="leftarro"
                                className="img-fluid w-100 h-100"
                              />
                            </span>
                          </li>
                          {
                            pagination.btnOne > 1 ? <li className="bg-dark p-2 text-light cursor-pointer" onClick={() => {
                              setIsLoadMore(true)
                              setPageNo(1)
                            }}>
                              <span className={`text-decoration-none d-flex align-items-center justify-content-center pageNavgation`}>
                                1
                              </span>
                            </li> : ''
                          }
                          {
                            pagination.btnOne > 2 ?
                              <li className=" cursor-pointer bg-dark p-2 text-light">
                                <span className="text-decoration-none d-flex align-items-center justify-content-center pageNavgation ">
                                  <img src="assets/img/newsApp/Menu.svg" alt="menu" className="img-fluid w-100 h-100" />
                                </span>
                              </li> : ''
                          }
                          <li className=" cursor-pointer bg-dark p-2 text-light" onClick={() => setPageNo(pagination.btnOne)} >
                            <span className={`text-decoration-none ${pageNo == pagination.btnOne ? "active" : ''}  d-flex align-items-center justify-content-center pageNavgation`}>
                              {pagination.btnOne}
                            </span>
                          </li>
                          {
                            totalPages <= pagination.btnTwo - 1 ? '' : <li onClick={() => setPageNo(pagination.btnTwo)} className=" bg-dark p-2 text-light cursor-pointer">
                              <span className={`text-decoration-none ${pageNo == pagination.btnTwo ? "active" : ''}  d-flex align-items-center justify-content-center pageNavgation`}>
                                {pagination.btnTwo}
                              </span>
                            </li>
                          }
                          {
                            totalPages <= pagination.btnThree - 1 ? '' : <li onClick={() => setPageNo(pagination.btnThree)} className="bg-dark p-2 text-light cursor-pointer ">
                              <span className={`text-decoration-none ${pageNo == pagination.btnThree ? "active" : ''}  d-flex align-items-center justify-content-center pageNavgation`}>
                                {pagination.btnThree}
                              </span>
                            </li>
                          }
                          {
                            totalPages >= pagination.btnThree + 2 ? <li className="bg-dark p-2 text-light cursor-pointer">
                              <span className="text-decoration-none d-flex align-items-center justify-content-center pageNavgation bg-transparent">
                                <img src="assets/img/newsApp/Menu.svg" alt="menu" className="img-fluid w-100 h-100" />
                              </span>
                            </li> : ''
                          }
                          {
                            pagination.btnThree == totalPages || pagination.btnTwo == totalPages || pagination.btnOne == totalPages ? '' :
                              <li className=" bg-dark p-2 text-light cursor-pointer" onClick={() => setPageNo(pagination.btnFive)}>
                                <span className={`text-decoration-none text-light ${pageNo == pagination.btnFive ? "active" : ''}  d-flex align-items-center justify-content-center pageNavgation`}>
                                  {pagination.btnFive}
                                </span>
                              </li>
                          }
                          <li
                            className="bg-dark p-2 text-light cursor-pointer bg-dark p-2"
                            onClick={() => {
                              if (pageNo > totalPages - 1) return
                              setPageNo(pre => pre + 1)
                            }}
                          >
                            <span
                              className="text-decoration-none d-flex align-items-center justify-content-center pageNavgation">
                              <img src="assets/img/newsApp/rightarro.svg" alt="leftarro" className="img-fluid w-100 h-100" />
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                </>

                : (<div className="d-flex align-items-center justify-content-center">
                  <div className="NoEntry">{accountPageLabels.NO_ENTRIES}</div>
                </div>)
              : (
                <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                  <Spinner animation="border" variant="light" size="sm" />
                </div>
              )}
          </div>
        </div>
        {/* <div className="col-12 affiliateCardTitle">
          {accountPageLabels.Missing_contract}
          <span
            className="affliateProgram text-decoration-none cursor-pointer"
            onClick={() => setShowTransaction(true)}
          >
            {accountPageLabels.Request_withdraw}
          </span>
        </div> */}
        {/* <div className="col-12 pt-3 affiliateCardTitle">
          {accountPageLabels.Missing_SOL}
          <span
            className="affliateProgram text-decoration-none cursor-pointer"
            onClick={() => setShowTransaction(true)}
          >
            {accountPageLabels.Request_withdraw}
          </span>
        </div> */}
        {
          showTransaction ? (
            <div className="col-12 pt-5">
              <div className="row">
                <div className="col-md-10 mx-auto">
                  <div className="transactionCard">
                    <div className="card-header">
                      <label htmlFor="transactionid" className="transactionLabel">
                        {accountPageLabels.ENTER_TRANSACTION_ID}
                      </label>
                      <input
                        type="text"
                        id="transactionid"
                        value={transactionId}
                        onChange={(e) => setTransactionId(e.target.value)}
                        placeholder="Transaction Id"
                        className="transactionInput form-control"
                      />
                    </div>
                    <div className="cardBody">
                      <div className="d-flex align-items-center gap-2">
                        <button
                          onClick={() => setShowTransaction("")}
                          className="cancelBtn border-0 text-decoration-none"
                        >
                          {accountPageLabels.CANCEL}
                        </button>
                        <button
                          onClick={getDataFromTransactionId}
                          className="customBtn shadow-none customBtntwo d-flex align-items-center justify-content-center "
                        >
                          {accountPageLabels.SUBMIT}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )
        }
      </div>
    </>
  );
}

export default Withdraw;
