import React from "react"
import CasionGames from "./components/CasionGames";
import DambleTable from "./components/DambleTable";
import { useAuth } from "../../hooks/useAuth";
import DepositBanner from "./components/DepositBanner";
import HeroBanner from "./components/HeroBanner";
import LiveWins from "./components/LiveWins";
import DambleUniverse from "./components/DambleUniverse";
import SportsCards from "./components/SportsCards";
import AppFooter from "../../components/AppFooter/AppFooter";
import CategoriesGamesComp from "./components/CategoriesGamesComp";
import OptionPills from "./components/OptionPills";
import ProviderSlider from "../../components/providerSlider/ProviderSlider";

const Casino = ({ socket }) => {
  const { token } = useAuth();

  return (
    <>
      <main className="main vh-100" id="main">
        <div className="mainContaint">
          <div className="homepage">
            {/* <LiveWins socket={socket} /> */}
            <HeroBanner />

            <OptionPills />

            <DambleUniverse />

            <CasionGames />

            <CategoriesGamesComp />

            <SportsCards />

            <ProviderSlider />

            <DambleTable socket={socket} design={true} />
          </div>
        </div>
        <AppFooter />
      </main>
    </>
  );
};

export default Casino;
