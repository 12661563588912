import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import React, { useEffect, useState } from 'react'
import { auth } from '../../../../config/firebase';
import { errorToaster, succesToaster } from '../../../../utils/toaster';
import { getAPI } from '../../../../service/apiInstance';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from "yup";
import { useAuth } from '../../../../hooks/useAuth';
import ErrorMessage from '../../../common/ErrorMessage/ErrorMessage';
import DownArrow from '../../../common/icons/DownArrow';
import SearchIcon from '../../../common/icons/SearchIcon';
import OtpSignIn from './OtpSignIn';
import { Spinner } from 'react-bootstrap';
import { getCustomAuthError } from '../../../../utils/customFirebaseError';



const validationSchema = Yup.object({
  number: Yup.string()
    .required("Phone Number is required")
    .matches(
      /^[0-9]{10}$/,
      "Invalid mobile number"
    ),
});

const SignInNumber = ({
  setEmailPassword,
  setLogOn,
  setPhoneOtp,
  setEmailOrNumber,
  setForgetpass,
  logOn,
  browserName
  // select,
  // setSelect
}) => {
  const [showCountry, setShowCountry] = useState(false);
  const [select, setSelect] = useState("")
  const [isLoading, setIsLoading] = useState(false);
  const [countrycodedata, setCountryCode] = useState([]);
  const countryName = useSelector((state) => state.country);
  const [matchedItem, setmatchedItem] = useState([]);
  const { token } = useAuth()
  const [isOTP,setIsOTP] = useState(false)
  const [number ,setNumber] = useState("")

  const generateRecaptcha = (random) => {
    const recaptchaParent = document.getElementById("recaptcha-parent");
    if (recaptchaParent) {
      recaptchaParent.innerHTML = `<span id=${random} />`
    }
    window.recaptchaVerifier = new RecaptchaVerifier(
      `${random}`,
      {
        size: "invisible",
        callback: (response) => { },
      },
      auth
    );
    window.recaptchaVerifier.render().then((widgetId) => {
      window.recaptchaWidgetId = widgetId;
    });
  };

  const handleSend = (values) => {
    const random = Math.random() + 1000000
    generateRecaptcha(random);
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(
      auth,
      `${select?.phoneCode ? select.phoneCode : "+91"} ${values.number}`,
      appVerifier
    )
      .then((confirmationResult) => {
        succesToaster("OTP SENT!");
        window.confirmationResult = confirmationResult;
        // setLogOn(false);
        // setPhoneOtp(true);
        setNumber(values.number)
        setIsOTP(true)
      })
      .catch((error) => {
        // errorToaster(error.message.split('(')[1].split(')')[0].split('/')[1]);
        // errorToaster(error.message);
        errorToaster(getCustomAuthError(error))

        const recaptchaContainer = document.getElementById(`${random}`)
        if (recaptchaContainer) { recaptchaContainer.remove(); }

        // errorToaster(getFriendlyErrorMessage(error.code))
        // errorToaster(error.message)

      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const CountryCodeFun = async () => {
    const res = await getAPI("get-countries");
    setCountryCode(res.data.data);
    setmatchedItem(res.data.data)
  };
  useEffect(() => {
    const codedata = countrycodedata?.length &&
      countrycodedata?.filter((item) => item.country == countryName).length == 0
        ? countrycodedata
        : countrycodedata.filter((item) => item.country === countryName);
    setmatchedItem(countrycodedata);
    setSelect(countryName && codedata[0]);
    localStorage.setItem("country_Id", codedata[0]?._id);
  }, [countryName, countrycodedata]);

  useEffect(() => {
    if (!token) {
      CountryCodeFun();
    }
  }, [token]);
  // useEffect(() => {
  //   if (!logOn) {
  //     setIsLoading(false);
  //   }
  // }, [logOn]);
  const handlesearch = (val) => {
    const valdata = val.trim();
    if (valdata.length > 0) {
      const filterdata = countrycodedata?.filter(
        (item) => item.country.toLowerCase().includes(val.toLowerCase()) || item.phoneCode.toLowerCase().includes(val.toLowerCase())
      )
      setmatchedItem(filterdata)
    } else {
      setmatchedItem(countrycodedata)
    }
  }
  return (
    <>
      {
        !isOTP ? (
          <Formik
            initialValues={{
              number: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              setIsLoading(true);
              handleSend(values, resetForm);
            }}
          >
            {
              ({
                values,
                handleBlur,
                handleChange,
                handleSubmit,
                errors,
                touched,
              }) => (
                <form>
                  <div className="col-12">
                  <div>
                    <label className='inputLabelNew'> Phone Number*</label>
                  </div>
                    <div className="mobileInputWrapper position-relative">
                      
                      <input
                        className='inp'
                        type="text"
                        placeholder='Phone number'
                        name="number"
                        id="number"
                        value={values.number}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <div onClick={() => setShowCountry(!showCountry)} className="mobileNoTxt">
                        {select?.phoneCode ? select?.phoneCode : "+91"}
                        <DownArrow />
                      </div>
                      {
                        showCountry ?
                          <div className="phoneNumberWrapper">
                            <div className="row">
                              <div className="col-12">
                                <div className="inputWrapper position-relative">
                                  <input
                                    type="text"
                                    className='inp'
                                    placeholder='Search'
                                    name="Search1"
                                    id="Search1"
                                    onChange={(e) => handlesearch(e.target.value)}
                                  />
                                  <div className='searchImg'>
                                    <SearchIcon />
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 listParent">
                                {
                                  matchedItem.map((item, index) => (
                                    <div
                                      className="row mx-0 list my-2"
                                      key={item?._id}
                                      onClick={() => {
                                        setSelect(item);
                                        localStorage.setItem("country_Id", item?._id)
                                        setShowCountry(false)
                                      }}
                                    >
                                      <div className="col-auto ps-0">
                                        <div className="number">
                                          {item.phoneCode}
                                        </div>
                                      </div>
                                      <div className="col pe-0"><div className="country">{item.country}</div></div>
                                    </div>
                                  ))
                                }
                              </div>
                            </div>
                          </div>
                          : ''
                      }
                    </div>
                    <ErrorMessage>
                      {
                        errors.number &&
                        touched.number &&
                        errors.number
                      }
                    </ErrorMessage>
                  </div>
                  <div className="col-12 mt-3">
                    <button
                      className='signUpBtnNew'
                      type='button'
                      onClick={(e) => {
                        // handleSubmit()
                        e.preventDefault()
                        handleSubmit()
                      }}
                    >
                      {isLoading ? <Spinner size="sm" color="dark" /> : 'Sign In'}
                      
                    </button>
                  </div>
                  <div id="recaptcha-parent" />
                </form>
              )
            }
          </Formik>
        ) : (
          <OtpSignIn
          setIsOTP={setIsOTP}
          select={select}
          number={number}
          browserName={browserName}
          />
        )
      }


    </>
  )
}

export default SignInNumber





