import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES_CONST } from "../../constants/routeConstant";
import { useWalletModal } from "../../hooks/useWallet";
import { useSelector } from "react-redux";
import { useAuth } from "../../hooks/useAuth";
import { APP_NAME, AUTH_MODAL_TABS, WALLET_MODAL_TABS } from "../../constants";
import { useAuthModal } from "../../hooks/useAuthModal";
import { useDownloadModal } from "../../hooks/useDownload";
import { socket } from "../../service/socket";
import { useTranslation } from "react-i18next";
import { useResultModal } from "../../hooks/useResult";

const AppFooter = () => {
  const { setWalletModalObject } = useWalletModal()
  const { token } = useAuth()
  const { setAuthModalObject } = useAuthModal();
  const navigate = useNavigate()
  const { t } = useTranslation()
  const footerLabel = t("appFooter", { returnObjects: true })
  const { setResultObject } = useResultModal()
  const [query, setQuery] = useSearchParams()

  const sideAppHandler = () => {
    const exists = document.body.classList

    if (exists["value"] === "asideBar") {
      document.body.classList.remove("asideBar")
    } else {
      document.body.classList.add("asideBar")
    }
  }

  const handleLiveSupport = () => {
    const btn = document.getElementById('zsiq_float')
    if (btn) {
      btn.click()
    }
  }

  const footerPaymentImages = [
    {id : 'fi1', image: 'assets/img/sidebar/visa.png'},
    {id : 'fi2', image: 'assets/img/sidebar/dotspay.png'},
    {id : 'fi3', image: 'assets/img/sidebar/gpay.png'},
    {id : 'fi4', image: 'assets/img/sidebar/applepay.png'},
    {id : 'fi5', image: 'assets/img/sidebar/paypal.png'}
  ]


  useEffect(()=> {
    function xorEncrypt(message, secret) {
      let encrypted = "";
      for (let i = 0; i < message.length; i++) {
          encrypted += String.fromCharCode(message.charCodeAt(i) ^ secret.charCodeAt(i % secret.length));
      }
      const data = btoa(encrypted); // Base64 encode the encrypted message
      console.log('Encoded Message', data)
  }
  const secret = 'shindakimfalme_secret';
  const message = '6679468b38026ed2c55edd53';
  xorEncrypt(message, secret)
  }, [])

  

  

  return (

    <div className="footerSection ">
      <div className="container-fluid footer footerLinks footerFluid pt-4">
        {/* <div className="row mx-0 justify-content-lg-between">
          <div className="col-lg-12 order-lg-3 order-3">
            <div className="row">
              <div className="col-4 col-sm-4">
                <ul className="linksUl list-unstyled">
                  <li className="linkLiHead">Platform</li>
                  <li className="">
                    <Link to={ROUTES_CONST.AFFILIATE_PAGE}
                      className="linkLi text-decoration-none">{footerLabel.Affiliate_Program}</Link></li>
                  <li className=""
                    id="my_custom_link"
                    onClick={handleLiveSupport}
                  > <span className="linkLi text-decoration-none">{footerLabel.LiveSupport}</span></li>
                </ul>
              </div>
              <div className="col-4 col-sm-4 px-0 order-lg-4 order-2">
                <ul className="linksUl list-unstyled">
                  <li className="linkLiHead">{footerLabel.Legal}</li>
                  <li className=""> <Link to={ROUTES_CONST.RESPONSIBLE_GAMBLING} className="linkLi text-decoration-none">{footerLabel.ResponsibleGambling}</Link></li>
                  <li className=""> <Link to={ROUTES_CONST.NEW_AML_POLICY} className="linkLi text-decoration-none">{footerLabel.AML_Policy}</Link></li>
                  <li className=""> <Link to={ROUTES_CONST.SPORTS_POLICY} className="linkLi text-decoration-none">{footerLabel.SportsPolicy}</Link></li>
                  <li className=""> <Link to={ROUTES_CONST.TERMS_AND_CONDITIONS} className="linkLi text-decoration-none">{footerLabel.TermsCondition}
                  </Link></li>
                </ul>
              </div>
              <div className="col-4 col-sm-4 order-lg-5 order-3">
                <ul className="linksUl list-unstyled">
                  <li className="linkLiHead">{footerLabel.Community}</li>
                  <li className=""> <Link target="_blank" to="/" className="linkLi text-decoration-none">{footerLabel.Instagram}</Link></li>
                  <li className=""> <Link target="_blank" to="/" className="linkLi text-decoration-none">{footerLabel.Telegram}</Link></li>
                  <li className=""> <Link target="_blank" to="/" className="linkLi text-decoration-none">{footerLabel.TwitterX}</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div> */}
        <div className="mt-3">
          <div className="row justify-content-center align-items-center gx-4 gx-lg-5 gy-1">
            {
              footerPaymentImages?.map(item=>(
                <div className="col-auto footerPayIcon bigOne" key={item?.id}>
                  <img src={item?.image} alt="" />
                </div>
              ))
            }
          </div>
        </div>
        <div className="footerDivider"></div>
        <div className="mx-auto" style={{maxWidth: '1000px'}}>
          <div className="row justify-content-center align-items-center gx-4 gx-lg-5 gy-3 gy-lg-4">
            {
              [1,2,3,4,5,6,7,8]?.map(item=>(
                <div className="col-auto footerPayIcon" key={item?.id}>
                  <img src={`/assets/img/footer/coin/coin${item}.svg`} alt="" />
                </div>
              ))
            }
          </div>
        </div>
        <div className="footerDivider"></div>
        <div className="">
          <div className="row justify-content-center aign-items-center g-4 gy-xl-0 pt-1">
            {
              [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17]?.map(item=>(
                <div className="col-auto footerIcons">
                  <img src={`/assets/img/footer/n${item}.png`} alt="" />
                </div>
              ))
            }
          </div>
        </div>
        <div className="footerDivider"></div>
        <div className="d-flex align-items-center justify-content-center">
          <div class="footerLogo"><img src="assets/img/appLogo.png" class="object-fit-contain" alt=""/></div>
        </div>
        <div className="footerDescText para mt-3">
          {footerLabel.App_Win_is}
        </div>
        <div className="mt-4">
          <div className="d-flex align-items-center justify-content-center gap-4">
            <div className="resTxt">
              Responsible Gaming
            </div>
            <div className="eighteenImg">
              <img src="assets/img/footer/eighteenplus.webp" alt="" />
            </div>
          </div>
        </div>
        <div className="mt-4">
        <div className="row justify-content-center aign-items-center gx-3">
            {
              [1,2,3,4,5,6]?.map(item=>(
                <div className="col-auto footerSocialIcons">
                  <img src={`/assets/img/footer/social/social${item}.svg`} alt="" />
                </div>
              ))
            }
          </div>
        </div>
        <div className="mt-4">
          <ul className="m-0 p-0 list-unstyled d-flex align-items-center justify-content-center flex-wrap gap-lg-4 gap-3 linksUl">
            <li className="">
                    <Link 
                    to={'#'}
                    // to={ROUTES_CONST.AFFILIATE_PAGE}
                      className="linkLi text-decoration-none">{footerLabel.Affiliate_Program}</Link></li>
                  <li className=""
                    id="my_custom_link"
                    onClick={handleLiveSupport}
                  > <span className="linkLi text-decoration-none">{footerLabel.LiveSupport}</span></li>
                      <li className=""> <Link to={ROUTES_CONST.RESPONSIBLE_GAMBLING} className="linkLi text-decoration-none">{footerLabel.ResponsibleGambling}</Link></li>
                  <li className=""> <Link to={ROUTES_CONST.NEW_AML_POLICY} className="linkLi text-decoration-none">{footerLabel.AML_Policy}</Link></li>
                  <li className=""> <Link to={ROUTES_CONST.SPORTS_POLICY} className="linkLi text-decoration-none">{footerLabel.SportsPolicy}</Link></li>
                  <li className=""> <Link to={ROUTES_CONST.TERMS_AND_CONDITIONS} className="linkLi text-decoration-none">{footerLabel.TermsCondition}
                  </Link></li>
          </ul>
        </div>
        <div className="footerrSection mt-4">
          <div className="row ">
            <div className="col-12 text-center">
              <div className="text-center copyright text-white">Copyright © 2024 {APP_NAME}. All rights <br className="d-none d-none" /> reserved.</div>
            </div>
          </div>
        </div>
      </div>

      {/* mobile bottom bar start  */}
      <div className="container-fluid bottomBarFluid d-xl-none d-block">
        <div className="row">
          <div className="col-12 px-0">
            <div className="bottomBar">
              <div className=" row justify-content-around align-items-center mx-0 gx-0 h-100">
                <div className="col-auto"
                  onClick={sideAppHandler}
                >
                  <span className="bottomAnchor toggleButton">
                    <div className="iconParent">
                      <img
                        src="assets/img/givewayImg/allGamesMenu.png"
                        alt="menu" className="h-100 w-100" /></div>
                        
                  </span>

                </div>
                {
                  token ? (
                    <>
                      <div className="col-auto" onClick={() => navigate(ROUTES_CONST.ACCOUNT)}>
                        <span className="bottomAnchor">
                          <div className="iconParent">
                            <img
                              src="assets/img/header/user.svg"
                              alt="" className="w-100 h-100" /></div>
                        </span>
                      </div>
                      <div className="col-auto">
                        <span className="bottomAnchor"
                          onClick={() => {
                            setWalletModalObject((prev) => ({
                              isWalletOpen: true,
                              selectedTab: WALLET_MODAL_TABS.DEPOSIT
                            }))
                            setQuery({ action: "wallet" })
                          }}>
                          <div className="iconParent">
                            <img
                              src="assets/img/givewayImg/walletVector.png"
                              alt="" className="w-100 h-100" /></div>
                        </span>
                      </div>
                      <div className="col-auto" onClick={() => {
                        // navigate(ROUTES_CONST.SLOT_PAGE)
                        setResultObject(pre => ({
                          ...pre,
                          isResultOpen: true
                        }))

                      }}>
                        <span
                          className="bottomAnchor">
                          <div className="iconParent">
                            <img
                              src="assets/img/header/search.svg"
                              alt=""
                              className="img-fluid w-100 h-100" />
                          </div>
                
                        </span>
                      </div>
                    </>
                  ) : (
                    <div className="col-auto" onClick={() => {
                      // navigate(ROUTES_CONST.SLOT_PAGE)
                      setResultObject(pre => ({
                        ...pre,
                        isResultOpen: true
                      }))

                    }}>
                      <span
                        className="bottomAnchor">
                        <div className="iconParent">
                          <img
                            src="assets/img/header/search.svg"
                            alt=""
                            className="img-fluid w-100 h-100" />
                        </div>
                 

                      </span>
                    </div>
                  )
                }
                <div className="col-auto">
                  <span
                    className="bottomAnchor"
                    data-bs-toggle="offcanvas"
                    href="#message"
                    role="button"
                    aria-controls="message"
                    onClick={() => {
                      socket.emit('joinRoom', { msg: null })
                      document.body.classList.toggle('showMsgOffcanvas')
                    }}
                  >
                    <div className="iconParent">
                      <img
                        src="assets/img/header/chat.svg"
                        alt=""
                        className="img-fluid w-100 h-100" />
                    </div>
                   
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppFooter;
