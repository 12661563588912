import React, { useEffect } from "react";
import Profile from "./components/Profile";
import Wallet from "./components/WalletInfo";
import Affiliate from "./components/Affiliate";
import Settings from "./components/Settings";
import Withdraw from "./components/WithdrawList";
import Deposits from "./components/DepositsList";
import AppFooter from "../../components/AppFooter/AppFooter";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLogoutModal } from "../../hooks/useLogout";
import { useTranslation } from "react-i18next";
import Transaction from "./components/Transaction";


function AccountPage() {
  const accontsstore = useSelector((state) => state.accounts);
  const [tabopen, setTabopen] = useState("");
  const [openaccordian, setAccordian] = useState("");
  const { setIsLogoutOpen } = useLogoutModal()
  const { t } = useTranslation()
  const accountPageLabels = t("accountPageLabels", { returnObjects: true })


  const accountOption = [
    { name: accountPageLabels.Profile, value: 'Profile', img: "profile" },
    { name: accountPageLabels.Wallet, value: 'Wallet', img: "wallet" },
    { name: accountPageLabels.Affiliate, value: 'Affiliate', img: "affiliate" },
    { name: accountPageLabels.Deposits, value: 'Deposits', img: "deposits" },
    { name: accountPageLabels.Withdrawals, value: 'Withdrawals', img: "withdraw" },
    { name: accountPageLabels.Transactions, value: 'Transactions', img: "withdraw" },
    { name: accountPageLabels.Settings, value: 'Settings', img: "setting" },
  ];

  useEffect(() => {
    setTabopen(accontsstore.length ? accontsstore : "Profile");
  }, [accontsstore]);

  const logoutFunction = () => {
    setIsLogoutOpen(true)
  };
  return (
    <>
      <main className="main vh-100" id="main">
        <div className="mainContaint">
          <div className="homepage">
            <div className="container-flui accountTab">
              <div className="row d-md-flex d-none">
                <div className="col-md-3 col-lg-3 col-xxl-3">
                  <nav>
                    <div
                      className="nav nav-tabs tabMain flex-column border-0"
                      id="nav-tab"
                      role="tablist"
                    >
                      {accountOption.map((item) => (
                        <button
                          key={`${item?.name}-${Math.random()}`}
                          className={`nav-link accountBtn d-flex align-items-center ${tabopen == `${item?.value}` ? "active" : ""
                            }`}
                          onClick={() => setTabopen(item?.value)}
                        >
                          <span className="accounttabIcon d-flex align-items-center justify-content-center">
                            <img
                              src={`assets/img/sidebar/${item?.img}.png`}
                              alt="profile"
                              className="h-100 w-100 img-fluid"
                            />
                          </span>
                          {item?.name}
                        </button>
                      ))}
                      <div onClick={logoutFunction}
                        className="nav-link accountBtn text-decoration-none d-flex align-items-center cursor-pointer"
                      >
                        <span className="accounttabIcon d-flex align-items-center justify-content-center">
                          <img
                            src="assets/img/sidebar/logout.png"
                            alt="Log Out"
                            className="h-100 w-100 img-fluid"
                          />
                        </span>
                        {accountPageLabels.Log_Out}
                      </div>
                    </div>
                  </nav>
                </div>
                <div className="col-md-9 col-lg-9 col-xxl-9">
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className={`tab-pane fade show active`}>
                      <div className="accountTabcard">
                        {
                          tabopen === "Profile" ? <Profile /> :
                            tabopen === "Wallet" ? <Wallet /> :
                              tabopen === "Affiliate" ? <Affiliate /> :
                                tabopen === "Deposits" ? <Deposits tabopen={tabopen} /> :
                                  tabopen === "Withdrawals" ? <Withdraw tabopen={tabopen} /> :
                                    tabopen === "Settings" ? <Settings tabopen={tabopen} /> :
                                      tabopen === "Transactions" ? <Transaction tabopen={tabopen} /> : ''
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row d-md-none d-flex tabMain">
                <div className="col-12">
                  <div className="row accordion-item border-0 tabMain">
                    <div className="col-12 accordion-header">
                      <button
                        className={`accordion-button accountBtn rounded-2 d-flex align-items-center ${openaccordian == "Profile" ? "" : "collapsed"
                          }`}
                        type="button "
                        onClick={() => {
                          if (openaccordian == "Profile") {
                            setAccordian('')
                          } else {
                            setAccordian("Profile")
                          }
                        }}
                      >
                        <span className="accounttabIcon d-flex align-items-center justify-content-center">
                          <img
                            src="assets/img/sidebar/profile.png"
                            alt="profile"
                            className="h-100 w-100 img-fluid"
                          />
                        </span>
                        {accountPageLabels.Profile}
                      </button>
                    </div>
                    <div
                      id="Profile"
                      className={`accordion-collapse col-12 ${openaccordian == "Profile" ? "collapse show" : "collapse"
                        }`}
                    >
                      <div className="accordion-body">
                        <div className="accountTabcard">
                          <Profile />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row accordion-item border-0 tabMain">
                    <div className="col-12 accordion-header">
                      <button
                        className={`accordion-button accountBtn rounded-2 d-flex align-items-center ${openaccordian == 'Wallet' ? "" : "collapsed"
                          }`}
                        onClick={() => {
                          if (openaccordian == 'Wallet') {
                            setAccordian('')
                          } else {
                            setAccordian("Wallet")
                          }
                        }}
                      >
                        <span className="accounttabIcon d-flex align-items-center justify-content-center">
                          <img
                            src="assets/img/sidebar/wallet.png"
                            alt="Wallet"
                            className="h-100 w-100 img-fluid"
                            loading='lazy'
                          />
                        </span>
                        {accountPageLabels.Wallet}
                      </button>
                    </div>
                    <div
                      id="Wallet"
                      className={`accordion-collapse col-12 ${openaccordian == 'Wallet' ? "collapse show" : "collapse"
                        }`}
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="accountTabcard">
                          <Wallet />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row accordion-item border-0 tabMain">
                    <div className="col-12 accordion-header">
                      <button
                        className={`accordion-button accountBtn rounded-2 d-flex align-items-center ${openaccordian == 'Affiliate' ? "collapse" : "collapsed"
                          }`}
                        onClick={() => {
                          if (openaccordian == 'Affiliate') {
                            setAccordian('')
                          } else {
                            setAccordian("Affiliate")
                          }
                        }}
                      >
                        <span className="accounttabIcon d-flex align-items-center justify-content-center">
                          <img
                            src="assets/img/sidebar/affiliate.png"
                            alt="Affiliate"
                            className="h-100 w-100 img-fluid"
                            loading='lazy'
                          />
                        </span>
                        {accountPageLabels.Affiliate}
                      </button>
                    </div>
                    <div
                      id="Affiliate"
                      className={`accordion-collapse col-12 ${openaccordian == 'Affiliate' ? "collapse show" : "collapse"
                        }`}
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="accountTabcard">
                          <Affiliate />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row accordion-item border-0 tabMain">
                    <div className="col-12 accordion-header">
                      <button
                        className={`accordion-button accountBtn rounded-2 d-flex align-items-center ${openaccordian == 'Deposits' ? "" : "collapsed"
                          }`}
                        type="button "
                        onClick={() => {
                          if (openaccordian == 'Deposits') {
                            setAccordian('')
                          } else {
                            setAccordian("Deposits")
                          }
                        }}
                      >
                        <span className="accounttabIcon d-flex align-items-center justify-content-center">
                          <img
                            src="assets/img/sidebar/deposits.png"
                            alt="Deposits"
                            className="h-100 w-100 img-fluid"
                          />
                        </span>
                        {accountPageLabels.Deposits}
                      </button>
                    </div>
                    <div
                      id="Deposits"
                      className={`accordion-collapse col-12 ${openaccordian == 'Deposits' ? "collapse show" : "collapse"
                        }`}
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="accountTabcard pb-md-4 pb-3">
                          <Deposits openaccordian={openaccordian} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row accordion-item border-0 tabMain">
                    <div className="col-12 accordion-header">
                      <button
                        className={`accordion-button accountBtn rounded-2 d-flex align-items-center ${openaccordian == 'Withdrawals' ? "" : "collapsed"
                          }`}
                        onClick={() => {
                          if (openaccordian == 'Withdrawals') {
                            setAccordian('')
                          } else {
                            setAccordian("Withdrawals")
                          }
                        }}
                      >
                        <span className="accounttabIcon d-flex align-items-center justify-content-center">
                          <img
                            src="assets/img/sidebar/withdraw.png"
                            alt="Withdrawals"
                            className="h-100 w-100 img-fluid"
                          />
                        </span>
                        {accountPageLabels.Withdrawals}
                      </button>
                    </div>
                    <div
                      id="Withdrawals"
                      className={`accordion-collapse col-12 ${openaccordian == 'Withdrawals' ? "collapse show" : "collapse"
                        }`}
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="accountTabcard">
                          <Withdraw openaccordian={openaccordian} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row accordion-item border-0 tabMain">
                    <div className="col-12 accordion-header">
                      <button
                        className={`accordion-button accountBtn rounded-2 d-flex align-items-center ${openaccordian == 'Transactions' ? "" : "collapsed"
                          }`}
                        onClick={() => {
                          if (openaccordian == 'Transactions') {
                            setAccordian('')
                          } else {
                            setAccordian("Transactions")
                          }
                        }}
                      >
                        <span className="accounttabIcon d-flex align-items-center justify-content-center">
                          <img
                            src="assets/img/sidebar/withdraw.png"
                            alt="Withdrawals"
                            className="h-100 w-100 img-fluid"
                          />
                        </span>
                        {accountPageLabels.Transactions}
                      </button>
                    </div>
                    <div
                      id="Withdrawals"
                      className={`accordion-collapse col-12 ${openaccordian == 'Transactions' ? "collapse show" : "collapse"
                        }`}
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="accountTabcard">
                          <Transaction openaccordian={openaccordian} tabopen={tabopen} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row accordion-item settingtab border-0 tabMain">
                    <div className="col-12 accordion-header">
                      <button
                        className={`accordion-button accountBtn rounded-2 d-flex align-items-center ${openaccordian == 'Settings' ? "" : "collapsed"
                          }`}
                        type="button "
                        onClick={() => {
                          if (openaccordian == 'Settings') {
                            setAccordian('')
                          } else {
                            setAccordian("Settings")
                          }
                        }}
                      >
                        <span className="accounttabIcon d-flex align-items-center justify-content-center">
                          <img
                            src="assets/img/sidebar/setting.png"
                            alt="Settings"
                            className="h-100 w-100 img-fluid"
                          />
                        </span>
                        {accountPageLabels.Settings}
                      </button>
                    </div>
                    <div
                      id="Settings"
                      className={`accordion-collapse col-12 ${openaccordian == 'Settings' ? "collapse show" : "collapse"
                        }`}
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="accountTabcard">
                          <Settings openaccordian={openaccordian} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row accordion-item settingtab border-0 tabMain">
                    <div className="col-12 accordion-header">
                      <button
                        className={`accordion-button accountBtn rounded-2 d-flex align-items-center collapsed`}
                        type="button "
                        onClick={logoutFunction}
                      >
                        <span className="accounttabIcon d-flex align-items-center justify-content-center">
                          <img
                            src="assets/img/sidebar/logout.png"
                            alt="logout"
                            className="h-100 w-100 img-fluid"
                          />
                        </span>
                        {accountPageLabels.Log_Out}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AppFooter />
      </main >
    </>
  );
}

export default AccountPage;
