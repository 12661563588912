import React, { useEffect, useRef, useState } from "react";
import { errorToaster, succesToaster } from "../../../../utils/toaster";
import { useAuth } from "../../../../hooks/useAuth";
import { useAuthModal } from "../../../../hooks/useAuthModal";
import { useSelector } from "react-redux";
import { postAPI } from "../../../../service/apiInstance";
import { USER_LANG } from "../../../../constants";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../../../config/firebase";
import { Spinner } from "react-bootstrap";
import { useSpinModal } from "../../../../hooks/useSpinModal";
import { useReferralModal } from "../../../../hooks/useReferralModal";
import { getCustomAuthError } from "../../../../utils/customFirebaseError";


const OtpSignUp = ({
  // setOtpId,
  setActive,
  username,
  checks,
  // optid,
  referral,
  number,
  select,
  setSelect,
  setChecks,
  browserName,
  click_id,
  new_click_id,
  setIsOtp,
  bonus
}) => {
  // const [isOtp, setIsOtp] = useState(true)
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef([]);
  // const [isLoading, setIsLoading] = useState(false)
  const { setToken } = useAuth();
  const { authModalObject, setAuthModalObject } = useAuthModal();
  const ipAddress = useSelector((state) => state.ipAddress);
  const country = useSelector((state) => state.country);
  const country_id = localStorage.getItem("country_Id");
  const [isLoading, setIsLoading] = useState(false)
  const [isMetamask, setIsMetamask] = useState(false);
  const [isTelegram, setIsTelegram] = useState(false);
  const [userObj, setUserObj] = useState({});
  const [otpError, setOtpError] = useState(false)
  const [timer, setTimer] = useState(60);
  const [resendLoading, setResendLoading] = useState(false);
  const { setSpinModalObject } = useSpinModal()
  const { isReferral, setIsReferral, isReferralInput, setIsReferralInput } = useReferralModal()

  useEffect(() => {
    inputRefs.current[0].focus()
  }, [])

  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
      return () => clearInterval(countdown);
    }
  }, [timer]);

  const handleOtpChange = (e, index) => {
    const updatedOtp = [...otp];
    updatedOtp[index] = e.target.value;
    setOtp(updatedOtp);

    if (e.target.value !== "" && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && index > 0 && event.target.value === "") {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleVerifyOtp = async (event) => {
    if (timer === 0 || !timer) {
      errorToaster('OTP Expired')
      return
    }
    const otpValue = otp.join("");
    // Perform verification logic here
    if (otpValue.length === 6) {
      setOtpError(false)
      setIsLoading(true)
      // verify otp
      let confirmationResult = window.confirmationResult;
      confirmationResult
        ?.confirm(otpValue)
        .then((result) => {
          // User signed in successfully.
          let user = result?.user;
          // const lang = JSON.parse(localStorage.getItem(USER_LANG));
          const lang = localStorage.getItem(USER_LANG);
          //payload
          const body = {
            phone: number,
            // username: username,
            accessToken: user.accessToken,
            refreshToken: user.stsTokenManager.refreshToken,
            terms: checks.terms,
            promotion: checks.promotions,
            ipAddress: ipAddress ? ipAddress : "",
            country: country ? country : "",
            // language: lang?.symbol,
            language: lang,
            phoneCodeId: country_id,
            refercode: referral,
            browser: browserName,
            trackingToken: !!click_id ? click_id : null,
            bonus: !!bonus ? bonus.bonus : false,
            clickid : !!new_click_id ? new_click_id : null
          };

          postAPI("user/register-user", body)
            .then((res) => {
              if (res?.data?.success) {
                succesToaster(res.data.message);
                setToken(res?.data.data?.auth_token);
                setAuthModalObject((pre) => ({ ...pre, isAuthOpen: false }));
                setSpinModalObject(prev => ({
                  ...prev,
                  isModalOpen: true
                }))
                localStorage.removeItem('clickid')
                // setIsReferral(true)
              } else {
                // errorToaster(res.data.message.split('(')[1].split(')')[0].split('/')[1]);
                errorToaster(res.data.message);
                // setOtpId(false);
                // setActive(true);
              }
            })
            .catch((err) => {
              errorToaster("something went wrong");
            })
            .finally(() => {
              setIsLoading(false)
            })
          setOtp(["", "", "", "", "", ""]);
          setIsLoading(false)
          window?.dataLayer?.push({
            'event': 'GA4_event',
            'event_name': 'game_sign_up'
         });
        })
        .catch((error) => {
          setOtp(["", "", "", "", "", ""]);
          // errorToaster("User couldn't sign in (bad verification code?)");
          errorToaster(getCustomAuthError(error))

          setIsLoading(false)
        })
        ;
    } else {
      setOtpError(true)
    }
    setIsLoading(false)
  };

  // --------------resend OTP---------------------///
  const generateRecaptcha = (random) => {
    const recaptchaParent = document.getElementById("recaptcha-parent");
    if (recaptchaParent) {
      recaptchaParent.innerHTML = `<span id=${random} />`
    }
    window.recaptchaVerifier = new RecaptchaVerifier(
      `${random}`,
      {
        size: "invisible",
        callback: (response) => { },
      },
      auth
    );
    window.recaptchaVerifier.render().then((widgetId) => {
      window.recaptchaWidgetId = widgetId;
    });
  };

  const handleSend = (values, resetForm) => {
    setResendLoading(true)
    const random = Math.random() + 1000000
    generateRecaptcha(random);
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(
      auth,
      `${select?.phoneCode ? select.phoneCode : "+91"} ${number}`,
      appVerifier
    ) //dynamic code
      .then((confirmationResult) => {
        succesToaster("OTP SENT!");
        // setOtpId(confirmationResult.verificationId);
        window.confirmationResult = confirmationResult;
        // setActive(false);
        // setOtpId(true);
        window?.recaptchaVerifier?.recaptcha?.reset(window.recaptchaWidgetId)
        setTimer(60)
      })
      .catch((error) => {
        // errorToaster(error.message);
        errorToaster(getCustomAuthError(error))
        const recaptchaContainer = document.getElementById(`${random}`)
        if (recaptchaContainer) { recaptchaContainer.remove(); }
        window?.recaptchaVerifier?.recaptcha?.reset(window.recaptchaWidgetId)
      })
      .finally(() => {
        setResendLoading(false)
        setChecks(pre => ({ ...pre, terms: false }))
      });
  };
  return (
    <form>
      <div className="col-12 mt-2">
        <div className="inpWrapper position-relative">
          <div className='d-flex justify-content-between w-100'>
            {
              otp.map((digit, index) => (
                <input
                  className='otpField mx-1 px-1'
                  type="mail"
                  name=""
                  id=""
                  key={index}
                  maxLength={1}
                  value={digit}
                  onChange={(e) => handleOtpChange(e, index)}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      const otpValue = otp.join("");
                      if (otpValue.length !== 6) {
                        errorToaster("OTP Field can't be empty")
                        return
                      }
                      setIsLoading(true)
                      handleVerifyOtp(event)
                    }
                    handleKeyDown(event, index)
                  }}
                  ref={(ref) => (inputRefs.current[index] = ref)}
                />
              ))
            }
          </div>
        </div>
      </div>
      <div className="col-12 mt-3">
        <button
          className='signUpBtnNew'
          type="button"
          onClick={(e) => {
            handleVerifyOtp(e)
          }}
        >{isLoading ? <Spinner size="sm" color="dark" /> : 'Sign Up'}</button>
      </div>
      {
        timer ? (
          <div className="cursor-pointer text-light py-2 resendOtpTxt col-12">
            Didn't received OTP, resend in {timer}s
          </div>
        ) : (
          <div className="col-12 mt-3">
            <button
              className="signUpBtnNew"
              onClick={handleSend}
              type="button"
            >
              <span className="playBtnInner d-flex align-items-center justify-content-center px-4 w-100">
                {
                  resendLoading ? <Spinner size="sm" color="dark" /> : "Resend OTP"
                }
              </span>
            </button>
          </div>
        )
      }
      <div id="recaptcha-parent" />
      {/* <div className="col-12 mt-3">
        Didn't 
      </div> */}
    </form>
  );
};

export default OtpSignUp;
